import React, { useState, useEffect, useContext, useCallback } from "react";
import { api_call_token } from "../../Utils/Network";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Rating from "@material-ui/lab/Rating";
import styles from "./TestSeries.module.css";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { Link } from "react-router-dom";
import { LinearProgress, Typography } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import Stack from "@mui/material/Stack";
import card1 from "../../Assets/cardBackground/card1.png";
import card2 from "../../Assets/cardBackground/card2.png";
import card3 from "../../Assets/cardBackground/card3.png";
import mobile from "../../Assets/HomeRewamp/HeaderPhone_1.png";
// import lcarro from "../../Assets/lcarrologo.png";
import lcarro from "../../Assets/Logo/Artboard 1 copy 3.png";
import { UserCredsContext } from "../../ContextApi/UserCredsContext/UserCredsContext";
import { MainDataContextApi } from "../../ContextApis/MainDataContextApi/MainDataContextApi";
import { Alert } from "@mui/material";
import LockIcon from '@material-ui/icons/Lock';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import cross from "../../Assets/1x/asset/close.png";
import { useLocation } from "react-router-dom";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    console.log(script, "script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    // script.onload = display.Razorpay;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

const Results = (guardian_exists) => (
  <div style={{ position: "absolute", top: "0", width: "96%%" }}>
    {!guardian_exists ? (
      <Alert
        severity="warning"
        style={{ margin: "0 auto", width: "max-content" }}
      >
        Please complete your profile to view the results
      </Alert>
    ) : (
      console.log(guardian_exists, "guardian_exists")
    )}
  </div>
);
function ViewAllResult(props) {
  const {
    user,
    loginData,
    setStudentProfile,
    setToken,
    updateLoginData,
    setUser,
    guardianExists,
    setGuardianExists,
  } = useContext(UserCredsContext);
  const { context_user } = React.useContext(MainDataContextApi);

  let { test_type, block_id, id } = useParams();
  const [instruction, setInstruction] = useState([]);
  const [viewData, setViewData] = useState(6);
  const [breakDown, setBreakDown] = useState([]);
  const [datas, setDatas] = useState([]);
  let history = useHistory();
  const [open, setOpen] = React.useState(false);
  const [goToTest, setGoTo] = useState(-1);
  const [testType, setTestType] = useState("");
  const [startType, setStartType] = useState(false);
  const [block, setBlock] = useState(null);
  const [overall_test, setoverall_test] = useState(false);
  const [guardian_exists, setGuardian_exists] = useState(false);
  const [ispurchase, setIspurchase] = useState(false);
  const [isprice, setIsPrice] = useState(0);
  const location = useLocation();
  const [cardsBackground] = useState([
    {
      img: "./../Assets/cardBackground/card1.png",
    },
    {
      img: "../../Assets/cardBackground/card2.png",
    },
    {
      img: "../../Assets/cardBackground/card3.png",
    },
  ]);

  const cardsBackgrounds = [card1, card2, card3];
  // console.log(cardsBackground[0]);
  // const { loading = false } = props;
  const [loading, setLoading] = useState(true);

  const [minimumTime, setMinimumTime] = useState(500);
  const [minimumTimeElapsed, setMinimumTimeElapsed] = useState(true);

  const handleClose = () => {
    setLoading(false);
    setOpen(false);
  };

  const restartTimeout = useCallback(() => {
    setMinimumTimeElapsed(false);
    setLoading(true);
    //simulate random load time between 0 and 5 seconds
    const randomLoadTime = Math.random() * 100;
    setTimeout(() => {
      setMinimumTimeElapsed(true);
    }, minimumTime);
    setTimeout(() => {
      setLoading(false);
    }, randomLoadTime);
  }, [setMinimumTimeElapsed, setLoading, minimumTime]);
  useEffect(() => {
    getInstruction();
    restartTimeout();
    ViewAllResult();
    if (window.Tawk_API) {
      window.Tawk_API.hideWidget();
    }
    return () => {
      if (window.Tawk_API) {
        window.Tawk_API.showWidget();
      }
    };
  }, []);

  //Payment


  const handelBooking = (data) => {
    setLoading(true);
    // console.log(data, "solts");

    // if (data == null || undefined) {
    //   console.log(data, "price");
    // } else {
    let price = data * 100;

    api_call_token
      .post(`counseling/purchase/test/initiate_book_slot/`, {
        amount: price,
      })
      .then((res) => {
        console.log(res.data, "solts result");
        const payData = res?.data?.data;
        displayRazorPay(payData);
      })
      .catch((err) => console.log(err));
    // }
    setLoading(false);
  };

    //Razorpay payment integration
    async function displayRazorPay(dataId, slotData) {
      setLoading(true);
      // console.log(dataId, slotData, "data id passed solts");
      const res = await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
      );
      if (!res) {
        alert("razorpay sdk failed to load. Are you online?");
        return;
      }
  
      let current_url = window.location.href;
      if (current_url.includes("localhost") || current_url.includes("staging")) {
        // var razorpay_key = "rzp_test_TW6h2zNAZqVdu1";
        var razorpay_key = "rzp_live_lDScQvs1epmNca";
      } else {
        var razorpay_key = "rzp_live_lDScQvs1epmNca";
      }
      var courseprice = dataId.amount;
      const options = {
        key: razorpay_key,
        amount: courseprice,
        currency: "INR",
        name: "Brainhap",
        description: "Course payment",
        image: lcarro,
        order_id: `${dataId.order_id}`,
        handler: function (response) {
          console.log(response.razorpay_payment_id, "hello");
          console.log(response.razorpay_order_id, "hello1");
          console.log(response.razorpay_signature, "hello2");
          // setPaymentId(response.razorpay_payment_id);
          // var intValue = parseInt(courseId);
          // console.log(slotData, "getpurchase body");
          getpurchase(response, dataId);
        },
        prefill: {
          name: `${user?.first_name}`,
          email: `${user?.email}`,
          contact: `${user?.phone}`,
        },
      };
  
      var paymentObject = new window.Razorpay(options);
      paymentObject.open();
      setLoading(false);
    }
    const getpurchase = (rpid, ordId, slotData) => {
      setLoading(true);
      // console.log(slotData, "getpurchase body");
      console.log(rpid, ordId, "rpid, ordId, slotData");
      var data = {
        transaction_id: rpid?.razorpay_payment_id,
        order_id: ordId?.id,
        razorpay_signature: rpid?.razorpay_signature,
      };
      console.log(data, "value to pass in purchase");
      api_call_token
        .post(`counseling/purchase/test/order_test_slot/`, data)
        .then((response) => {
          console.log("The response for purchase course is ", response.data.data);
          // alert("Successfully Slot is Booked");
          if (response.data.data.id) {
            // setOpen(true);
            ViewAllResult()
          }
          // setSuccess(true);
        });
      // console.log(rpid, "rpid");
    };
  ///

  const ViewAllResult = () => {
    setLoading(false);
    api_call_token
      .get(
        `test/aptitude_test_question/question_progress/?test_type=${test_type}`
      )
      .then((res) => {
        setDatas(res.data.data);
        setBreakDown(res.data.data);
        setViewData(6);
        setoverall_test(res.data.overall_test);
        setGuardian_exists(res.data.guardian_exists);
        setIspurchase(res.data.is_purchased);
        setIsPrice(res.data.price);
        // console.log(res.data);
        window.localStorage.removeItem("answer");
        window.localStorage.removeItem("minutes");
        window.localStorage.removeItem("number");
      })
      .catch((err) => console.log(err));
  };
  const getInstruction = () => {
    setLoading(false);

    api_call_token
      .get("test/instructions/")
      .then((res) => {
        setInstruction(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  const handleClick = () => {
    setLoading(false);

    // history.push("/testseries");
    props.history.goBack();
  };

  const setTest = (val, sub, id) => {
    // debugger;
    setLoading(false);

    // console.log(val, sub);
    setTestType(val);
    // getQuestions({...filterObj, test_type: val ,page: 1, page_size: 10})
    setStartType(sub);
    setBlock(id);
    setGoTo(0);
  };
  const [showAlertResults, setShowAlertResults] = useState(false);
  // setShowAlertResults(true)
  // const onClickAlert = () => setShowAlertResults(!showAlertResults)}
  // (breakDown, "ViewAllResult");
  // console.log(guardian_exists, "guardian_exists");
  console.log(user, "datassss")
  console.log(location.state, id, "SSSSSSSS")
  return (
    <div className={styles.Back_position}>
      {!minimumTimeElapsed || loading ? (
        <div
          style={{ display: "flex", flexWrap: "wrap", margin: "auto" }}
          className={styles.btn_answers}
        >
          {[1, 2].map((v, i) => (
            <div className={styles.box_blg_skeleton} key={i}>
              <Skeleton variant="text" />
              <br />
              <Skeleton variant="circular" width={400} height={40} />
              <br />
              <Skeleton variant="rectangular" width={600} height={118} />
            </div>
          ))}
        </div>
      ) : (
        goToTest === -1 && (
          <div className={styles.mains_test}>
            <div className={styles.test_inst_box}>
              <div
                style={{
                  margin: "30px 0",
                  display: "flex",
                  // flexDirection: "row-reverse",
                  justifyContent: "space-between",
                }}
              >
                <div>
                <button
                  onClick={() => handleClick()}
                  style={{
                    width: "150px",
                    textAlign: "center",
                    marginRight: "5px",
                  }}
                  className={styles.btn_starts_test}
                >
                  Back
                </button>
                {/* {(ispurchase) ? <></> : (location?.state?.test === "sample") ? <></> :  <button onClick={() => handelBooking(isprice)}
                  style={{
                    width: "150px",
                    textAlign: "center",
                    marginRight: "5px",
                  }}
                  className={styles.btn_starts_test}>
                  Pay now
                </button>} */}
                </div>
                {/* {overall_test && id != 1 && (
                  <Link
                    to={{
                      pathname: `/result_view/overall/${test_type}`,
                    }}
                    style={{ width: "150px", textAlign: "center" }}
                    className={
                      datas.length
                        ? styles.btn_starts_test
                        : styles.btn_starts_test_disable
                    }
                  >
                    Parameter Profile
                  </Link>
                )} */}

                {/* )} */}
              </div>

              {loading ? (
                <div
                  style={{ display: "flex", flexWrap: "wrap" }}
                  className={styles.blg_container}
                >
                  {[1, 2].map((v, i) => (
                    <div className={styles.box_blg_skeleton} key={i}>
                      <Skeleton
                        animation="wave"
                        className={styles.test_inst_box}
                        height={300}
                        width={900}
                      />
                    </div>
                  ))}
                </div>
              ) : (
                <>
                  <div>
                    {/* <div>
                    <input
                      type="submit"
                      value="Search"
                      onClick={() => setShowAlertResults(!showAlertResults)}
                    />
                  </div> */}
                    <div
                      style={{ position: "absolute", top: "2%", width: "96%%" }}
                    >
                      {!guardian_exists ? (
                        <>
                          <Alert
                            severity="warning"
                            style={{ margin: "0 auto", width: "max-content" }}
                          >
                            {/* Please complete your profile to view the results */}
                            Complete guardian details to view results
                          </Alert>
                        </>
                      ) : (
                        console.log(guardian_exists, "guardian_exists")
                      )}
                    </div>
                    {datas &&
                      datas.map((values, ind) => (

                        // location?.state?.test == "sample"
                        (values?.is_free && id == '1') && 
                        <div
                          key={ind}
                          className={`${styles.academic_selection} ${styles.academic_selection_background}`}
                          style={{
                            backgroundImage: `${`url(${values?.img})`}`,
                          }}
                        >
                          <div
                            className={styles.acc_details2}
                            style={{
                              backgroundImage:
                                "/static/media/card1.49f26551.png",
                            }}
                          >
                            <p
                              style={{
                                fontSize: "20px",
                                textTransform: "capitalize",
                              }}
                            >
                              {/* Test:- */}
                              {values.test_name}
                            </p>

                            {values?.id ? (
                              <Link
                                to={
                                  !guardian_exists
                                    ? {
                                        pathname: `/user-registration`,
                                        state: { update: true },
                                      }
                                    : {

                                        // pathname: (location?.state?.test == "sample") ? `/result_view/get/?id=${values?.id}/test_type=${test_type}/${1}` : `/result_view/get/?id=${values?.id}/test_type=${test_type}/${2}`,
                                      pathname: (id == '1') ? `/result_view/get/?id=${values?.id}/test_type=${test_type}/${1}` : `/result_view/get/?id=${values?.id}/test_type=${test_type}/${2}`,
                                        
                                        // pathname: `/result_view/get/?test_type=${test_type}`,
                                        state: {
                                          fromSubmit: values?.submitted,
                                          guardEx: guardian_exists,
                                          // test: location?.state?.test,
                                          test: (id == '1') ? "sample" : "actual" , //location?.state?.test,

                                        },
                                      }
                                }
                                style={{
                                  textAlign: "center",
                                }}
                                className={`${styles.btn_starts_test} ${
                                  !values?.id
                                    ? styles.greys
                                    : styles.btn_card_result
                                }`}
                                disabled={values?.id ? false : true}
                              >
                                View Result
                              </Link>
                            ) : (
                              <>
                              {
                                (ispurchase || values.is_free) ? 
                                <button
                                  className={`${styles.btn_starts_test} ${
                                    !values?.is_available
                                      ? styles.btn_card_start
                                      : styles.btn_card_resume
                                  }`}
                                  onClick={() =>
                                    setTest(
                                      values.test_type,
                                      values.test_started,
                                      values.block_id
                                    )
                                  }
                                  disabled={values?.is_available ? false : true}
                                >
                                  {values.test_started ? "Resume" : "Start"}
                                </button>
                                :<LockIcon />
                              }
                                
                              </>
                            )}
                          </div>
                          <div style={{ marginBottom: "10px" }}>
                            {values.attempt_date
                              ? `Test Attempted on: ${values.attempt_date}`
                              : " "}
                          </div>
                        </div>
                           ))}

                    {datas &&
                      datas.map((values, ind) => (
                        // location?.state?.test == "actual"
                      
                        // (!values?.test_name.includes("SAMPLE TEST") && id == '2') && 
                        (!values?.is_free && id == '2') && 

                        
                        <div
                          key={ind}
                          className={`${styles.academic_selection} ${styles.academic_selection_background}`}
                          style={{
                            backgroundImage: `${`url(${values?.img})`}`,
                          }}
                        >
                          <div
                            className={styles.acc_details2}
                            style={{
                              backgroundImage:
                                "/static/media/card1.49f26551.png",
                            }}
                          >
                            <p
                              style={{
                                fontSize: "20px",
                                textTransform: "capitalize",
                              }}
                            >
                              {/* Test:- */}
                              {values.test_name}
                            </p>

                            {values?.id ? (
                              <Link
                                to={
                                  !guardian_exists
                                    ? {
                                        pathname: `/user-registration`,
                                        state: { update: true },
                                      }
                                    : {
                                      // location?.state?.test == "sample"
                                      pathname: (id == '1') ? `/result_view/get/?id=${values?.id}/test_type=${test_type}/${1}` : `/result_view/get/?id=${values?.id}/test_type=${test_type}/${2}`,

                                        // pathname: `/result_view/get/?id=${values?.id}/test_type=${test_type}`,
                                        // pathname: `/result_view/get/?test_type=${test_type}`,
                                        state: {
                                          fromSubmit: values?.submitted,
                                          guardEx: guardian_exists,
                                          test: (id == '1') ? "sample" : "actual" , //location?.state?.test,
                                        },
                                      }
                                }
                                style={{
                                  textAlign: "center",
                                }}
                                className={`${styles.btn_starts_test} ${
                                  !values?.id
                                    ? styles.greys
                                    : styles.btn_card_result
                                }`}
                                disabled={values?.id ? false : true}
                              >
                                View Result
                              </Link>
                            ) : (
                              <>
                              {
                                (ispurchase || values.is_free) ? 
                                <button
                                  className={`${styles.btn_starts_test} ${
                                    !values?.is_available
                                      ? styles.btn_card_start
                                      : styles.btn_card_resume
                                  }`}
                                  onClick={() =>
                                    setTest(
                                      values.test_type,
                                      values.test_started,
                                      values.block_id
                                    )
                                  }
                                  disabled={values?.is_available ? false : true}
                                >
                                  {values.test_started ? "Resume" : "Start"}
                                </button>
                                :<LockIcon />
                              }
                                
                              </>
                            )}
                          </div>
                          <div style={{ marginBottom: "10px" }}>
                            {values.attempt_date
                              ? `Test Attempted on: ${values.attempt_date}`
                              : " "}
                          </div>
                        </div>
                           ))}
                  </div>
                </>
              )}
              {datas.length ? (
                ""
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <p className={styles.instructions}>
                    Coming soon, please check again later.
                  </p>
                </div>
              )}
              {/* <div>
            <Link
              to={{ pathname: `/` }}
              style={{ width: "150px", textAlign: "center" }}
              className={styles.btn_starts_test}
            >
              Back
            </Link>
          </div> */}
            </div>
          </div>
        )
      )}

      {!minimumTimeElapsed || loading ? (
        <div
          style={{
            display: "flex",
            margin: "auto",
            alignContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
          className={styles.blg_container}
        >
          {/* {[1, 2].map((v, i) => (
            <div className={styles.box_blg_skeleton} key={i}>
              <Skeleton variant="text" />
              <Skeleton variant="circular" width={400} height={40} />
              <Skeleton variant="rectangular" width={210} height={118} />
            </div>
          ))} */}
        </div>
      ) : (
        goToTest === 0 && (
          <div className={styles.mains_test}>
            <p className={styles.instruct}>Instructions</p>
            <div className={styles.test_inst_box}>
              {instruction.map((val, idx) => (
                <div
                  key={idx}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <p className={styles.instructions}>{`${idx + 1}.`}</p>
                  <p className={styles.instructions}>{val.text}</p>
                </div>
              ))}
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Link
                  to={{
                    pathname: `/testseries/${testType}/${block}`,
                    state: {
                      fromType: startType,
                      block_id: block,
                      guardEx: guardian_exists,
                      test: location?.state?.test,
                    },
                  }}
                  className={`${styles.btn_starts_test} `}
                >
                  Start
                </Link>
              </div>
            </div>
          </div>
        )
      )}

{/* <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
          Congratulations! Your tests are now unlocked
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          
        </DialogActions>
      </Dialog> */}
    </div>
  );
}

export default ViewAllResult;
