import React from 'react'
import styles from "./index.module.css"
import { id } from 'date-fns/locale'


function StudentReviewcard() {
  return (
    <div className={styles.cardListing}>
      {
        cardData?.map((v,i) => {
            return(
                <>
                <div key={i} className={styles.cardContainer}>
                    <h5 className={styles.name}>{v?.s_name}</h5>
                    <h5 className={styles.class}>{v?.class}</h5>
                    <p className={styles.para}>{v?.para}</p>
                </div>
                </>
            )
        })
      }
    </div>
  )
}

export default StudentReviewcard

const cardData = [
    {
        id:1,
        s_name:"Kalp Doshi",
        class:"CLASS 10th",
        para:"As I am about to go for stream selection, BrainHap’s career counselling has been a game changer. It gave me a clear understanding of subjects where I am good and where I need more improvement. The counselling session was eye-opening for me. I learned so much about my aptitudes and interests, and now I feel confident about my future career path.",
    },
    {
        id:2,
        s_name:"Meet Patel",
        class:"CLASS 10th",
        para:"Experiencing BrainHap's BrainMapping Technology was great. I received detailed insights into my skills, aptitudes, and interests, along with a clear path to achieving success in my dream career. Their counsellors helped me understand the best career options for me and guided me on how I can achieve that. I will recommend all my friends to try BrainHap.",
    },
    {
      id:1,
      s_name:"Riya Meena",
      class:"CLASS 10th",
      para:"BrainHap's Brain Mapping test was very interesting, I liked it very much. Then in the counselling session afterwards I learned so much about myself, including my strengths and areas where I need improvement. The detailed insights into my skills, aptitudes, and interests that the counsellors have given me helped me in clearly deciding my career goals. Also, they gave me career options that fit my capabilities. I really enjoyed the entire experience!",
  },
  {
      id:2,
      s_name:"Shivam Paswan",
      class:"CLASS 10th",
      para:"The EEG Headset test was really fun, and I enjoyed it a lot! The counselling session afterwards was super helpful too. I learned so much about myself, like what I'm good at and my abilities. And also I got good clarity about my future and which career option will be best for me. Thanks a lot, BrainHap!",
  },
]
