import React from "react";
import styles from "./index.module.css";
import img1 from "../../../Assets/freetrailpage/img1.png";
import img2 from "../../../Assets/freetrailpage/img2.png";
import img3 from "../../../Assets/freetrailpage/img3.png";
import img4 from "../../../Assets/freetrailpage/img4.jpg";
import img5 from "../../../Assets/freetrailpage/img5.jpg";

function ImageAnimationSlider() {
  return (
    <section className={styles.section}>
      <article className={styles.article}>
        {" "}
        <div className={`${styles.div} `}>
          <ul className={styles.ul}>
            {imgCard?.map((v, i) => {
              return(
              <li key={i}>
                <img className={styles.img} src={v?.img} />
              </li>

              )
            })}
          </ul>
          </div>
          <div className={`${styles.div} `}>
          <ul className={styles.mainingLinting}>
            {imgCard?.map((v, i) => {
              return(
              <li key={i}>
                <img src={v?.img} />
              </li>
              )
            })}
          </ul>
        </div>
      </article>
    </section>
  );
}

export default ImageAnimationSlider;

const imgCard = [
  {
    id: 1,
    img: img1,
  },
  {
    id: 2,
    img: img2,
  },
  {
    id: 3,
    img: img3,
  },
  {
    id: 4,
    img: img4,
  },
  // {
  //   id: 5,
  //   img: img5,
  // },
  // {
  //   id: 6,
  //   img: img3,
  // },
];
