import React, { useEffect, useRef, useState } from "react";
import styles from "./index.module.css";
import pptshow from "../../../Assets/pptPageimg.png";
import Carousel from "react-multi-carousel";
import { responsiveCourses } from "../../../Utils/Utils";
import { api_call_token } from "../../../Utils/Network";
import ReactPlayer from "react-player";
import Box from "@mui/material/Box";
// import Button from '@mui/material/Button';
// import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@material-ui/icons/Close";
import { da } from "date-fns/locale";
import { useHistory, useParams, useLocation } from "react-router-dom";
import novideo from "../../../Assets/no-Video.png";
import playch from "../../../Assets/videoIconActive.png";
import playBtn from "../../../Assets/videoPlay.png";

const styleTwo = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  textAlign: "center",
  // alignItems:"center"
};

function VideosPage({ setSelectCourse }) {
  const [loading, setLoading] = useState(true);
  const [chapterVideos, setChapterVideos] = useState([]);
  const [finelOpne, setFinelOpne] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [page, setPage] = useState(1);
  const [lastElement, setLastElement] = useState(null);
  const [maxpage, setmaxPage] = useState(1);
  const observerRef = useRef(null);
  const playerRef = useRef(null);
  const params = useParams();
  const { tabId, ppt, courseId } = params;

  const observer = useRef(
    typeof window != "undefined" &&
      new IntersectionObserver((entries) => {
        const first = entries[0];
        if (first.isIntersecting && page <= maxpage) {
          setPage((no) => no + 1);
        }
      })
  );

  useEffect(() => {
    if (page <= maxpage) {
      getVideo();
    }
  }, [page]);

  useEffect(() => {
    const currentElement = lastElement;
    const currentObserver = observer.current;

    if (currentObserver == false) return;
    if (currentElement) {
      currentObserver.observe(currentElement);
    }
    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement);
      }
    };
  }, [lastElement]);

  const getVideo = () => {
    console.log("Fetching page", page);

    api_call_token
      // .get(`content/video/learnzone/?course__id=${courseId}&page=${page}`)
      .get(`content/video/learnzone/${courseId}/`)
      .then((res) => {
        if (res.status == 200) {
          // const maxpage = res?.data?.max_pages;
          // setmaxPage(maxpage);
          // console.log("Total PPT Count:", maxpage);
          const data = res.data.data;
          setChapterVideos(data);
          // setChapterVideos((prevState) => {
          //   const updatedState = [...prevState, ...data];
          //   return updatedState;
          // });
          setSelectCourse(data?.title);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // console.log("Total PPT Count:", maxpage);
  const viewVideoOpne = (video) => {
    setSelectedVideo(video);
    setFinelOpne(true);
  };
  const viewVideoClose = () => setFinelOpne(false);

  function formatDuration(seconds) {
    const hours = Math.floor(seconds / 3600)
      .toString()
      .padStart(2, "0");
    const minutes = Math.floor((seconds % 3600) / 60)
      .toString()
      .padStart(2, "0");
    const remainingSeconds = (seconds % 60).toString().padStart(2, "0");
    return `${hours}:${minutes}:${remainingSeconds}`;
  }

  return (
    <>
      <div className={styles.pptContainer}>
        {/* <h3 className={styles.heading}>Videos </h3> */}
        {/* {chapterVideos?.map((info, index) => {
          console.log(info?.title, "videoshero");
          return ( */}
        <>
          {chapterVideos?.chapter?.length === 0 && (
            <div
              className={styles.nodataAvl}
              style={{ margin: "0 auto", width: "fit-content" }}
            >
              <img src={novideo} alt="no Video" />
              <p style={{ fontSize: "20px", textAlign: "center" }}>
                Videos Coming Soon !{" "}
              </p>
            </div>
          )}
          {chapterVideos?.chapter?.length > 0 && (
            <div>
              {/* <h2 className={styles.CourseHeading}>{info?.title}</h2>
            <h3 className={styles.heading}>Videos </h3>  */}
              {chapterVideos?.chapter?.map((vc, i) => {
                console.log(vc, "videoshero");
                return (
                  <div key={vc?.id}>
                    <h4 className={styles.chapterheading}>
                      {/* {chapterVideos?.id} */}
                      {i + 1}. {vc?.title} ({vc?.total_count})
                    </h4>
                    <Carousel
                      responsive={responsiveCourses}
                      className={styles.slider}
                      showDots={true}
                    >
                      {vc?.video?.map((v, index) => {
                        return (
                          // <a
                          //   href={v?.url}
                          //   className={styles.pptCard}
                          //   target="_blank"
                          // >

                          //   <video controls>
                          //     <source src={v?.file} />
                          //   </video>

                          // </a>
                          <div
                            style={{
                              margin: "10px 10px 30px 0",
                              // marginRight: "10px",
                              position: "relative",
                              cursor: "pointer",
                              borderRadius: "10px",
                              overflow: "hidden",
                            }}
                            onClick={() => viewVideoOpne(v)}
                            ref={setLastElement}
                            className={styles.videoOverlay}
                          >
                            <img
                              src={
                                v?.thumbnail == null ? pptshow : v?.thumbnail
                              }
                              style={{
                                width: "100%",
                                borderRadius: "10px",
                                paddingLeft: "0",
                              }}
                            />
                            {/* <ReactPlayer
                            controls={true}
                            ref={(player) => {
                              playerRef.current = player;
                            }}
                            url={v?.thumbnail}
                            url="https://demo.unified-streaming.com/k8s/features/stable/video/tears-of-steel/tears-of-steel.ism/.m3u8"
                            width="90%"
                            height="100%"
                            style={{
                              margin: "0 auto",
                              backgroundColor: "#000",
                            }}
                          /> */}
                            <p className={styles.durestionInfo}>
                              {formatDuration(v?.duration)}
                            </p>
                            <div className={styles.playBtn}>
                              <img src={playBtn} alt="play" />
                            </div>
                            <div className={styles.pptCardText}>
                              <p style={{ textTransform: "capitalize" }}>
                                {/* {i + 1}.{index + 1} */}
                                 {v?.title}
                              </p>
                            </div>
                          </div>
                        );
                      })}
                    </Carousel>
                  </div>
                );
              })}
              {/*  */}
            </div>
          )}
        </>
        {/*  );
        })} */}

        <Modal
          open={finelOpne}
          onClose={viewVideoClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={styleTwo} className={styles.boxForMobile}>
            <div
              className={styles.closebtn}
              style={{ textAlign: "right", cursor: "pointer" }}
            >
              <CloseIcon onClick={viewVideoClose} />
            </div>
            <div className={styles.videoModel}>
              <ReactPlayer
                controls={true}
                ref={(player) => {
                  playerRef.current = player;
                }}
                config={{
                  file: {
                    attributes: {
                      controlsList: "nodownload",
                    },
                  },
                }}
                onSeek={3000}
                // progressInterval={1000}
                url={selectedVideo?.file}
                // url="https://demo.unified-streaming.com/k8s/features/stable/video/tears-of-steel/tears-of-steel.ism/.m3u8"
                // width="100%"
                // height="500px"
                className={styles.innerVideoDisplay}
                style={{
                  margin: "0 auto",
                  backgroundColor: "#000",
                }}
              />
            </div>
          </Box>
        </Modal>
      </div>
    </>
  );
}

export default VideosPage;

const pptData = [
  {
    id: 1,
    title: "Chapter 1",
    ppt: [
      {
        id: 1,
        topicName: "Topic 1",
        noOfSlide: 5,
        imag: pptshow,
        url: "",
      },
      {
        id: 2,
        topicName: "Topic 2",
        noOfSlide: 8,
        imag: pptshow,
        url: "",
      },
    ],
  },
  {
    id: 2,
    title: "Chapter 2",
    ppt: [
      {
        id: 1,
        topicName: "Topic 1",
        noOfSlide: 14,
        imag: pptshow,
        url: "",
      },
      {
        id: 2,
        topicName: "Topic 2",
        noOfSlide: 19,
        imag: pptshow,
        url: "",
      },
      {
        id: 3,
        topicName: "Topic 3",
        noOfSlide: 21,
        imag: pptshow,
        url: "",
      },
      {
        id: 4,
        topicName: "Topic 4",
        noOfSlide: 5,
        imag: pptshow,
        url: "",
      },
    ],
  },
  {
    id: 1,
    title: "Chapter 3",
    ppt: [
      {
        id: 1,
        topicName: "Topic 1",
        noOfSlide: 5,
        imag: pptshow,
        url: "",
      },
      {
        id: 2,
        topicName: "Topic 2",
        noOfSlide: 8,
        imag: pptshow,
        url: "",
      },
      {
        id: 4,
        topicName: "Topic 2",
        noOfSlide: 8,
        imag: pptshow,
        url: "",
      },
      {
        id: 5,
        topicName: "Topic 1",
        noOfSlide: 5,
        imag: pptshow,
        url: "",
      },
      {
        id: 6,
        topicName: "Topic 2",
        noOfSlide: 8,
        imag: pptshow,
        url: "",
      },
      {
        id: 7,
        topicName: "Topic 2",
        noOfSlide: 8,
        imag: pptshow,
        url: "",
      },
    ],
  },
  {
    id: 1,
    title: "Chapter 4",
    ppt: [
      {
        id: 1,
        topicName: "Topic 1",
        noOfSlide: 5,
        imag: pptshow,
        url: "",
      },
      {
        id: 2,
        topicName: "Topic 2",
        noOfSlide: 8,
        imag: pptshow,
        url: "",
      },
    ],
  },
];
