import React from 'react'
import styles from "./index.module.css"

const NeuroStudentNeedCard = ({values}) => {
  console.log(values,"NeuroStudentNeedCard");
  return (
    <>
    <div className={styles.cardContainer}>
        <div className={styles.imgContainer}>
          <img src={values?.img} alt={values?.title} />
          <h4 className={styles.cardName}>{values?.title}</h4>
        </div>
        
        <p className={styles.desc}>{values?.details}</p>
    </div>
    </>
  )
}

export default NeuroStudentNeedCard