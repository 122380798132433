import React from "react";
import styles from "./index.module.css";
import { Button } from "@material-ui/core";

function BuyKitCard({values ,vieworderboxOpne}) {
  return (
    <div className={styles.BuycardBox}>
      <div className={styles.imgContainer}>
        <img src={values?.image} alt="" />
      </div>
      <div className={`${styles.textContainer}`}>
        <h5 className={styles.kitName}>{values?.title}</h5>
        <p className={styles.kitdesc}>{values?.desc}</p>
        <Button
          variant="contained"
          onClick={() => vieworderboxOpne(values)}
          className={styles.buybtn}
        >
          Buy Now
        </Button>
      </div>
    </div>
  );
}

export default BuyKitCard;
