import React, { useContext, useEffect, useState } from "react";
import styles from "./index.module.css";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { api_call, api_call_token } from "../../Utils/Network";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import { makeStyles } from "@material-ui/core/styles";
import { UserCredsContext } from "../../ContextApi/UserCredsContext/UserCredsContext";
import Modal from "@material-ui/core/Modal";

function getModalStyle() {
  const top = 45;
  const left = 45;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: "60px",
    border: "0",
    borderRadius: "7px",
    ["@media (max-width: 568px)"]: {
      width: "90vw",
      padding: "20px",
    },
    ["@media (min-width: 768px)"]: {
      width: "400px",
      padding: "20px",
    },
  },
  BackdropProps: {
    width: "500px",
    ["@media (max-width: 768px)"]: {
      width: "100%",
    },
  },
}));
function MentalHealthmcq(props) {
  const params = useParams();
  const { mhtest } = params;
  const [state, setState] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [answer, setAnswer] = useState([]);
  const [indxNumber, setIndxNumber] = useState(0);
  const [getBack, setGetBack] = useState(false);
  const [totalQ, setTotalQuestion] = useState(null);
  const [filterObj, setFilterObj] = useState({});
  const [showPassage, setShowPassage] = useState(null);
  const [modalStyle] = React.useState(getModalStyle);
  const [passageRedirect, setPassageRedirect] = useState(0);
  const [open, setOpen] = React.useState(false);
  const [openFinalpop, setOpenFinalpop] = React.useState(false);
  const [pdfFile , setPdfFile] = useState()
  const classes = useStyles();
  let char = ["A", "B", "C", "D", "E", "F", "G"];
  let history = useHistory();
  let testid = parseInt(mhtest, 10)
  const {
    user,
    loginData,
    setStudentProfile,
    setToken,
    updateLoginData,
    setUser,
    guardianExists,
    setGuardianExists,
  } = useContext(UserCredsContext);

  const getQuestionListing = () => {
    let data = { test_id: parseInt(mhtest, 10) };

    console.log(props, "nekuhwfuhknmehfijd4353453");
    api_call_token
      .get(`content/question/list/?test_id=${mhtest}`)
      .then((res) => {
        console.log(res?.data?.data, "getQuestionListing");
        setQuestions(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getQuestionListing();
  }, []);

  const toggleDrawer = (open) => (event) => {
    setState(open);
  };
  const setAns = (ansid, quid) => {
    let index = answer.findIndex((x) => x.question_id === quid);
    if (index === -1) {
      setAnswer([
        ...answer,
        {
          user: user?.id,
          question_id: quid,
          response_id: ansid,
          test_block: parseInt(mhtest, 10),
          // questions: quid,

          // answer_data: [{ answer_choice: ansid }],
          // avg_time_spend: Math.floor(Math.random() * 11),
        },
      ]);
    } else {
      answer[index].user = user?.id;
      answer[index].question_id = quid;
      answer[index].response_id = ansid;
      answer[index].test_block = parseInt(mhtest, 10);
      // answer[index].answer_data[0].answer_choice = ansid;
      // answer[index].avg_time_spend = Math.floor(Math.random() * 11);
      setAnswer([...answer]);
    }
  };

  

  const getNewQuestion = (x) => {
    window.scrollTo(0, 0);
    console.log(x, "dd");
    let questSize = questions.length;
    let currentIndex = x;
    let c = (currentIndex / questSize) * 100;
    console.log(c, "dd");
    setIndxNumber(x + 1);
    if (c > 75) {
      if (totalQ == questions.length) {
        return;
      } else {
        // getQuestions(filterObj, questions);
      }
    }
  };

  const getQuestions = (
    paramObj = {
      ...filterObj,
      page: 1,
      //   test_type: test_type,
      page_size: 10,
      //   block_id: block_id,
    },
    list = []
  ) => {
    // api_call_token
    //   .get(`test/aptitude_test_question/`, { params: { ...paramObj } })
    //   .then((res) => {
    //     setBlock(res.data.block_no);
    //     setPages(res.data.page);
    //     setTotalQuestion(res.data.total_question);
    //     setShowPassage(res.data.total_questions_main);
    //     setQuestion([...list, ...res.data.data]);
    //     if (res.data.page === 1 && !props?.location?.state?.fromType) {
    //       setTimers(res.data.total_time);
    //     }
    //     if (props?.location?.state?.fromType && res.data.page === 1) {
    //       api_call_token
    //         .get(`test/get_json/${res.data.block_no}/?test_type=${test_type}`)
    //         .then((res) => {
    //           setAnswer(res.data.data.json);
    //           setTimers(res.data.data.time_left);
    //         });
    //     }
    //     if (res.data.next_page) paramObj.page = res.data.next_page;
    //     else delete paramObj.page;
    //     setFilterObj(paramObj);
    //     setGoTo(1);
    //     console.log();
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  };
  const QuitTest = () => {
    // getAnswer();
    // localStorage.removeItem("timer");
    // localStorage.removeItem("answer");
    history.push("/mental-health-testseries");
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClosefinal = () => {
    setOpenFinalpop(false)
  }
 
  const SubmitTest = () => {
    console.log(answer, "nuen097890u09");

    api_call_token
    .post(`content/save/result/`,  answer )
    .then((res) =>{
      // console.log(res?.data?.guardian_detail, "SubmitTest");
      // if(res?.status === 200 || res?.status === 201 || res?.status === 204){
      //   history.push("/mental-health-testseries")
      // }

      if(res?.data?.guardian_detail){
        // api_call_token
        // .get(`content/mental/helth/report/?test=${testid}`)
        // .then((res) =>{

        //   console.log(res?.data?.file, "SubmitTest");
        //   setPdfFile(res?.data?.file)
        //   if(res?.data?.file){
        //     window.open(res?.data?.file)
        //     setOpen(false);
        //     history.push("/mental-health-testseries")
        //   }else{
        //     alert("somethig went Wrong")
        //   }
          
        // })
        // .catch((err) =>{
        //   console.log(err);
        // })
        setOpenFinalpop(true)
        
      }else{
        // history.push("/mental-health-testseries")
        setGuardianExists(1);
        setOpen(false);
        history.push(`/user-registration`);
      }
    })
    .catch((err) =>{
      console.log(err);
    })
  }

  const closeandNav = () => {
    setOpenFinalpop(false);
    history.push("/mental-health-testseries")
  }
  
  return (
    <>
      <div className={styles.Back_position}>
        <div className={styles.show_data}>
          <button
            onClick={toggleDrawer(true)}
            className={`${styles.btn_starts_test} ${styles.show_question}`}
          >
            Show Questions
          </button>
          <div className={styles.test_section}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  padding: "10px",
                  backgroundColor: "#c3c3c3",
                  borderRadius: "8px",
                }}
              >
                Q {indxNumber + 1}.
              </div>
            </div>
          </div>
          <div className={styles.submFinal_btn}>
            <button
              className={`${styles.btn_next} ${styles.next_btns}`}
              style={{ marginLeft: "10px" }}
              onClick={() => QuitTest()}
            >
              Quit
            </button>
            <button
                onClick={handleOpen}
              className={`${styles.btn_starts_test} ${styles.submit_btn}`}
            >
              Submit
            </button>
          </div>
        </div>
        <div className={styles.mains_test}>
          <div className={styles.test_section}>
            <div
              dangerouslySetInnerHTML={{
                __html: questions[indxNumber]?.title,
              }}
              className={styles.quest}
            ></div>
            <div className={styles.answer_wrapper}>
              {questions[indxNumber]?.choices?.map((v, i) => {
                return (
                  <>
                    <button
                      onClick={() => setAns(v.id, questions[indxNumber].id)}
                      className={`${styles.btn_answers} ${answer?.some(
                        (e) =>
                          e.question_id === questions[indxNumber].id &&
                          // e.answer_data[0].answer_choice === v.id
                          e.response_id === v?.id
                      )
                        ? styles.btn_succes
                        : null
                        }`}
                    >
                      <span className={styles.chars}>{char[i]}</span>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: v?.title,
                        }}
                        style={{
                          display: "flex",
                          textAlign: "left",
                        }}
                      ></div>
                    </button>
                  </>
                );
              })}
            </div>
          </div>
          <div className={styles.test_section}>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                marginTop: "20px",
              }}
            >
              {indxNumber === 0 ? (
                <></>
              ) : (
                <>
                  {/* {questions[indxNumber]?.title ? ( */}
                  <button
                    onClick={() => {
                      setIndxNumber(indxNumber - 1);
                      setGetBack(true);
                      window.scrollTo(0, 0);
                    }}
                    className={styles.btn_next}
                  >
                    Back
                  </button>
                  {/* ) : ( */}
                  <>
                    {/* {<button
                              onClick={() => passageRedirect(question[number - 1]?.questions?.length - 1)}
                              className={styles.btn_next}
                            >
                              Back
                            </button>
                            } */}
                  </>
                  {/* )} */}
                </>
              )}
              {indxNumber === questions.length - 1 ? (
                <></>
              ) : ( 
                answer?.some(
                  (e) =>
                    e.question_id === questions[indxNumber].id) ? <button
                  onClick={() => {
                    getNewQuestion(indxNumber);
                    setGetBack(false);
                  }}
                  className={`${styles.btn_next} ${styles.next_btns}`}
                  style={{ marginRight: "0px" }}
                  
                >
                  Next
                </button> : ""
                
              )}
            </div>
          </div>
        </div>
      </div>
      <SwipeableDrawer
        anchor={window.innerWidth < 767 ? "bottom" : "left"}
        open={state}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        classes={{ paper: classes.BackdropProps }}
      >
        <div className={styles.mob_drawer}>
          <div className={styles.show_total_question}>
            Total Number of Questions: {showPassage}
          </div>
          {questions.map((val, idx) =>
            val.title ? (
              <div
                className={`${styles.test_box} ${answer?.some((e) => e.question_id === val.id)
                  ? styles.btn_green
                  : null
                  } ${indxNumber === idx ? styles.border : null}`}
                onClick={() => {
                  setIndxNumber(idx);
                  getNewQuestion(idx - 1);
                }}
              >
                <div className={styles.whitespace}>
                  Q {idx + 1}.
                  {/* {val?.question_no}. */}
                </div>
                <div
                  dangerouslySetInnerHTML={{ __html: val.title }}
                  className={styles.question_display}
                ></div>
              </div>
            ) : (
              val.questions.map((val, i) => (
                <div
                  className={`${styles.test_box} ${answer?.some((e) => e.question_id === val.id)
                    ? styles.btn_green
                    : null
                    }`}
                  onClick={() => {
                    setIndxNumber(idx);
                    getNewQuestion(idx - 1);
                    setPassageRedirect(i);
                  }}
                >
                  <div className={styles.whitespace}>
                    Q {val?.question_no}{" "}
                  </div>
                  <div
                    dangerouslySetInnerHTML={{ __html: val?.title }}
                    className={styles.question_display}
                  ></div>
                  {/* <div> Passage</div> */}
                </div>
              ))
            )
          )}

          <button
            className={`${totalQ === questions.length
              ? styles.grey
              : styles.getNextSet
              } ${styles.widthHundred}`}
            onClick={() => getQuestions(filterObj, questions)}
            disabled={totalQ === questions.length ? true : false}
          >
            Get Next Set
          </button>
        </div>
      </SwipeableDrawer>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          <h2 id="simple-modal-title">Alert</h2>
          <p
            id="simple-modal-description"
            style={{
              fontSize: "18px",
              color: "#4a3d3d",
              lineHeight: 1.4,
            }}
          >
            Once submitted the test, you won't be able to take a retest.
          </p>
          <button
            className={`${styles.btn_starts_test} ${styles.submit_btn}`}
            onClick={() => SubmitTest()}
            style={{ marginLeft: "0" }}
          >
            Submit
          </button>
        </div>
      </Modal>
      <Modal
        open={openFinalpop}
        onClose={handleClosefinal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          <h2 id="simple-modal-title">THANKING NOTE </h2>
          <p
            id="simple-modal-description"
            style={{
              fontSize: "18px",
              color: "#4a3d3d",
              lineHeight: 1.4,
            }}
          >
            Thank you for taking the time to complete this assessment. Remember, this test is just a tool to gain insight into your mental health. Our counsellors are here to provide further assistance and support. Don't hesitate to reach out to us. 

 
          </p>
          <button
            className={`${styles.btn_starts_test} ${styles.submit_btn}`}
            onClick={() => closeandNav()}
            style={{ marginLeft: "0" }}
          >
            Submit
          </button>
        </div>
      </Modal>
    </>
  );
}

export default MentalHealthmcq;
