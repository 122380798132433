import React from "react";
import styles from "./dashboard.module.css";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import NewMyAccount from "../MyAccount/NewMyAccount";
import MyReortsPage from "../MyReortsPage/MyReortsPage";
import STEAMReport from "../STEAMReport/STEAMReport";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  tabs: {
    color: "#000",
    // fontSize:"26px" ,
    // fontWeight:"600",
  },
}));

function MyDashboard() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      {/* <AppBar position="static"> */}
      <div className={styles.tabsContainer}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
          TabIndicatorProps={{
            style: {
              background: `linear-gradient(270deg, #032c6b 0%, #11898a 100%)`, // Change this to your desired color
              height: "3px",
            },
          }}
        >
          <Tab
            label="My Account"
            {...a11yProps(0)}
            className={`${value === 0 ? styles.activeTab : ""} ${
              styles.textLable
            }`}
          />
          <Tab
            label="My Report"
            {...a11yProps(1)}
            className={`${value === 1 ? styles.activeTab : ""} ${
              styles.textLable
            }`}
          />
          <Tab
            label="STEAM Courses"
            {...a11yProps(2)}
            className={`${value === 2 ? styles.activeTab : ""} ${
              styles.textLable
            }`}
          />
        </Tabs>
      </div>
      {/* </AppBar> */}
      <TabPanel value={value} index={0}>
        <NewMyAccount />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <MyReortsPage />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <STEAMReport />
      </TabPanel>
    </div>
  );
}

export default MyDashboard;
