import React from 'react'
import styles from "./index.module.css"
import img1 from "../../../Assets/freetrailpage/ag.png"
import img2 from "../../../Assets/freetrailpage/mg.png"
import img3 from "../../../Assets/freetrailpage/nm.png"
import { id } from 'date-fns/locale'

function HowdoesCard() {
  return (
    <>
    <ul className={styles.ul}>
        {
            carddata?.map((v,i) => {
                return(
                    <li className={styles.outsidelist}>
                       <div className={styles.imgContainer}>
                        <img src={v?.img} />
                       </div> 
                       <div className={styles.textConatainer}>
                            <span>0{v?.id}</span>
                            <h4>{v?.title}</h4>
                            <p>{v?.para}</p>
                       </div>
                    </li>
                )
            })
        }
    </ul>
    </>
  )
}

export default HowdoesCard

const carddata = [
    {
        id:1,
        img:img1,
        title:"AlphaGuide",
        para:"The initial assessment phase is where we understand your child's interests and basic cognitive abilities.",
    },
    {
        id:2,
        img:img2,
        title:"MindGrapher",
        para:"Advanced BrainMapping test measures neuro-cognitive learning abilities to pinpoint specific talents and skills.",
    },
    {
        id:3,
        img:img3,
        title:"NeuroMentor",
        para:"Personalised counselling sessions to help your child chart a clear path to success.",
    },
]
