import React from "react";
import styles from "./index.module.css";

const AlphaguideOverview = ({values}) => {
  return (
    <>
      <div className={`${styles.mainBox}`} style={{background:values?.bgcolor}}>
        <div className={`${styles.boxImgContainer}`} >
          <img src={values?.img} alt="" style={{boxShadow:values?.boxShadow, border:`5px solid ${values?.textcol}`}}/>
        </div>
        <div className={styles.textContainer}>
          <h4 className={`${styles.cardHeading}`} style={{color:values?.textcol}}>{values?.title}</h4>
          <p className={styles.cardPara}>{values?.description}</p>
        </div>
      </div>
    </>
  );
};

export default AlphaguideOverview;
