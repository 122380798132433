import React, { useContext, useEffect, useState } from "react";
import styles from "./index.module.css";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useHistory } from "react-router-dom";
import { api_call_token } from "../../Utils/Network";
import { UserCredsContext } from "../../ContextApi/UserCredsContext/UserCredsContext";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

function ReportAccordion() {
  const classes = useStyles();
  const [reportStatus, setReportStatus] = useState(true);
  let history = useHistory();
  const [alltest, setAllTest] = useState([]);
  const [freeTest, setFreetest] = useState([]);
  const [paidTest, setPaidTest] = useState([]);
  const [purchasetest, setPurchaseTest] = useState();
  const [counsellingReport , setCounsellingReport] = useState([]); 
  const [mindgrapherReport, setMindGrapherReport] = useState([]);
  const { user } = useContext(UserCredsContext);

  const handleFreetest = (id) =>{
    console.log(id ,"handleFreetest");
    history.push({ pathname : `result_view/get/?id=${id}/test_type=aptitude/1`, state: {
      fromSubmit: true,
      guardEx: true,
      // test: location?.state?.test,
      // test: (id == '1') ? "sample" : "actual" , //location?.state?.test,

    }})
  }

  const handelPaidtestReport = (id, name) =>{
    console.log(id, name ,"handlePaidtest");

    if(name === "academic"){
      history.push({ pathname : `result_view/get/?id=${id}/test_type=achievement/2`, state: {
        fromSubmit: true,
        guardEx: true,
        // test: location?.state?.test,
        // test: (id == '1') ? "sample" : "actual" , //location?.state?.test,
  
      }})
    }else{
      history.push({ pathname : `result_view/get/?id=${id}/test_type=${name}/2`, state: {
        fromSubmit: true,
        guardEx: true,
        // test: location?.state?.test,
        // test: (id == '1') ? "sample" : "actual" , //location?.state?.test,
  
      }})
    }
    
  }


  const gettestprogress = () =>{
    api_call_token
    // `test/aptitude_test_question/question_progress/?test_type=${test_type}`
    // .get(`test/aptitude_test_question/question_progress/?test_type=aptitude`)
    .get(`test/report/status`)
    .then((res) =>{
      console.log(res?.data?.data?.slice(5,6) , "mnw89923h2fwhfbsh");
      const datapaid = res?.data?.data?.slice(0,2);

      setPaidTest(datapaid)
      const datafree = res?.data?.data?.slice(3,4);
      setFreetest(datafree)

      const datacareer = res?.data?.data?.slice(4,5);
      setCounsellingReport(datacareer)

      const datamind = res?.data?.data?.slice(5,6);
      setMindGrapherReport(datamind)
      // setAllTest(res?.data?.data);
    })
    .catch((err) =>{
      console.log(err);
    })

  }  


  const getpurchesedetails = () => {
    api_call_token
    .get(`test/aptitude_test_question/question_progress/?test_type=aptitude`)
    .then((res) =>{
      console.log(res?.data, "getpurchesedetails");
      setPurchaseTest(res?.data?.is_purchased)
    })
    .catch((err) => {
      console.log(err);
    })
  }
  // const getCareerCounsellingreport = () => {
  //   api_call_token
  //   .get(`counseling/career/report/career_counselling_report/?user_id=${user?.id}`)
  //   .then((res) => {
  //     // console.log(res?.data?.data, "getCareerCounsellingreport");

  //     setCounsellingReport(res?.data?.data)
  //   })
  //   .catch((err) =>{
  //     console.log(err);
  //   })
  // }

  // const getmindGraphReport = () => {
  //   api_call_token
  //   .get(`counseling/mindgraph/report/mind_grapher_report/?user_id=${user?.id}`)
  //   .then((res) => {
  //     // console.log(res?.status, "getmindGraphReport");
  //     setMindGrapherReport(res?.data?.data)
  //   })
  //   .catch((err) =>{
  //     console.log(err);
  //   })
  // }

  const pdfshowcareer = (pdfFile) =>{
    // console.log(pdfFile ,"freetest0987");
    // pdfFile ? window.open(pdfFile) : alert("Report Not Generated yet")
    api_call_token
    .get(`counseling/career/report/career_counselling_report/?user_id=${user?.id}`)
    .then((res) => {
      // console.log(res?.data?.data, "getmindGraphReport");
      // setMindGrapherReport(res?.data?.data)

      if(res?.status == 200 || res?.status == 201 ){
        window.open(res?.data?.data?.file) 
      }
    })
    .catch((err) =>{
      console.log(err);
      alert("Report Not Generated yet")
    })
  }

  const pdfshowmindgrapher = (pdfFile) =>{
  //   // console.log(pdfFile ,"freetest0987");
  //   pdfFile ? window.open(pdfFile) : alert("Report Not Generated yet")

    api_call_token
    .get(`counseling/mindgraph/report/mind_grapher_report/?user_id=${user?.id}`)
    .then((res) => {
      // console.log(res?.data?.data, "getmindGraphReport");
      // setMindGrapherReport(res?.data?.data)

      if(res?.status == 200 || res?.status == 201 ){
        window.open(res?.data?.data?.file) 
      }
    })
    .catch((err) =>{
      console.log(err);
      alert("Report Not Generated yet")
    })
  }


  useEffect(() =>{
    gettestprogress();
    getpurchesedetails();
    // getCareerCounsellingreport();
    // getmindGraphReport();
  }, [])

  return (
    <div className={classes.root}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>
            Free Test
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className={`${styles.textContainer}`}>
            <h3 className={styles.testheading}>Free Test </h3>
            <div className={styles.actionBtns}>
              {freeTest[0]?.status == true ? (
                <button className={styles.viewReport} onClick={() => handleFreetest(freeTest[0]?.test_id[0]) }>View Report  </button>
              ) : (
                <div className={styles.pending}> Pending</div>
              )}
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion disabled={ purchasetest ? false : true} >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Alphaguide</Typography>
        </AccordionSummary>
        { 
          paidTest?.map((v, i) =>{
            return(
              <AccordionDetails>
          <div className={`${styles.textContainer}`}>
            <h3 className={styles.testheading}>{v?.test_type} test</h3>
            <div className={styles.actionBtns}>
              {v?.test_id ? (
                <button className={styles.viewReport} onClick={() => handelPaidtestReport(v?.test_id[0],v?.test_type )}>View Report </button>
              ) : (
                <div className={styles.pending}> Pending  </div>
              )}
            </div>
          </div>
        </AccordionDetails>
            )
          })
        }
        {/* <AccordionDetails>
          <div className={`${styles.textContainer}`}>
            <h3 className={styles.testheading}>Aptitude Test </h3>
            <div className={styles.actionBtns}>
              {reportStatus ? (
                <button className={styles.viewReport}>View Report </button>
              ) : (
                <div className={styles.pending}> Pending</div>
              )}
            </div>
          </div>
        </AccordionDetails>
        <AccordionDetails>
          <div className={`${styles.textContainer}`}>
            <h3 className={styles.testheading}>Interest Test </h3>
            <div className={styles.actionBtns}>
              {reportStatus ? (
                <button className={styles.viewReport}>View Report </button>
              ) : (
                <div className={styles.pending}> Pending</div>
              )}
            </div>
          </div>
        </AccordionDetails>
        <AccordionDetails>
          <div className={`${styles.textContainer}`}>
            <h3 className={styles.testheading}>Academics Test </h3>
            <div className={styles.actionBtns}>
              {reportStatus ? (
                <button className={styles.viewReport}>View Report </button>
              ) : (
                <div className={styles.pending}> Pending</div>
              )}
            </div>
          </div>
        </AccordionDetails> */}
      </Accordion>
      <Accordion disabled={ counsellingReport[0]?.status ? false : true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>
            Career Counselling
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className={`${styles.textContainer}`}>
            <h3 className={styles.testheading}>Career Counselling Report </h3>
            <div className={styles.actionBtns}>
              {counsellingReport[0]?.status ? (
                <button className={styles.viewReport} onClick={() => pdfshowcareer(counsellingReport?.file)}>View Report </button>
              ) : (
                <div className={styles.pending}> Pending</div>
              )}
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion disabled={ mindgrapherReport[0]?.status ? false : true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography className={classes.heading}>Mind Grapher</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className={`${styles.textContainer}`}>
            <h3 className={styles.testheading}>Brainmapping Report </h3>
            <div className={styles.actionBtns}>
              {counsellingReport[0]?.status ? (
                <button className={styles.viewReport} onClick={() => pdfshowmindgrapher(mindgrapherReport?.file)}>View Report </button>
              ) : (
                <div className={styles.pending}> Pending</div>
              )}
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default ReportAccordion;
