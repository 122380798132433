import React, { useEffect } from 'react';
import styles from './BrainMapping.module.css';
import { Button, colors } from '@material-ui/core';
import mappingBanner from '../../../src/Assets/mappingBanner.png';

import arrowRight from '../../../src/Assets/arrowRight.png';
import paperWork from '../../../src/Assets/paperWork.png';
import graph from '../../../src/Assets/graph.png';
import img1 from '../../../src/Assets/img1.png';
import img2 from '../../../src/Assets/img2.png';
import img3 from '../../../src/Assets/img3..png';
import img4 from '../../../src/Assets/img4.png';

import brain from '../../../src/Assets/brain.png';
import system from '../../../src/Assets/system.png';
import plus from '../../../src/Assets/plus.png';
import rocket from '../../../src/Assets/rocket.png';

import soluction1 from '../../../src/Assets/soluction1.png';

import soluction2 from '../../../src/Assets/soluction2.png';
import soluction3 from '../../../src/Assets/soluction3.png';
import soluction4 from '../../../src/Assets/soluction4.png';
import soluction5 from '../../../src/Assets/soluction5.png';
import soluction6 from '../../../src/Assets/soluction6.png';

import how1 from '../../../src/Assets/how1.png';
import how2 from '../../../src/Assets/how2.png';
import how3 from '../../../src/Assets/how3.png';
import how4 from '../../../src/Assets/how4.png';
import how5 from '../../../src/Assets/how5.png';
import how6 from '../../../src/Assets/how6.png';


import doIcon1 from '../../../src/Assets/doIcon1.png';
import doIcon2 from '../../../src/Assets/doIcon2.png';
import doIcon3 from '../../../src/Assets/doIcon3.png';
import doIcon4 from '../../../src/Assets/doIcon4.png';
import doIcon5 from '../../../src/Assets/doIcon5.png';
import doIcon6 from '../../../src/Assets/doIcon6.png';
import doIcon7 from '../../../src/Assets/doIcon7.png';
import doIcon8 from '../../../src/Assets/doIcon8.png';
import ref1 from '../../../src/Assets/ref1.png';
import ref2 from '../../../src/Assets/ref2.png';
import joinUs from '../../../src/Assets/joinUs.png';

import bannerMob from '../../../src/Assets/bannerMob.png';

import joinUSmob from '../../../src/Assets/joinUSmob.png';

function BrainMapping() {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return (
        <div className={styles.con}>
            <div className={styles.banner}>
                <div className={styles.img}>
                    <img src={mappingBanner} alt="Mapping Banner" />
                </div>
                <div className={styles.imgMobile}>
                    <img src={bannerMob} />
                </div>
                <div className={styles.btnCon}>
                    <Button variant="outlined" endIcon={<img src={arrowRight} />} className={styles.btn}>
                        Experience Now
                    </Button>
                </div>
            </div>
            <div className={styles.research}>
                <div className={styles.left}>
                    <div className={styles.leftCon}>
                        <h3 className={styles.heading}>Backed by research</h3>
                        <p className={styles.para}
                        >Assessment of Attention in Real  Classroom Environment: 
                            An EEG Based Study</p>
                        <div>
                            <Button variant="outlined" endIcon={<img src={arrowRight} />} className={styles.btnMore}>
                                Learn More
                            </Button>
                        </div>
                    </div>

                </div>
                <div className={styles.right}>
                    <div className={styles.img1}>
                        <img src={paperWork} alt="Mapping Banner" />
                    </div>
                </div>
            </div>
            <div className={styles.didCon}>
                <h3 className={styles.headingDid}>Did you know?</h3>

                <div className={styles.about}>


                    <div className={styles.rigth1}>

                        <div className={styles.img3}>
                            <img src={graph} alt="Mapping Banner" />
                        </div></div>

                    <div className={styles.left1}>
                        <div className={styles.leftConDid}>

                            <p className={styles.paraCon} > In India
                                <span className={styles.color}> 82%
                                </span>of college students did not consider their inner talents and potentials when choosing their higher education paths. </p>
                            <p className={styles.paraCon} > A 2021 survey reveals
                                <span className={styles.color1}> 75%
                                </span>  believed the obtaining of future-ready skills would have helped them in bolstering their career.</p>
                            <p className={styles.paraCon} > And over
                                <span className={styles.color2}> 53%
                                </span> emerging professional confessed that they were unable to find jobs as per their choice.</p>
                        </div>
                    </div>



                </div>
            </div>
            <div className={styles.challenge}>
                <p className={styles.chalengeHead}>Today, students face many challenges that hold back their academic and personal growth.
                </p>
                <div className={styles.cardCon}>
                    {card?.map((item) => {
                        return (
                            <div className={styles.card} style={{ background: item.color }} key={item.id}>
                                <div className={styles.cardImg}>
                                    <img src={item.img} alt="Card image" />
                                </div>
                                <p className={styles.cardDesc}>{item.para}</p>
                            </div>
                        );
                    })}

                </div>
                <div className={styles.align} >
                    <p className={styles.para}>At BrainHap, we're dedicated to offering the necessary support to  help your child pursue their dreams</p>

                </div>



            </div>
            <div className={styles.technology}>
                <p className={styles.tHead}>Introducing Brain Mapping Technology</p>

                <div className={styles.detail}>
                    <div className={styles.image}>
                        <img src={brain} />
                    </div>

                    <p className={styles.info}>
                        Brain mapping is a revolutionary technology revealing how our brains functions. By measuring the brain activity, we gain valuable insight about how we process information, our learning patterns and cognitive parameters.

                    </p>
                </div>
                <div className={styles.align} >
                    <p className={styles.para}>Our approach enables personalized assistance by identifying student's unique strengths and areas  needing support, fostering improved cognitive skills and overall growth.
                    </p>
                </div>
                <div className={styles.system}>
                    <img src={system} />
                </div>
                <div className={styles.plus}>
                    <img src={plus} />
                </div>
                <div className={styles.rocket}>
                    <img src={rocket} />
                </div>
                <div className={styles.system1}>
                    <img src={system} />
                </div>

            </div>
            <div className={styles.soluction}>
                <h3 className={styles.headingDid}>Our Solutions
                </h3>

                {soluction?.map((item) => {
                    return (

                        <div className={styles.soluctionCon}>

                            <div className={styles.soluctiomImage}>
                                <div className={styles.cardImage}>
                                    <img src={item.img} />
                                </div>
                            </div>


                            <div className={styles.soluctionInfo}>
                                <div className={styles.cardInfo}>
                                    <p className={styles.soluctionHead}>{item.heading}</p>
                                    <p className={styles.soluctionPara}>{item.para}</p>
                                    <div className={styles.option}>

                                        {item?.option?.map((opt) => {
                                            return (
                                                <p className={styles.soluctionPara}>{opt.value}</p>



                                            )
                                        })}
                                    </div>
                                </div>

                            </div>


                        </div>
                    )
                })}



            </div>
            <div className={styles.do}>
                <h3 className={styles.headingDid}>How we do this?
                </h3>
                <div className={styles.allCon}>
                    {howDo?.map((item) => {
                        return (
                            <div key={item.id} className={styles.howCon} style={{ background: item.color }}>
                                <div className={styles.howImg}>
                                    <img src={item.img} />
                                </div>
                                <div className={styles.howRight}>
                                    <p className={styles.para}>{item.head}</p>
                                    <p className={styles.howInfo}>{item.para}</p>
                                </div>
                                <div className={styles.leftImg}>
                                    <img src={item.img1} />
                                </div>
                                <div className={styles.rightImg}>
                                    <img src={item.img2} />
                                </div>

                            </div>
                        );
                    })}



                </div>
            </div>
            <div className={styles.img}>
                <img src={joinUs} />
            </div>

            <div className={styles.imgMobile}>
                <img src={joinUSmob} />
            </div>



            <div className={styles.refence}>
                <h3 className={styles.headingDid}>References</h3>
                <div className={styles.refCon}>
                    <ul>
                        <li>Janetius, Prof & Christopher, A. & Mini, Thekkechangarampatt. (2020). Magnitude of career confusion among college students in India: An empirical report. The International Journal of Indian Psychology. 8. 962-967. 10.25215/0802.114.</li>
                        <li>
                            <a href="https://economictimes.indiatimes.com/jobs/mid-career/88-indian-workers-see-upskilling-as-integral-to-career-growth-says-new-report/articleshow/99653838.cms?from=mdr" target="_blank" rel="noopener noreferrer">
                                https://economictimes.indiatimes.com/jobs/mid-career/88-indian-workers-see-upskilling-as-integral-to-career-growth-says-new-report/articleshow/99653838.cms?from=mdr
                            </a>
                        </li>
                        <li>
                            <a href="https://www.indiatoday.in/education-today/latest-studies/story/survey-reveals-33-educated-youth-are-unemployed-due-to-lack-of-future-skills-study-1854292-2021-09-18?utm_source=washare&utm_medium=socialicons&utm_campaign=shareurltracking" target="_blank" rel="noopener noreferrer">
                                https://www.indiatoday.in/education-today/latest-studies/story/survey-reveals-33-educated-youth-are-unemployed-due-to-lack-of-future-skills-study-1854292-2021-09-18?utm_source=washare&utm_medium=socialicons&utm_campaign=shareurltracking
                            </a>
                        </li>
                    </ul>

                </div>
                <div className={styles.refImg}>
                    <img src={ref1} />
                </div>
                <div className={styles.refImg1}>
                    <img src={ref2} />
                </div>
            </div>
        </div>


    );
}

export default BrainMapping;
const card = [
    {
        id: 1,
        img: img1,
        para: "Academic Pressure",
        color: 'linear-gradient(180deg, #DBFEF4 0%, #BFE1E0 100%)'
    },
    {
        id: 2,
        img: img2,
        para: "Future Uncertainties",
        color: ' linear-gradient(180deg, #C8E1C5 0%, #DDEBDC 100%)'
    },
    {
        id: 3,
        img: img3,
        para: "Mental Health Issues",
        color: 'linear-gradient(180deg, #FBF4F4 0%, #F6E8E8 100%)'
    },
    {
        id: 4,
        img: img4,
        para: "Lack of Support",
        color: 'linear-gradient(180deg, #EDF3F9 0%, #D5E2F0 100%)'
    }
];
const soluction = [
    {
        id: 1,
        img: soluction1,
        heading: "Six Comprehensive Cognitive Parameters ",
        para: `We track your child's learning patterns using
            six comprehensive parameters, offering a
            complete understanding of their cognitive
            development. `,
        option: [
            { id: 1, value: "A.Attention", },
            { id: 2, value: "B.Inattention", },
            { id: 3, value: "C.Focus", },
            { id: 4, value: "D.Concentration", },
            { id: 5, value: "E.Engagement", },
            { id: 6, value: "F.Drowsiness" },
        ]
    },
    {
        id: 2,
        img: soluction2,
        heading: "Improved Academic Performance ",
        para: `With personalized support, enhanced focus,
            and sharper thinking skills, student can
            improve academically, unlocking their full
            potential both in academic and non-academic
            areas
            `,
    },
    {
        id: 3,
        img: soluction3,
        heading: "Improved Mental Health ",
        para: `We prioritize student’s mental well-being with
            personalized counselling, promoting resilience
            and emotional balance alongside their
            academic growth.
            `,
    },
    {
        id: 4,
        img: soluction4,
        heading: "Personalised assistance ",
        para: `Your child will receive personalised support
            tailored to their unique cognitive profile. By
            identifying their strengths and weaknesses,
            we create targeted learning strategies to
            maximise their potential.
            `,
    },
    {
        id: 5,
        img: soluction5,
        heading: "Meaningful Insights ",
        para: `Our platform offers detailed brainwave-channel
            analytics and allows us to provide insights into
            your child's natural interests and aptitudes,
            guiding their educational journey.
            `,
    },
    {
        id: 6,
        img: soluction6,
        heading: "Unlocking Your Child's Full Potential ",
        para: `Our comprehensive approach aims to unlock your child's full potential, providing them with the strategies and support they need to succeed academically and thrive in all aspects of life.
            `,
    },
]
const howDo = [
    {
        id: 1,
        img: how1,
        img1: doIcon1,
        img2: doIcon2,
        head: " EEG Headset Placement",
        color: ` linear-gradient(180deg, rgba(212, 255, 242, 0.8) 0%, rgba(172, 214, 214, 0.8) 100%)`,
        para: "Our expert ensure place the EEG headset on the student's scalp, and collect the data."
    },
    {
        id: 2,
        img: how2,
        img1: doIcon3,
        img2: doIcon4,
        head: "Multiple Brain Signal Recording Sessions ",
        color: 'linear-gradient(180deg, rgba(213, 230, 212, 0.8) 0%, rgba(184, 216, 181, 0.8) 100%)',
        para: "We conduct multiple sessions to record brainwave signals, employing cutting-edge Brain Mapping technology to gather comprehensive data."
    },
    {
        id: 3,
        img: how3,
        img1: doIcon5,
        img2: doIcon6,
        head: "In-Depth Analysis and meaningful insights ",
        color: 'linear-gradient(180deg, rgba(250, 242, 242, 0.8) 0%, rgba(243, 225, 225, 0.8) 100%)',
        para: "Our neuro experts analyse the recorded results to derive meaningful insights."
    },
    {
        id: 4,
        img: how4,
        img2: doIcon7,

        head: " Collaboration with IIT Bombay Researchers",
        color: 'linear-gradient(180deg, rgba(236, 242, 249, 0.8) 0%, rgba(200, 217, 235, 0.8) 100%)',
        para: "We collaborate closely with researchers from IIT Bombay, subjecting our findings to rigorous validation and research-driven discussions."
    },
    {
        id: 5,
        img: how5,
        img2: doIcon8,

        head: "Mindgrapher Report ",
        color: 'linear-gradient(180deg, rgba(232, 236, 241, 0.8) 0%, rgba(181, 207, 216, 0.8) 100%)',
        para: "At the end, a mindgrapher report is generated, offering comprehensive insights into the student’s  neurocognitive patterns and learning potential."
    },
    {
        id: 6,
        img: how6,
        img1: doIcon1,

        head: " Real-time Actionable Insights",
        color: 'linear-gradient(180deg, rgba(255, 201, 96, 0.12) 0%, rgba(255, 201, 96, 0.4) 100%)',
        para: "Our system delivers real-time insights, empowering your child to adapt and optimise their learning approach for improved academic performance."
    },
]