import React, { useEffect } from "react";
import whoo from "../../Assets/1x/asset/woo.png";
import { useHistory } from "react-router-dom";

function Success({ closeModal, ...props }) {
  let history = useHistory();

  useEffect(() => {
    setTimeout(() => {
      // closeModal();
      history.push("/");
    }, 5000);
  }, []);
  return (
    <div style={{ textAlign: "center" }}>
      <img
        src={whoo}
        alt="wohoo"
        style={{ width: "60%" }}
        className="woo"
      ></img>
      <h1 className="success_title">Woohoo!</h1>
      {/* <p className="success_message">You are all set to Learn</p> */}
      <p className="success_message">Start your journey with BrainHap<br/> Let’s shape your Dream Career together!</p>
    </div>
  );
}

export default Success;
