import React, { useContext, useState } from "react";
import styles from "./index.module.css";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { Button, IconButton, makeStyles } from "@material-ui/core";
import cross from "../../Assets/1x/asset/close.png";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import { UserCredsContext } from "../../ContextApi/UserCredsContext/UserCredsContext";
import { api_call_token } from "../../Utils/Network";
import lcarro from "../../Assets/Logo/Artboard 1 copy 3.png";
import { useHistory } from "react-router-dom";
import dummy from "../../Assets/pp.pdf";
import dummyI from "../../Assets/dummyreport/dummyI.pdf";
import dummyA from "../../Assets/dummyreport/dummyA.pdf"
import dummyccr from "../../Assets/dummyreport/dummyccr.pdf"
import dummyaca from "../../Assets/dummyreport/DummyAcademic.pdf"


const useStyles = makeStyles((theme) => ({
    // boxStyles:{
    //   width:"100%",
    //   overflow:"auto",
    //   height:"700px",
    // },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        // color: theme.palette.grey[500],
        color: "transparent",
        // background:"#fff",
    },
}));

function loadScript(src) {
    return new Promise((resolve) => {
        const script = document.createElement("script");
        console.log(script, "script");
        script.src = "https://checkout.razorpay.com/v1/checkout.js";
        // script.onload = display.Razorpay;
        script.onload = () => {
            resolve(true);
        };
        script.onerror = () => {
            resolve(false);
        };
        document.body.appendChild(script);
    });
}

function TestPricingHindi({ isOpen, isClose, pricecheck, setPriceModel }) {
    const classes = useStyles();

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    console.log(pricecheck, "pricecheck");
    const [price, setPrice] = useState({});
    const [loading, setLoading] = useState(true);
    const [loadings, setLoadings] = useState(true);
    const [open, setOpen] = useState(false);
    const history = useHistory();
    const { user, setUser, boardList, gradeList, countrylist, statelist } =
        useContext(UserCredsContext);

    // /////////////////////////////////////////////

    const handleAdvanceBooking = (data) => {
        // debugger;
        setLoading(true);
        // console.log(data, "solts");

        // if (data == null || undefined) {
        //   console.log(data, "price");
        // } else {
        let price = data * 100;
        setPriceModel(false)

        api_call_token
            .post(`counseling/purchase/test/initiate_book_slot_and_test/`, {
                amount: price,
            })
            .then((res) => {
                console.log(res.data, "solts result");
                const payData = res?.data?.data;
                displayAdvRazorPay(payData);
            })
            .catch((err) => console.log(err));
        // }
        setLoading(false);

    };

    const handelBooking = (data) => {
        setLoading(true);
        let price = data * 100;
        setPriceModel(false)
        api_call_token
            .post(`counseling/purchase/test/initiate_book_slot/`, {
                amount: price,
            })
            .then((res) => {
                console.log(res.data, "solts result");
                const payData = res?.data?.data;
                displayRazorPay(payData);
            })
            .catch((err) => console.log(err));
        // }
        setLoading(false);
    };

    async function displayRazorPay(dataId, slotData) {
        setLoading(true);
        // console.log(dataId, slotData, "data id passed solts");
        const res = await loadScript(
            "https://checkout.razorpay.com/v1/checkout.js"
        );
        if (!res) {
            alert("razorpay sdk failed to load. Are you online?");
            return;
        }

        let current_url = window.location.href;
        if (current_url.includes("localhost") || current_url.includes("staging")) {
            // var razorpay_key = "rzp_test_TW6h2zNAZqVdu1";
            var razorpay_key = "rzp_live_lDScQvs1epmNca";
        } else {
            var razorpay_key = "rzp_live_lDScQvs1epmNca";
        }
        var courseprice = dataId.amount;
        const options = {
            key: razorpay_key,
            amount: courseprice,
            currency: "INR",
            name: "Brainhap",
            description: "Course payment",
            image: lcarro,
            order_id: `${dataId.order_id}`,
            handler: function (response) {
                console.log(response.razorpay_payment_id, "hello");
                console.log(response.razorpay_order_id, "hello1");
                console.log(response.razorpay_signature, "hello2");
                // setPaymentId(response.razorpay_payment_id);
                // var intValue = parseInt(courseId);
                // console.log(slotData, "getpurchase body");
                getpurchase(response, dataId);
            },
            prefill: {
                name: `${user?.first_name}`,
                email: `${user?.email}`,
                contact: `${user?.phone}`,
            },
        };

        var paymentObject = new window.Razorpay(options);
        paymentObject.open();
        setLoadings(false);
    }

    const getAdvpurchase = (rpid, ordId, slotData) => {
        setLoading(true);
        // console.log(slotData, "getpurchase body");
        console.log(rpid, ordId, "rpid, ordId, slotData");
        var data = {
            transaction_id: rpid?.razorpay_payment_id,
            order_id: ordId?.id,
            amount: price?.advance?.price,
            // razorpay_signature: rpid?.razorpay_signature,
        };
        console.log(data, "value to pass in purchase");
        api_call_token
            .post(`counseling/purchase/test/order_counseling_with_test/`, data)
            .then((response) => {
                console.log("The response for purchase course is ", response.data.data);
                // alert("Successfully Slot is Booked");
                if (response.status == 200 || response.status == 201 || response.status == 204) {
                    // setOpen(true);
                    // ViewAllResult()
                    setOpen(false);
                    setPriceModel(false)
                    alert("Payment Successfull");
                    history.push("/testseries");
                }
                // setSuccess(true);
            });
        // console.log(rpid, "rpid");
    };

    async function displayAdvRazorPay(dataId, slotData) {
        setLoading(true);
        // console.log(dataId, slotData, "data id passed solts");
        const res = await loadScript(
            "https://checkout.razorpay.com/v1/checkout.js"
        );
        if (!res) {
            alert("razorpay sdk failed to load. Are you online?");
            return;
        }

        let current_url = window.location.href;
        if (current_url.includes("localhost") || current_url.includes("staging")) {
            // var razorpay_key = "rzp_test_TW6h2zNAZqVdu1";
            var razorpay_key = "rzp_live_lDScQvs1epmNca";
        } else {
            var razorpay_key = "rzp_live_lDScQvs1epmNca";
        }
        var courseprice = dataId.amount;
        const options = {
            key: razorpay_key,
            amount: courseprice,
            currency: "INR",
            name: "Brainhap",
            description: "Course payment",
            image: lcarro,
            order_id: `${dataId.order_id}`,
            handler: function (response) {
                console.log(response.razorpay_payment_id, "hello");
                console.log(response.razorpay_order_id, "hello1");
                console.log(response.razorpay_signature, "hello2");
                // setPaymentId(response.razorpay_payment_id);
                // var intValue = parseInt(courseId);
                // console.log(slotData, "getpurchase body");
                getAdvpurchase(response, dataId);
            },
            prefill: {
                name: `${user?.first_name}`,
                email: `${user?.email}`,
                contact: `${user?.phone}`,
            },
        };
        var paymentObject = new window.Razorpay(options);
        paymentObject.open();
        setLoadings(false);
    }

    const getpurchase = (rpid, ordId, slotData) => {
        setLoading(true);
        // console.log(slotData, "getpurchase body");
        console.log(rpid, ordId, "rpid, ordId, slotData");
        var data = {
            transaction_id: rpid?.razorpay_payment_id,
            order_id: ordId?.id,
            razorpay_signature: rpid?.razorpay_signature,
        };
        console.log(data, "value to pass in purchase");
        api_call_token
            .post(`counseling/purchase/test/order_test_slot/`, data)
            .then((response) => {
                console.log("The response for purchase course is ", response.data.data);
                // alert("Successfully Slot is Booked");
                // if (response.data.data.id) {
                if (response.status == 200 || response.status == 201 || response.status == 204) {
                    // setOpen(true);
                    // ViewAllResult()
                    // setOpen(false);
                    // isClose();
                    setPriceModel(false)
                    alert("Payment Successfull");
                    history.push("/testseries");
                }
                // setSuccess(true);
            });
        // console.log(rpid, "rpid");
    };

    return (
        <>
      <Dialog
        // fullScreen={fullScreen}
        className={styles.boxStyles}
        open={isOpen}
        onClose={isClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {/* {"Next Question is Passage"} */}
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={isClose}
          ></IconButton>

          <img src={cross} alt="cross" className="cross" onClick={isClose} />
        </DialogTitle>

        <DialogActions>
          <div className={styles.pdfBoxListing}>
            <div className={`${styles.boxComponent}`}>
              <h2 className={`${styles.heading} ${styles.headingone}`}>
                मानक (STANDARD) <span>(₹ 500 )</span>
              </h2>
              <div className={`${styles.innerContainer}`}>
                <div className={`${styles.textConatiner}`}>
                  <h4 className={styles.textConatinerHeading}>
                  कैरियर मानचित्रण परीक्षण (CAREER MAPPING TESTS)
                  </h4>
                  <div>
                    <h5 className={styles.innerHeading}> कौशल परीक्षा: (Aptitude Test)</h5>

                    <ul className={styles.innerList}>
                      <li> 14 पैरामीटर्स के आधार पर मूल्यांकन।</li>

                      <li>
                      संभावित करियर विकल्पों के लिए अपनी योग्यता/क्षमताओं को पहचानें।
                      </li>
                    </ul>

                    <h5 className={styles.innerHeading}> रुचि परीक्षण: (Interest Test)</h5>

                    <ul className={styles.innerList}>
                      <li> 9 मापदंडों के आधार पर मूल्यांकन।</li>

                      <li>
                      सर्वोत्तम करियर विकल्पों के लिए अपने रुचि के क्षेत्र की पहचान करें।
                      </li>
                    </ul>

                    <h5 className={styles.innerHeading}> शैक्षणिक परीक्षण: (Academic Test)</h5>
                    <ul className={styles.innerList}>
                      <li>
                      ब्लूम के वर्गीकरण के आधार पर 4 अलग-अलग मापदंडों पर खुद को परखें।
                      </li>

                      <li>अपने मजबूत और कमजोर शैक्षणिक विषयों को पहचानें।</li>
                    </ul>

                    <h5 className={styles.innerHeading}> मूल्यांकन रिपोर्ट (ASSESSMENT REPORTS)</h5>
                    <ul className={styles.innerList}>
                      <li>
                      प्रत्येक परीक्षण के लिए अलग-अलग डाउनलोड करने योग्य रिपोर्ट ।
                      </li>
                    </ul>
                    <div className={styles.pdfreporttod}>
                        <a className={styles.reporttext} href={`${dummyA}#toolbar=0`} rel="noreferrer" target="_blank" >कौशल परीक्षा</a>
                        <a className={styles.reporttext} href={`${dummyI}#toolbar=0`} rel="noreferrer" target="_blank" >रुचि परीक्षण</a>
                        <a className={styles.reporttext} href={`${dummyaca}#toolbar=0`} rel="noreferrer" target="_blank" >शैक्षणिक परीक्षण</a>
                    </div>
                  </div>
                </div>
                <button
                  className={`${styles.buyButton} ${styles.headingone}`}
                  disabled={pricecheck?.standard?.status}
                  style={(pricecheck?.standard?.status) ? {cursor: 'not-allowed'} : {cursor: 'pointer'}} 
                  onClick={() => handelBooking(pricecheck?.standard?.price)}
                >
                  Buy now
                </button>
              </div>
            </div>

            {/* 2nd box component */}
            <div className={`${styles.boxComponent}`}>
              <h2 className={`${styles.heading} ${styles.headingtwo}`}>
              अग्रिम (ADVANCE)  <span>(₹ 1,000 )</span>
              </h2>
              <div className={`${styles.innerContainer}`}>
                <div className={`${styles.textConatiner}`}>
                  <h4 className={styles.textConatinerHeading}>
                  कैरियर मानचित्रण परीक्षण (CAREER MAPPING TESTS)
                  </h4>
                  <div>
                    <h5 className={styles.innerHeading}> कौशल परीक्षा: (Aptitude Test)</h5>
 
                    <ul className={styles.innerList}>
                      <li> 14 पैरामीटर्स के आधार पर मूल्यांकन।</li>

                      <li>
                      संभावित करियर विकल्पों के लिए अपनी योग्यता/क्षमताओं को पहचानें।
                      </li>
                    </ul>

                    <h5 className={styles.innerHeading}> रुचि परीक्षण: (Interest Test)</h5>

                    <ul className={styles.innerList}>
                      <li> 9 मापदंडों के आधार पर मूल्यांकन।</li>

                      <li>
                      सर्वोत्तम करियर विकल्पों के लिए अपने रुचि के क्षेत्र की पहचान करें।
                      </li>
                    </ul>

                    <h5 className={styles.innerHeading}> शैक्षणिक परीक्षण: (Academic Test)</h5>
                    <ul className={styles.innerList}>
                      <li>
                      ब्लूम के वर्गीकरण के आधार पर 4 अलग-अलग मापदंडों पर खुद को परखें।
                      </li>

                      <li>अपने मजबूत और कमजोर शैक्षणिक विषयों को पहचानें।</li>
                    </ul>

                    <h5 className={styles.innerHeading}> मूल्यांकन रिपोर्ट (ASSESSMENT REPORTS)</h5>
                    <ul className={styles.innerList}>
                      <li>
                      प्रत्येक परीक्षण के लिए अलग-अलग डाउनलोड करने योग्य रिपोर्ट 
                      </li>
                    </ul>
                    <div className={styles.pdfreporttod}>
                        <a className={styles.reporttext} href={`${dummyA}#toolbar=0`} rel="noreferrer" target="_blank" >कौशल परीक्षा</a>
                        <a className={styles.reporttext} href={`${dummyI}#toolbar=0`} rel="noreferrer" target="_blank" >रुचि परीक्षण</a>
                        <a className={styles.reporttext} href={`${dummyaca}#toolbar=0`} rel="noreferrer" target="_blank" >शैक्षणिक परीक्षण</a>
                    </div>

                    <h4 className={styles.textConatinerHeading}>
                      {" "}
                      परामर्श सत्र (COUNSELLING SESSION)
                    </h4>

                    <ul className={styles.innerList}>
                      <li>
                       आपके कैरियर विकास प्रक्रिया का मार्गदर्शन करने के लिए उद्योग विशेषज्ञों के साथ एक-पर-एक परामर्श सत्र।
                      </li>

                      <li>
                       विस्तृत कैरियर परामर्श रिपोर्ट* आपके परीक्षा परिणाम का सारांश।<br/>
                        <span className={styles.notesinlist}>
                        (*नोट: मूल्यांकन रिपोर्ट + परामर्श सत्र अंतर्दृष्टि का संयोजन)
                        </span>
                      </li>
                      {/* <li>
                        {" "}
                        Recommended Neuroscientific Techniques for academic
                        achievement in weaker areas.
                        <br />
                        <span className={styles.notesinlist}>
                          (*Note: Combination of Assessment reports +
                          counselling session insights)
                        </span>
                      </li> */}

                      <li>
                       भविष्य के लिए तैयार रहने और सफलता प्राप्त करने के लिए अपने शीर्ष 10 सर्वोत्तम करियर प्राप्त करें।
                      </li>
                    </ul>
                    <div className={styles.pdfreporttod}>
                        <a className={styles.reporttext} href={`${dummyccr}#toolbar=0`} rel="noreferrer" target="_blank" >परामर्श सत्र</a>
                        
                    </div>

                    <h5 className={styles.innerHeading}> व्हाट्सएप समर्थन (WHATSAPP SUPPORT)</h5>
                    <ul className={styles.innerList}>
                      <li> आपके सभी प्रश्नों के समाधान के लिए 24 x 7 उपलब्ध।</li>
                    </ul>
                   
                  </div>
                </div>
                <button
                  className={`${styles.buyButton} ${styles.headingtwo}`}
                  disabled={pricecheck?.advance?.status}
                  style={(pricecheck?.advance?.status) ? {cursor: 'not-allowed'} : {cursor: 'pointer'}}
                  onClick={() =>
                    handleAdvanceBooking(pricecheck?.advance?.price)
                  }
                >
                  Buy now
                </button>
              </div>
            </div>
          </div>
          {/* <Button onClick={() => handelBooking(price)}>Pay</Button> */}
        </DialogActions>
      </Dialog>
    </>
    )
}

export default TestPricingHindi