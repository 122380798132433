import React from 'react'
import styles from "./card.module.css"

function TextimgCard(value) {


  return (
    <div className={styles.mainBox}>
        <div className={styles.imgContainer}>
            <img src={value?.values?.img} alt={value?.values?.title} />
        </div>
        <h4 className={styles.boxTitle}>{value?.values?.title}</h4>
    </div>
  )
}

export default TextimgCard