import React, { useState } from "react";
import styles from "./index.module.css";
import LzProgress from "../LzProgress";
import easy from "../../../Assets/easy.png";
import medium from "../../../Assets/medium.png";
import hard from "../../../Assets/hard.png";
import rightAns from "../../../Assets/currectAns.png"
import worngAns from "../../../Assets/WrongAns.png"

const LzQuestion = (props) => {
  const {value , index} =props;
  console.log(value, "hasdjkshfdhg");
  // const v =value;
  const [dLevel, setDLevel] = useState(value?.question?.complexity);
  const [markGets, setMarksGets] = useState(value?.answer_status);

  const para ="Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at section 1.10.32."
  const words = para.split(' ');
  const limited = words.slice(0, 10).join(' ')
  return (
    <div className={styles.queReviewBox}>
      <div className={styles.queBar}>
        <p>
          Q{index + 1}. {value?.question?.title}
        </p>
        <div className={styles.timeingForQ}>
          <div style={{ width: "90%" }}>
            <LzProgress variant="determinate" value={value?.question?.time} />
          </div>
          <span style={{whiteSpace:"nowrap"}}>{value?.question?.time} sec</span>
        </div>
      </div>
      <div className={styles.difficultyBarMarks}>
        {/* <div className={styles.barImagContainer}> */}
          {/* <img
            src={dLevel == 'Easy' ? easy : dLevel == "Medium" ? medium : dLevel ? hard : ""}
            alt=""
          /> */}

         
            {
              dLevel == 'Easy' ? <h4 className={`${styles.textStyles} ${styles.easy}`}>Easy</h4> : dLevel== "Medium" ? <h4 className={`${styles.textStyles} ${styles.medium}`}>Medium</h4> : dLevel ? <h4 className={`${styles.textStyles} ${styles.hard}`}>Hard</h4> :""
            }
         
        {/* </div> */}
        <div
          className={`${styles.marksBox} ${
            markGets == 'Correct'
              ? styles.correctAns
              : markGets == 'Incorrect'
              ? styles.incorrectAns
              : ""
          }`}
        >
          <h2 className={styles.MarksIndicators}>
            <img src={markGets == 'Correct'
              ? rightAns
              : markGets == 'Incorrect'
              ? worngAns
              : ""} alt="answer-indcate" />
            
            {value?.mark} Mark(s)</h2>
        </div>
      </div>
    </div>
  );
};

export default LzQuestion;

