import React, { useState, useEffect, useCallback, useContext } from "react";
import { api_call_token } from "../../Utils/Network";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./TestSeries.module.css";
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import { LinearProgress, Typography } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import Stack from "@mui/material/Stack";
import img1 from "../../Assets/cardBackground/aptitude_pink.png";
import img2 from "../../Assets/cardBackground/academics_yellow.png";
import img3 from "../../Assets/cardBackground/interest_purple.png";
import icon1 from "../../Assets/cardBackground/Aptitude_white.svg";
import icon2 from "../../Assets/cardBackground/academics_white.svg";
import icon3 from "../../Assets/cardBackground/interest_white.svg";
import sample from "../../Assets/testpage/sample.png";
import { useLocation, useHistory } from "react-router-dom";
import { UserCredsContext } from "../../ContextApi/UserCredsContext/UserCredsContext";
import LockIcon from "@material-ui/icons/Lock";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { Button, IconButton } from "@material-ui/core";
import cross from "../../Assets/1x/asset/close.png";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import cardimgmainap from "../../Assets/cardBackground/Aptitudetestnew.png";
import cardimgmainin from "../../Assets/cardBackground/interesttestnew.png";
import cardimgmainac from "../../Assets/cardBackground/academicstestnew.png";

const useStyles = makeStyles((theme) => ({
  BackdropProps: {
    width: "500px",
  },

  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    // color: theme.palette.grey[500],
    color: "transparent",
    // background:"#fff",
  },
}));

function TestSeries(props) {
  const [instruction, setInstruction] = useState([]);
  const [testType, setTestType] = useState("");
  const [progress, setProgress] = useState([]);
  const [goToTest, setGoTo] = useState(-1);
  const [startType, setStartType] = useState(false);
  // const [backgroundImage, setBackgroundImage] = useState([
  //   { img: img3, color: "#896E8791", icon: icon2, newimg: cardimgmainap  },
  //   { img: img2, color: "#D09F127A", icon: icon3, newimg: cardimgmainin  },
  //   { img: img1, color: "#F1A9A9", icon: icon1, newimg: cardimgmainac  },
  // ]);
  const [backgroundImage, setBackgroundImage] = useState([
    { img: img3, color: "#A0B3CA", icon: icon2, newimg: cardimgmainap },
    { img: img2, color: "#E5B5B5", icon: icon3, newimg: cardimgmainin },
    { img: img1, color: "#F1A9A9", icon: icon1, newimg: cardimgmainac },
  ]);
  const { testSubmit } = useContext(UserCredsContext);
  // const { loading = false } = props;
  console.log(testSubmit, "testSubmit83682");
  const history = useHistory();
  const [minimumTime, setMinimumTime] = useState(500);
  const [minimumTimeElapsed, setMinimumTimeElapsed] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isPurchase, setIsPurchase] = useState(false);
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { location } = useLocation();
  const [testCompleted, setTestCompleted] = useState(false);
  const [isOpenDialog, setIsOpenDialog] = useState(false);

  const restartTimeout = useCallback(() => {
    setMinimumTimeElapsed(false);
    setLoading(true);
    //simulate random load time between 0 and 5 seconds
    const randomLoadTime = Math.random() * 1000;
    setTimeout(() => {
      setMinimumTimeElapsed(true);
    }, minimumTime);
    setTimeout(() => {
      setLoading(false);
    }, randomLoadTime);
  }, [setMinimumTimeElapsed, setLoading, minimumTime]);
  // console.log(backgroundImage[0], "backgroundImage");
  useEffect(() => {
    getInstruction();
    // getQuestions();
    progressTest();
    if (window.Tawk_API) {
      window.Tawk_API.hideWidget();
    }
    // return () => {
    //   if (window.Tawk_API) {
    //     window.Tawk_API.showWidget();
    //   }
    // };

    getapiForLock();
    setLoading(false);
    restartTimeout();
  }, []);

  const getapiForLock = () => {
    api_call_token
      .get(`/test/aptitude_test_question/question_progress/?test_type=interest`)
      .then((res) => {
        if (res.data) {
          setIsPurchase(res.data.is_purchased);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Show Instruction

  const getInstruction = () => {
    setLoading(true);
    api_call_token
      .get("test/instructions/")
      .then((res) => {
        setInstruction(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  const DataChange = () => {
    alert("Please give sample test first");
  };

  const progressTest = () => {
    setLoading(true);
    api_call_token
      .get("test/aptitude_test_question/progress_bar/")
      .then((res) => {
        setProgress(res.data.data.slice(0,2));
        if (res.data.data) {
          res.data.data.slice(0,2).map((v, i) => {
            if (v?.test_started) {
              testSubmit(true);
            }
          });
          // res?.data?.data?.map((v, i) =>{
          //   if(v?.submitted){
          //     setTestCompleted(true)
          //   }
          // })
          const allSubmitted = res?.data?.data?.slice(0,2).every((v) => v?.submitted);

          if (allSubmitted) {
            setTestCompleted(true);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const getPaidtestStatus = () => {
    api_call_token
    .get(`test/report/status`)
    .then((res) =>{
      // console.log(res?.data?.data?.slice(5,6) , "mnw89923h2fwhfbsh");
      const datapaid = res?.data?.data?.slice(0,3);

      // console.log(datapaid ,"datapaid");

      const allSubmitted = datapaid?.every((v) => v?.status)
      // console.log(allSubmitted, "allSubmitted");
      if (allSubmitted) {
        setTestCompleted(true);
      }

    })
    .catch((err) =>{
      console.log(err);
    })
  }

  useEffect(() =>{
    getPaidtestStatus();
  },[])
  const setTest = (val, sub) => {
    setTestType(val);
    // getQuestions({...filterObj, test_type: val ,page: 1, page_size: 10})
    setStartType(sub);
    setGoTo(0);
  };
  // console.log(testCompleted,"mytesb499874r");
  useEffect(() => {
    if (testCompleted) {
      // history.push("/ask-to-counselor")
      setIsOpenDialog(true);
    }
  }, [testCompleted]);

  const handleClose = () => {
    setIsOpenDialog(false);
  };
  return (
    <div className={styles.Back_position}>
      {loading ? (
        <>
          <div
            style={{ display: "flex", justifyContent: "space-around" }}
            className={styles.blg_container}
          >
            {[1, 2, 3].map((v, i) => (
              <div className={styles.box_blg_skeleton} key={i}>
                <Skeleton
                  animation="wave"
                  className={styles.test_inst_box}
                  height={300}
                  width={300}
                />
                <Skeleton animation="wave" width={300} height={50} />
                <Skeleton animation="wave" width={300} height={50} />
                <Skeleton animation="wave" width={300} height={50} />
              </div>
            ))}
          </div>
        </>
      ) : (
        goToTest === -1 && (
          <div className={styles.mains_test}>
            <div className={styles.test_inst_box}>
              <div
                style={{
                  margin: "6px 0 25px 0px",
                }}
              >
                <Link
                  to={{ pathname: `/` }}
                  style={{
                    width: "150px",
                    textAlign: "center",
                    fontSize: "20px",
                  }}
                  className={styles.btn_starts_test}
                >
                  Back
                </Link>
              </div>
              <div className={styles.academic_selection_container}>
                {loading ? (
                  <Stack spacing={3}>
                    <Skeleton
                      animation="wave"
                      variant="rectangular"
                      width="auto"
                      height={118}
                    />
                    <Skeleton
                      animation="wave"
                      variant="rectangular"
                      width={20}
                      height={118}
                    />
                    <Skeleton
                      animation="wave"
                      variant="rectangular"
                      width="auto"
                      height={118}
                    />
                  </Stack>
                ) : (
                  <div
                    className={styles.testflexbox}
                    style={{ display: "flex" }}
                  >
                    {/* <div
                      // style={{ backgroundImage: `url(${sample})` }}
                      className={`${styles.acc_details} `}
                    >
                      <img
                        src={sample}
                        // alt={values?.test_type}
                        className={styles.academic_img_}
                      />
                      <img
                        src={sample}
                        // alt={values?.test_type}
                        className={styles.academic_icon_}
                      />
                      <div
                        // className={values.test_type}
                        style={{ padding: "10px", marginBottom: "10px" }}
                      >
                        <p className={styles.academic_card_title}>
                          Free Test
                        </p>

                        <LinearProgress
                          className={styles.linearProgressFill}
                          // color="`${backgroundImage[ind].color}`"
                          color="secondary"
                          variant="determinate"
                          value={100}
                        />
                        <div className={styles.academic_card_percentage} style={{ marginBottom: '45px' }}>

                        </div>

                        <Typography align="center">
                          <Link
                            className={styles.btn_starts_test}
                            to={{
                              pathname: `get-all-result/all/aptitude/1`,
                              state: {
                                test: "sample"
                              }
                            }}
                            style={{
                              fontFamily: "Segoe UI",
                              fontStyle: "normal",
                              fontWeight: "400",
                              fontSize: "16px",
                              lineHeight: "21px",
                              textTransform: "capitalize",
                              color: "#000",
                              backgroundColor: "#95a78a",
                              borderRadius: "5px",
                              padding: "5px 32px",
                              boxShadow: "0.7px 0.7px 1.5px 0 #767676",
                            }}
                          >
                            {"Take Test"}
                          </Link>
                        </Typography>
                        
                      </div>
                    </div> */}

                    {progress &&
                      progress.map((values, ind) => (
                        <div
                          // style={{ backgroundImage: `url(${backgroundImage[ind]})` }}
                          className={`${styles.acc_details} `}
                        >
                          <img
                            src={backgroundImage[ind].newimg}
                            alt={values?.test_type}
                            className={styles.academic_img_}
                          />
                          {/* <img
                            src={backgroundImage[ind].icon}
                            alt={values?.test_type}
                            className={styles.academic_icon_}
                          /> */}
                          <div
                            className={values.test_type}
                            style={{ padding: "10px", marginBottom: "10px" }}
                          >
                            <p className={styles.academic_card_title}>
                              {values.test_type === "achievement"
                                ? "Academic"
                                : values.test_type}
                            </p>

                            <LinearProgress
                              className={styles.linearProgressFill}
                              variant="determinate"
                              value={values.percent}
                            />
                            <div className={styles.academic_card_percentage}>
                              {values.percent}% done
                            </div>

                            <Typography align="center">
                              {isPurchase ? (
                                <Link
                                  className={styles.btn_starts_test}
                                  to={{
                                    pathname: `/get-all-result/all/${values.test_type}/2`,
                                    state: {
                                      test: "actual",
                                    },
                                  }}
                                  style={{
                                    fontFamily: "Segoe UI",
                                    fontStyle: "normal",
                                    fontWeight: "400",
                                    fontSize: "16px",
                                    lineHeight: "21px",
                                    textTransform: "capitalize",
                                    color: "#000",
                                    backgroundColor: backgroundImage[ind].color,
                                    borderRadius: "5px",
                                    padding: "5px 32px",
                                    boxShadow: "0.7px 0.7px 1.5px 0 #767676",
                                  }}
                                >
                                  {values?.submitted
                                    ? "View Result"
                                    : "Get Started"}
                                </Link>
                              ) : (
                                <LockIcon onClick={() => DataChange()} />
                              )}
                            </Typography>
                          </div>
                        </div>
                      ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        )
      )}

      {!minimumTimeElapsed || loading ? (
        <Skeleton style={{ height: 200 }} />
      ) : (
        goToTest === 0 && (
          <div className={styles.mains_test}>
            <p className={styles.instruct}>Instructions</p>
            <div className={styles.test_inst_box}>
              {instruction.map((val, idx) => (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <p className={styles.instructions}>{idx + 1}</p>
                  <p className={styles.instructions}>{val.text}</p>
                </div>
              ))}
              {/* <button
              onClick={() => setGoTo(1)}
              className={styles.btn_starts_test}
            >
              Start Test
            </button> */}
              <Link
                to={{
                  pathname: `/testseries/${testType}/`,
                  state: { fromType: startType, test: location?.state?.test },
                }}
                className={`${styles.btn_starts_test} ${styles.btn_right}`}
              >
                Start
              </Link>
            </div>
          </div>
        )
      )}
      <Dialog
        // fullScreen={fullScreen}
        className={styles.boxStyles}
        open={isOpenDialog}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {/* {"Next Question is Passage"} */}
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClose}
          ></IconButton>

          <img
            src={cross}
            alt="cross"
            className="cross"
            onClick={handleClose}
          />
        </DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <h1 className={styles.popup_textstyles}>You have successfully completed the tests. <br/> Book your counselling session now.</h1>
          </DialogContentText>
        </DialogContent>
        {/* <DialogActions> */}
        <div className={styles.ButtonsContainer}>
          <Button variant="outlined" className={styles.btn_cancelpop}  onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button variant="contained"
          className={styles.btnGradient}
            onClick={() => {
              history.push("/ask-to-counselor");
            }}
            color="primary"
          >
            Book Session
          </Button>
          </div>
        {/* </DialogActions> */}
      </Dialog>
    </div>
  );
}

export default TestSeries;
