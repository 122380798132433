import React, { useContext, useEffect, useState } from "react";
import styles from "./index.module.css";
import Skeleton from "@material-ui/lab/Skeleton";
import Stack from "@mui/material/Stack";
import { LinearProgress, Typography } from "@material-ui/core";
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
// import test1 from "../../Assets/testpage/AdolescentSQ.jpg";
// import test2 from "../../Assets/testpage/AcademinSq.jpg";
import { api_call, api_call_token } from "../../Utils/Network";
import { useLocation, useHistory } from "react-router-dom";
import { UserCredsContext } from "../../ContextApi/UserCredsContext/UserCredsContext";
import test1 from "../../Assets/mentalhelth/Academic_Stress1.png"
import test2 from "../../Assets/mentalhelth/Adolescent_Stress2.png"
import test3 from "../../Assets/mentalhelth/Anxiety1.png"
import test4 from "../../Assets/mentalhelth/Depression1.png"
import test5 from "../../Assets/mentalhelth/Suicidal_Ideation1.png"

function MentalHealth() {
  const [loading, setLoading] = useState(false);
  const [testListing, setTestListing] = useState([]);
  const {
    user,
    loginData,
    setStudentProfile,
    setToken,
    updateLoginData,
    setUser,
    setDisplayType,
    setOpenModel,
    guardianExists,
    setGuardianExists,
  } = useContext(UserCredsContext);
  const history = useHistory();
  const [showRule, setShowRule] = useState(1);
  const [indexNumber, setIndexNumber] = useState(0);
  const [testID, setTestID] = useState(null);

  const getmentalhealthtestlisting = () => {
    setLoading(true);
    api_call_token
      .get(`content/test/listing`)
      .then((res) => {
        setLoading(false);
        console.log(res?.data, "getmentalhealthtestlisting");
        setTestListing(res?.data);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  // testing api

  const getpdfreport = () => {
    api_call_token
      .get(`content/mental/helth/report/`)
      .then((res) => {
        console.log(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getmentalhealthtestlisting();
    window.scrollTo(0, 0);
    // getpdfreport()
  }, []);

  const handelgototest = (data) => {
    console.log(data?.id, "handelgototest");
    // history.push(`/mental-health-testseries/${id}`)
    if (data?.test_status && data?.user_guardian) {
      api_call_token
        .get(`content/mental/helth/report/?test=${data?.id}`)
        .then((res) => {
          console.log(res?.data?.file, "SubmitTest");
          // setPdfFile(res?.data?.file)
          if (res?.data?.file) {
            window.open(res?.data?.file);
            // setOpen(false);
            // history.push("/mental-health-testseries")
          } else {
            alert("somethig went Wrong");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (data?.test_status && !data?.user_guardian) {
      setGuardianExists(1);
      // setOpen(false);
      history.push(`/user-registration`);
    } else {
      // history.push(`/mental-health-testseries/${data?.id}`)
      if (data?.id == 1) {
        setShowRule(2);
        window.scrollTo(0, 0);
        setIndexNumber(0);
        setTestID(data?.id);
      } else if (data?.id == 2) {
        setShowRule(2);
        window.scrollTo(0, 0);
        setIndexNumber(1);
        setTestID(data?.id);
      } else if (data?.id == 3) {
        setShowRule(2);
        window.scrollTo(0, 0);
        setIndexNumber(2);
        setTestID(data?.id);
      }
      else if (data?.id == 4) {
        setShowRule(2);
        window.scrollTo(0, 0);
        setIndexNumber(3);
        setTestID(data?.id);
      }else if (data?.id == 5) {
        setShowRule(2);
        window.scrollTo(0, 0);
        setIndexNumber(4);
        setTestID(data?.id);
      }
      
    }
  };

  const handletpback = () => {
    setShowRule(1);
  };

  // test navivgation 
  const navigatToTest = (id) =>{
    if(user.user_type === 1){
      history.push(`/mental-health-testseries/${id}`);
    }else{
      setDisplayType(0);
      setOpenModel(true);
      // setTestType(1);
    }
  }



  return (
    <div className={styles.main_backGround}>
      {showRule == 1 && (
        <>
          {loading ? (
            <>
              <div
                style={{ display: "flex", justifyContent: "space-around" }}
                className={styles.blg_container}
              >
                {[1, 2].map((v, i) => (
                  <div className={styles.secondBox} key={i}>
                    <Skeleton
                      animation="wave"
                      className={styles.listingOuterBox}
                      height={500}
                      width={300}
                    />
                    <Skeleton animation="wave" width={300} height={50} />
                    <Skeleton animation="wave" width={300} height={50} />
                    {/* <Skeleton animation="wave" width={300} height={50} /> */}
                  </div>
                ))}
              </div>
            </>
          ) : (
            <div className={styles.secondBox}>
              <div className={styles.titleintrobox}>
                <h1 className={styles.mentalHealthTitle}>MENTAL HEALTH TESTS</h1>
                {/* <h3 className={styles.mentalHealthTitletwo}>
                  TAKE A FREE TEST & GET YOUR REPORT
                </h3>
                <p className={styles.mentalHealthpara}>
                  Explore our stress assessment tools designed to evaluate your
                  mental well-being across various aspects of life. Take these
                  diagnostic tests to gain valuable insights into your stress
                  levels and empower yourself to proactively manage and enhance
                  your overall well-being.{" "}
                </p> */}
              </div>
              <div className={styles.listingOuterBox}>
                <div>
                  <Link
                    to={{ pathname: `/` }}
                    style={{
                      width: "150px",
                      textAlign: "center",
                      fontSize: "20px",
                    }}
                    className={styles.btn_starts_test}
                  >
                    Back
                  </Link>
                </div>
                <div className={styles.listingBox}>
                  {loading ? (
                    <Stack spacing={3}>
                      <Skeleton
                        animation="wave"
                        variant="rectangular"
                        width="auto"
                        height={118}
                      />
                      <Skeleton
                        animation="wave"
                        variant="rectangular"
                        width={20}
                        height={118}
                      />
                      <Skeleton
                        animation="wave"
                        variant="rectangular"
                        width="auto"
                        height={118}
                      />
                    </Stack>
                  ) : (
                    <>
                      <div className={styles.mainBoxlisting}>
                        {testListing?.map((v, i) => {
                          return (
                            <div className={styles.testBox}>
                              <div className={styles.imgContainer}>
                                <img src={v?.icon} alt={v?.title} />
                              </div>
                              <h3 className={styles.testHEading}>{v?.title}</h3>
                              {/* <LinearProgress
                            className={styles.linearProgressFill}
                            // color="`${backgroundImage[ind].color}`"
                            // color="secondary"
                            variant="determinate"
                            value={v.percent}
                          /> */}
                              {/* <div>{v?.percent}% Done </div> */}
                              <div
                                style={{
                                  margin: "0 auto",
                                  textAlign: "center",
                                }}
                              >
                                {
                                  !v?.test_status ?  <button
                                  className={styles.teststartBtn}
                                  onClick={() => handelgototest(v)}
                                  disabled={v?.test_status ? true : false}
                                  style={{cursor:`${v?.test_status ? `not-allowed` : ''}`}}
                                >
                                  {v?.test_status
                                    ? "View result"
                                    : "Start Test"}
                                </button> : ""
                                }
                                
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
        </>
      )}

      {showRule == 2 && (
        <div className={styles.introBox}>
          <div className={styles.testheading}>
            <h2 className={styles.introtestheading} onClick={handletpback}>
              {testRules[indexNumber]?.test_title}
            </h2>
          </div>

          <div className={styles.imgRuleBox}>
            <div className={styles.introimgContainer}>
              <img
                src={testRules[indexNumber]?.img}
                alt={testRules[indexNumber]?.test_title}
              />
            </div>
            <div className={styles.introtextContainer}>
              <h4 className={styles.rulesHeading}>
                INTRODUCTION 
              </h4>
              <p className={styles.rulesPara}>
                {testRules[indexNumber]?.paraone}
              </p>
              <p className={styles.rulesPara}>
                {testRules[indexNumber]?.paratwo}
              </p>
              <p className={styles.rulesPara}>
                {testRules[indexNumber]?.parathree}
              </p>
              <h5 className={styles.rulesLinstingHeading}>
                {testRules[indexNumber]?.listingheading}
              </h5>
              <ul className={styles.ruleslist}>
                {testRules[indexNumber]?.rules?.map((v, i) => {
                  return <li>{v?.item}</li>;
                })}
              </ul>

              <h4 className={styles.rulesHeading}>
                INSTRUCTIONS
              </h4>
              {testRules[indexNumber]?.instructions?.length > 0 && testRules[indexNumber]?.instructions?.map((p, i) => {
                return (
                  <p
                    className={styles.rulesPara}
                    dangerouslySetInnerHTML={{ __html: p?.para }}
                  ></p>
                );
              })}

              {
                testRules[indexNumber]?.instrParaone?.length > 0 && (
                  <p>
                    {testRules[indexNumber]?.instrParaone}
                  </p>
                ) 
              }
              {
                testRules[indexNumber]?.forEXPHEading?.length > 0 && (
                  <h5>
                    {testRules[indexNumber]?.forEXPHEading}
                  </h5>
                ) 
              }
              <ul className={styles.forExplist}>
              {
                testRules[indexNumber]?.forExp?.length > 0 &&  testRules[indexNumber]?.forExp?.map((v, i) =>{

                  return(
                    <li>{v?.list}</li>
                  )
                })
              }
              </ul>
              {
                testRules[indexNumber]?.instrParatwo?.length > 0 && (
                  <p>
                    {testRules[indexNumber]?.instrParatwo}
                  </p>
                ) 
              }
              {/* <div className={styles.rulesPara} dangerouslySetInnerHTML={} ></div> */}
            </div>
          </div>
          <div style={{ textAlign: "center" }}>
            <Link
              className={styles.teststartBtn}

              onClick={() => navigatToTest(testID)}
              // to={{
              //   pathname: `/mental-health-testseries/${testID}`,
              // }}
            >
              {" "}
              Next
            </Link>
          </div>
        </div>
      )}
    </div>
  );
}

export default MentalHealth;

const testRules = [
  {
    id: 2,
    test_title: "ADOLESCENT STRESS QUESTIONNAIRE",
    img: test2,
    paraone:
      "During adolescence, life undergoes significant and often challenging changes more than any other stage. The experience of adolescent stress has been systematically associated with a range of health compromising lifestyles and behaviours",
    paratwo:
      "The Adolescent Stress Questionnaire (ASQ) was initially crafted by Byrne and Mazanov, featuring 20 questions. Additionally, a focus group methodology was employed to generate an extra 36 items, supplementing the questionnaire.",
    listingheading: "It assesses stress levels across 10 factors:",
    rules: [
      {
        item: "Stress of Home Life",
      },
      {
        item: "Stress of School Performance",
      },
      {
        item: "Stress of School Attendance",
      },
      {
        item: "Stress of Romantic Relationships",
      },
      {
        item: "Stress of Peer Pressure",
      },
      {
        item: "Stress of Teacher Interaction",
      },
      {
        item: "Stress of Future Uncertainty",
      },
      {
        item: "Stress of School/Leisure Conflict",
      },
      {
        item: "Stress of Financial Pressure",
      },
      {
        item: "Stress of Emerging Adult Responsibility",
      },
    ],
    instructions: [
      {
        para: "This scale consists of 56 items that describe various stressors in the lives of adolescents from different sources over the past year.",
      },
      {
        para: "You can indicate the level of stress you feel for each item by selecting the option that best represents your experience, ranging from <span> 'Not at all stressful,'</span>  <span>A Little stressful,</span> <span>Moderately stressful,</span> <span>Quite stressful</span> to <span>Very stressful</span>.",
      },
    ],
  },
  {
    id: 1,
    test_title: "ACADEMIC STRESS SCALE",
    img: test1,
    paraone:
      "Academic stress may be the single most dominant stress factor that affects the mental well-being of school students. Academic stress refers to the pressures and challenges experienced by individuals in an educational setting, typically resulting from the demands, expectations, and responsibilities associated with academic pursuits.",
    paratwo:
      "The Academic Stress Scale is rooted in the scale originally developed by Kim in 1970. Rajendran and Kaliappan (1990) adapted this scale to Indian conditions.",
    listingheading:
      "The items are classified into five areas containing 8 items each",
    rules: [
      {
        item: "Personal Inadequacy",
      },
      {
        item: "Fear of Failure",
      },
      {
        item: "Interpersonal difficulties with teachers",
      },
      {
        item: "Teacher-pupil relationship / Teaching methods",
      },
      {
        item: "Inadequate study facilities",
      },
    ],
    instructions: [
      {
        para: "This scale comprises 40 items that describe the stress in your academic life originating from various sources.",
      },
      {
        para: "You can indicate the level of stress for each item by selecting from the options: <span>No Stress,</span>  <span>Slight Stress,</span> <span>Moderate Stress,</span> <span>High Stress,</span> to <span>Extreme Stress,</span> based on your perceived stress level for each.",
      },
    ],
  },
  {
    id: 3,
    test_title: "EMOTION REGULATION TEST",
    img: test4,
    paraone:
      "Emotion regulation plays a crucial role in shaping how individuals experience and respond to their emotions. The Emotion Regulation Questionnaire (ERQ) is a widely used self-report measure developed by Gross and John in 2003 to assess individual differences in emotion regulation strategies.",
    paratwo:
      "The ERQ focuses on two primary emotion regulation strategies: Cognitive Reappraisal and Expressive Suppression. Cognitive Reappraisal involves changing the way one thinks about a situation in order to modify its emotional impact, whereas Expressive Suppression involves inhibiting the outward expression of emotions.",
    parathree:
    "Understanding one's preferred emotion regulation strategies can provide valuable insights into how individuals manage their emotions in various contexts, from everyday life to stressful situations.",
      listingheading:
      "",
    rules: [],
    instrParaone : "This questionnaire consists of 10 statements related to how you typically regulate your emotions. Please read each statement carefully and indicate how much you agree or disagree with it based on your typical behaviour.",
    forEXPHEading:"For each statement, choose the option that best reflects how frequently you engage in the described behaviour. For Example",
    forExp: [
      {
        list:"Strongly Disagree"
      },
      {
        list:"Disagree"
      },
      {
        list:"Somewhat Disagree"
      },
      {
        list:"Neutral"
      },
      {
        list:"Somewhat Agree"
      },
      {
        list:"Agree"
      },
      {
        list:"Strongly Agree"
      },
    ],
    instrParatwo:"Select the response that most accurately reflects your typical approach to regulating your emotions. There are no right or wrong answers; simply choose the option that best describes your usual behaviour.",
  },
  {
    id: 4,
    test_title: "ANXIETY & DEPRESSION TEST",
    img: test3,
    paraone:
      "Anxiety & Depression is a significant concern for students, as it can profoundly affect their well-being, especially in educational environments where stressors are prevalent and heightened. The Hospital Anxiety and Depression Scale (HADS) is a widely used tool designed to assess and quantify symptoms of anxiety and depression of school students in various settings.",
    paratwo:
      "Originally developed by Zigmond and Snaith in 1983, the HADS has since become a standard measure for screening anxiety and depression in various clinical populations. Its brevity, simplicity, and emphasis on emotional distress make it especially well-suited for school settings.",
    parathree:
    "The HADS consists of two subscales: Anxiety and Depression. Each subscale comprises seven items, providing a comprehensive assessment of these emotional states of students.",
      listingheading:
      "",
    rules: [],
    instrParaone : "This scale consists of 14 items, divided into two subscales: Anxiety and Depression. Please read each statement carefully and select the response that best reflects how you have been feeling in the past week, including today.",
    forEXPHEading:"For each statement, choose the option that most accurately describes your experience. For Example:",
    forExp: [
      {
        list:" I do not feel this way at all"
      },
      {
        list:"I feel this way to some extent"
      },
      {
        list:"I feel this way a good bit of the time"
      },
      {
        list:"I feel this way most of the time"
      },
    ],
    instrParatwo:"Select the response that best reflects your feelings, focusing on your experiences. There are no right or wrong answers; simply choose the option that corresponds most closely to your current state. ",
  },
  {
    id: 5,
    test_title: "SUICIDAL IDEATION TEST",
    img: test5,
    paraone:
      "Suicide is a complex and multifaceted phenomenon influenced by a myriad of psychological, social, and environmental factors. The Multi-Attitude Suicide Tendency Scale (MAST) is a psychometric tool developed to assess various attitudes and tendencies related to suicide risk.",
    paratwo:
      "Originally developed by Orbach et al. in 1983, the MAST aims to provide a comprehensive assessment of suicide-related attitudes and behaviors, allowing for a nuanced understanding of suicide risk factors.",
    parathree:
    "The MAST encompasses multiple dimensions, including attitudes toward life and death & tendencies of life and death. By exploring these different aspects, the MAST offers valuable insights into an individual's suicide risk profile, facilitating targeted intervention and support.",
      listingheading:
      "",
    rules: [],
    instrParaone : "This scale comprises a series of statements (30 questions) related to attitudes and tendencies concerning life, & death. Please read each statement carefully and indicate how much you agree or disagree with it based on your own thoughts and feelings.",
    forEXPHEading:"For each statement, choose the option that best reflects your agreement or disagreement. For example:",
    forExp: [
      {
        list:"Strongly Disagree"
      },
      {
        list:"Disagree"
      },
      {
        list:"Neither agree nor disagree"
      },
      {
        list:"Agree"
      },
      {
        list:"Strongly Agree"
      },
    ],
    instrParatwo:"Select the response that most closely aligns with your own beliefs and experiences. There are no right or wrong answers; simply choose the option that best represents your perspective.",
  },
  
];