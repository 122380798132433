import React, { useEffect, useState, useContext } from "react";
import Dialog from "@material-ui/core/Dialog";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import "./DialogBox.css";
import Login from "./Login";
import StudeIcon from "../../Assets/Onboard/STUDENT w.png";
import StudImg from "../../Assets/1x/asset/girlrev.png";
import studs from "../../Assets/1x/asset/asset10.png";
import Authenticate from "./Authenticate";
import { UserCredsContext } from "../../ContextApi/UserCredsContext/UserCredsContext";
import FormDetail from "./FormDetail";
import Success from "./Success";
import { School } from "@material-ui/icons";
import ModelInner from "../ModelPageInner/ModelInner";
import { makeStyles } from "@material-ui/core/styles";
import cross from "../../Assets/1x/asset/close.png";
import {
  login_api,
  api_token,
  api_call,
  setApiToken,
} from "../../Utils/Network";
import Button from "@material-ui/core/Button";
import "./Signin.css";
import ProfileDrop from "../ProfileDrop/ProfileDrop";
import ForgetPassword from "./ForgotPassword";
import logos from "../../Assets/brainhap/logosmall.png";
import bannerSignup from "../../Assets/brainhap/bannerSignup.png";

import CloseIcon from '@material-ui/icons/Close';

function DialogBox({ _open, closeModal, ...props }) {
  const {
    loginData,
    updateLoginData,
    studentProfile,
    updateUserType,
    updatePartnerType,
    partnerType,
    displayType: disType,
    setDisplayType: setDisType,
    openModel,
    setOpenModel,
    userType,
    token,
    setToken,
    setUser,
    user,
    redirectMyspace,
    setRedirectMyspace,
    logout,
  } = useContext(UserCredsContext);
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [loginType, setLoginType] = useState("user");
  const [usersign, setUsrSign] = useState([]);
  const [displayType, setDisplayType] = useState(null);
  const [errors, setErrors] = useState({});
  const [contactNumber, setContactNumber] = useState({
    code: "+91",
    number: null,
  });
  const useStyles = makeStyles({
    root: {
      borderRadius: 6,
    },
    paper: {
      borderRadius: 13,
    },
  });
  const temp = {};
  const classes = useStyles();
  const [loginD, setLogin] = useState({
    username: "",
    password: "",
  });

  const handelLoginData = (event) => {
    const { name, value } = event.target;

    setLogin({ ...loginD, [name]: value });
    setErrors({});
  };

  const setuserSign = () => {
    login_api
      .get(`/auth/user_signup_types`)
      .then((response) => {
        var [student, teacher, school, online, institute] = response.data.data;
        setUsrSign([student, teacher, institute, online, school]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  ///////////  Check user type for redirecting to different forms

  const setType = (_data) => {
    console.log(_data, "data)Type");
    updateUserType(_data.user_type);
    updatePartnerType(_data.partner_type);
    updateLoginData({
      type: "login",
    });
    setDisplayType(2);
    if (_data.user_type == 8) {
      // history.push('/teachersregistration')
      window.open("https://coaching.lcarro.com/#/", "_blank");
      closeModal = handleClose();
    } else if (_data.user_type == 7 && _data.partner_type == 1) {
      window.open("https://coaching.lcarro.com/#/", "_blank");
      closeModal = handleClose();
    }
  };
  useEffect(() => {
    setuserSign([]);
    updateLoginData({
      code: "+91",
      phone: "9773526679",
      otp: "",
      type: "login",
    });
  }, []);

  const setTypes = () => {
    console.log("hellos");
    updateLoginData({
      type: "forg",
    });
    setDisType(2);
  };
  useEffect(() => {
    setDisplayType(disType);
  }, [disType]);

  useEffect(() => {
    setOpen(openModel);
  }, [openModel]);

  const validate = () => {
    let regEx = /^[0-9a-zA-Z]+$/;
    let returnValue = true;
    temp.username =
      loginD.username === ""
        ? "Please enter username"
        : loginD.username.match(regEx)
        ? ""
        : "Please enter proper username";
    temp.password = loginD.password === "" ? "Please enter password" : "";
    setErrors({
      ...temp,
    });
    var found = Object.keys(temp).filter(function (key) {
      return temp[key].includes("Please");
    });
    if (found.length) {
      returnValue = false;
    }
    return returnValue;
  };
  //////////  Login code for student

  const SubmitLogins = () => {
    if (!validate()) {
      return null;
    }

    var loginDetails = {
      username: loginD.username,
      password: loginD.password,
    };

    api_call
      .get(`auth/user/unique_username_check/?username=${loginD.username}`)
      .then((response) => {
        // console.log(response, "responseee")
        const { user_type } = response.data.data;
        if (user_type && user_type === 1) {
          api_call
            .post(`/auth/user/login/`, loginDetails)
            .then((response) => {
              if (redirectMyspace.redirect) {
                //Redirect to MySpace Logic
              } else {
                setRedirectMyspace(true, true);
              }
              localStorage.setItem(
                "network_access_token",
                response.data.data.token.access
              );
              setApiToken(response.data.data.token.access);
              setToken(response.data.data.token);
              setUser(response.data.data.user);
              handleClose();
            })
            .catch((error) => {
              if (error) {
                const { message } = error.response.data.error;
                setErrors({ message });
              }
            });
        } else {
          setDisplayType(5);
        }
      })
      .catch((error) => {
        if (error) {
          // console.log(error.response.data.error.message, "ErrorLogin");
          // const {message} = error.response.data.error;
          // setErrors({message})
        }
      });
  };

  // Login UI
  const getLogin = () => {
    return (
      <div className="Containers_popup" style={{ position: "relative" }}>
        {/* <img src={cross} alt="cross" className="cross" onClick={handleClose} /> */}
        {/*import CloseIcon from '@mui/icons-material/Close'; */}
        <CloseIcon  className="cross"  onClick={handleClose} />
        <div className="modal_head">
          <h1>Sign in</h1>
        </div>
        <div className="signin_content">
          <div className="stud_dialogue stud_dialogs">
            <img
              src={bannerSignup}
              alt="StudentIcon"
              className="studImg"
              style={{ marginTop: "-50px" }}
            ></img>
          </div>
          <div className="sign_cont_box">
            <div style={{ textAlign: "center" }}>
              <img src={logos} style={{ width: "50px" }} />
            </div>

            <div className="signin_input_box">
              <div className="signin_input">
                {/* <input
                  type="text"
                  name="code"
                  list="countryCode"
                  id="city"
                  // onChange={(e) => checkId(e, "select")}
                  defaultValue={contactNumber.code}
                /> */}
                <input
                  name="username"
                  placeholder="User Name/Phone Number"
                  onChange={handelLoginData}
                  className="inputs"
                ></input>
              </div>
              <p className="error-message-text error-marginbottom">
                {errors.username}
              </p>
            </div>
            <div className="signin_input_box">
              <input
                name="password"
                type="password"
                placeholder="password"
                onChange={handelLoginData}
                className="signin_input"
              ></input>
              <p className="error-message-text error-marginbottom">
                {errors.password}
              </p>
              <p className="error-message-text error-marginbottom">
                {errors.message}
              </p>
            </div>
            <div
              className="signin_input_box"
              style={{ textAlign: "left", marginBottom: "15px" }}
            >
              <span
                className="forgot_pass"
                onClick={setTypes}
                style={{
                  color: "#172B69",
                  cursor: "pointer",
                  paddingLeft: "34px",
                }}
              >
                Forgot password ?
              </span>
            </div>
            <div style={{ textAlign: "center" }}>
              <Button
                variant="contained"
                className="btn_model"
                onClick={SubmitLogins}
                color="primary"
                style={{
                  borderRadius: "8px",
                  width: "82%",
                  fontSize: "18px",
                  fontWeight: "550",
                  backgroundColor: "#fff",
                  color: "rgb(3, 44, 107)",
                  fontWeight: "bold",
                }}
                // disabled={enable}
              >
                Login
              </Button>
            </div>
            <div style={{ textAlign: "center", marginTop: "20px" }}>
              <Button
                variant="contained"
                className="btn_model"
                onClick={handleClickOpen}
                color="primary"
                style={{
                  borderRadius: "8px",
                  width: "82%",
                  fontSize: "18px",
                  fontWeight: "550",
                  backgroundColor: "#fff",
                  color: "rgb(3, 44, 107)",
                  fontWeight: "bold",
                }}
                // disabled={enable}
              >
                New User Sign in?
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const getDetails = () => {
    return (
      <div className="Containers_popup" style={{ position: "relative" }}>
        <img src={cross} alt="cross" className="cross" onClick={handleClose} />
        <div className="modal_head">
          {/* <img src={StudeIcon} alt="Student Icon" className="StudIcon"></img> */}
          <h1>Sign up as</h1>
        </div>
        <div className="body">
          <div className="body_container bdy_ptnr">
            {usersign &&
              usersign !== undefined &&
              usersign !== 0 &&
              usersign.map((itm, indx) => {
                return (
                  <div
                    className="bdy_box"
                    onClick={() => setType(itm)}
                    style={{ cursor: "pointer" }}
                  >
                    <img
                      src={itm.icon}
                      alt="teacher"
                      className="icon_model"
                    ></img>
                    <p>{itm.title}</p>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    );
  };

  const sorryDetails = () => {
    return (
      <>
        <div className="modal_head">
          <img src={StudeIcon} alt="Student Icon" className="StudIcon"></img>
          <h1>Sign up</h1>
        </div>
        <div className="oops_content">
          <h3 className="sign_err">
            {" "}
            User is not registered. Please register the user first.
          </h3>
          {/* <h3 className="sign_err">Only Student can Sign in through browser</h3> */}
          {/* <h3 className="sign_err">
            Other Users can Sign in through mobile app
          </h3> */}
        </div>
      </>
    );
  };

  const enterDetails = () => {
    return (
      <div className="Containers_popup">
        <div className="modal_head">
          <img src={StudeIcon} alt="Student Icon" className="StudIcon"></img>
          <h1>Sign up</h1>
        </div>
        <div className="popup_content">
          <div className="stud_content">
            {loginData.type === "forg" && (
              <ForgetPassword closeModal={handleClose} />
            )}

            {/* live code  */}

            {loginData.type === "login" && <Login open={open} closeModal={handleClose} />}
            {loginData.type === "auth" && (
              <Authenticate closeModal={handleClose} />
            )}
            {loginData.type === "usr" && <FormDetail />}

            {/* for test Only */}
            {/* {loginData.type === "login" && <FormDetail />} */}
            {/*usr - for live*/}
            {/* test */}

            {loginData.type === "subm" && <Success closeModal={handleClose} />}
          </div>
        </div>
      </div>
    );
  };
  const handleClick = () => {
    // debugger;
    // const script = document.querySelector('script[src="https://otpless.com/auth.js"]');
    // if (script) {
    //   document.body.removeChild(script);
    // }
    // const newScript = document.createElement('script');
    // newScript.src = 'https://otpless.com/auth.js';
    // document.body.appendChild(newScript);

    setDisplayType(0);
    if (token.access) {
      return;
    }
    setOpen(true);
  };

  const handleClickOpen = () => {
    setDisplayType(2);
    updateUserType(1);
    if (token.access) {
      return;
    }
    // setOpenModel(true);
    setOpen(true);
  };

  const handleClose = () => {
    setOpenModel(false);
    setOpen(false);
    setDisplayType(null);
    setDisType(1);
  };
  return (
    <div>
      <div className="logins">
        {token.access ? (
          (user.first_name !== null &&
            user.first_name !== undefined &&
            user.first_name) ||
          (user.hasOwnProperty("institute") &&
            user.institute.name !== null &&
            user.institute.name !== undefined &&
            user.institute.name) ? (
            <ProfileDrop datas={user} />
          ) : (
            <>User</>
          )
        ) : (
          <>
            <button style={{background: "linear-gradient( 97.14deg, #11888a -3.11%, #2a5e95 100% )", borderRadius:"5px" , color:'white' , fontWeight:"bold"  }} className="navsgn nav-signin" onClick={handleClick}>
              Login
            </button>
            {/* <button className="navsgn nav-signup" onClick={handleClickOpen}>
              Sign up
            </button> */}
          </>
        )}

        {/* {token.access && loginToken === 2 ? (
          user.first_name ? (
            <p>{user.first_name}</p>
          ) : (
            <>User</>
          )
        ) : (

        )} */}
      </div>

      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        maxWidth="xl"
        className={classes.paper}
      >
        {displayType === 0 && getLogin()}
        {displayType === 1 && getDetails()}
        {displayType === 2 && enterDetails()}
        {displayType === 5 && sorryDetails()}
      </Dialog>
    </div>
  );
}
export default DialogBox;
