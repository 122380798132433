import React from 'react'
import styles from "./index.module.css"

const NeuroForSuccessCard = ({values}) => {
  return (
    <>
    <div className={styles.cardContainer}>
        <div className={styles.imgContainer}>
            <img src={values?.img} alt={values?.title} />
        </div>

        <h4>{values?.title}</h4>
        <p>{values?.details}</p>
    </div>
    </>
  )
}

export default NeuroForSuccessCard