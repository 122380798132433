import React, { useEffect, useState } from "react";
import styles from "./steam.module.css";
import DIYImg from "../../Assets/Do it Yourself.png";
import mcqImg from "../../Assets/MCQ (1).png";
import worksheetImg from "../../Assets/Workdheet.png";
import { border, color } from "@mui/system";
import sub1 from "../../Assets/alml.png";
import sub2 from "../../Assets/robotic.png";
import sub3 from "../../Assets/html.png";
import sub4 from "../../Assets/python.png";
import { api_call_token } from "../../Utils/Network";

function STEAMReport() {
  const [subjectSelection, setSubjectSelection] = useState();
  const [activeSub, setActiveSub] = useState();
  const [testTypeActive, setTestTypeActive] = useState();
  const [courseList, setCourseList] = useState([]);
  const [testPerformance, setTestPerformance] = useState([]);

  const handleSeletedSubject = (data) => {
    setActiveSub(data?.id);
    setSubjectSelection(data);
  };

  const handleTestType = (data) => {
    setTestTypeActive(data?.id);
    setActiveSub();
    setSubjectSelection();
    setTestPerformance([]);
  };

  useEffect(() => {
    getCourseList();
  }, []);

  const getCourseList = () => {
    api_call_token
      .get(`content/V1/lms/course/`)
      .then((res) => {
        console.log(res?.data?.data);
        setCourseList(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (testTypeActive && subjectSelection?.id) {
      const timout = setTimeout(() => {
        const finalFilters = {};
        finalFilters.test_type = testTypeActive;
        // finalFilters.size = 10;
        finalFilters.course_id = subjectSelection?.id;

        getoverallstudentTestReports(finalFilters);
      }, 100);
      return () => clearTimeout(timout);
    }
  }, [testTypeActive, subjectSelection]);

  const getoverallstudentTestReports = (newfillters) => {
    api_call_token
      .get(`content/test-performance/get_reports/`, {
        params: { ...newfillters },
      })
      .then((res) => {
        console.log(res?.data?.data);
        setTestPerformance(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className={styles.MainCOntainer}>
      <div className={styles.testTypeContainer}>
        {testType?.map((v, i) => {
          return (
            <div
              style={{
                background: v?.background,
                border:
                  testTypeActive === v?.id
                    ? `2px solid ${v?.borderColor}`
                    : "2px solid #fff",
              }}
              key={v?.id}
              className={`${styles.testName} ${testTypeActive === v?.id ?  styles.testTypeBoxShadow : ''}`}
              onClick={() => handleTestType(v)}
            >
              <h2 className={styles.showText}>{v?.title}</h2>
              <div className={styles.bgimgcontainer}>
                <img src={v?.img} />
              </div>
            </div>
          );
        })}
      </div>
      {courseList?.length > 0 &&
        (
          <>
            <h3 className={styles.containerHeading}>Select your Course</h3>

            <div className={styles.subjectListing}>
              {courseList?.map((v, i) => {
                return (
                  <div
                    style={{
                      backgroundColor: v?.secondary_color,
                      border:
                        activeSub === v?.id
                          ? `2px solid ${v?.primary_color}`
                          : `2px solid #fff`,
                    }}
                    className={`${styles.subjectNameContainer} ${
                      activeSub === v?.id ? styles.activesubSelection : ""
                    } `}
                    onClick={() => handleSeletedSubject(v)}
                  >
                    <div className={styles.imgContainer}>
                      <img src={v?.icon} />
                    </div>
                    <h2
                      style={{ color: v?.primary_color }}
                      className={styles.subText}
                    >
                      {v?.title}
                    </h2>
                  </div>
                );
              })}
            </div>
          </>
        )}

      {testPerformance?.length > 0 && (
        <>
          <h3 className={styles.containerHeading}>Test List</h3>

          <div className={styles.testList}>
            {testPerformance?.map((v, i) => {
              return (
                <div className={styles.testInfoBox}>
                  <div className={styles.flexBoxes}>
                    <div className={styles.leftInfotext}>
                      <h5> {v?.chapter_name}</h5>
                      <p>Test taken on 23rd March 2024</p>
                    </div>
                    <h3 className={styles.values}>{v?.percentage}%</h3>
                  </div>
                  <div className={styles.rangeBox}>
                    <div className={styles.grayBG}></div>
                    <div
                      style={{ width: `${v?.percentage}%` }}
                      className={styles.colorBG}
                    ></div>
                  </div>
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
}

export default STEAMReport;

const testType = [
  {
    id: 3,
    test_type: 3,
    title: "Do It Yourself",
    img: DIYImg,
    background: "linear-gradient(270deg, #ff6969 0%, #b30000 100%)",
    borderColor: "#B70505",
  },
  {
    id: 1,
    test_type: 1,
    title: "MCQ",
    img: mcqImg,
    background: "linear-gradient(270deg, #fba834 0%, #7d4a02 100%)",
    borderColor: "#7D4A02",
  },
  {
    id: 2,
    test_type: 2,
    title: "Worksheet",
    img: worksheetImg,
    background: "linear-gradient(270deg, #5bbcff 0%, #004b80 100%)",
    borderColor: "#004B80",
  },
];

const subjectListing = [
  {
    id: 1,
    title: "AI / ML",
    bgColor: "#EEF9EC",
    textColor: "#347928",
    img: sub1,
  },
  {
    id: 2,
    title: "Robotics",
    bgColor: "#FEEFE7",
    textColor: "#E85C0D",
    img: sub2,
  },
  {
    id: 3,
    title: "HTML",
    bgColor: "#E7EEFD",
    textColor: "#4379F2",
    img: sub3,
  },
  {
    id: 4,
    title: "Python",
    bgColor: "#FDE8EF",
    textColor: "#D91656",
    img: sub4,
  },
  {
    id: 5,
    title: "Robotics",
    bgColor: "#FEEFE7",
    textColor: "#E85C0D",
    img: sub2,
  },
  // {
  //     id:5,
  //     title:"",
  //     color1:"",
  //     color2:"",
  //     img:"",
  // },
];
