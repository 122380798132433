import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { api_call } from "../../Utils/Network";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

function BlogDetails() {
  const { blogSlug } = useParams();
  let history = useHistory();
  const [blog, setBlog] = useState();

  useEffect(() => {
    if (blogSlug) {
      getBlogapi(blogSlug);
    }
  }, [blogSlug]);

  const getBlogapi = (slug) => {
    api_call
      .get(`test/blog/${slug}`)
      .then((res) => {
        console.log(res.data.data, "Blog");
        setBlog(res.data.data);
        //   setLoading(false);
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <div
        onClick={() => {
          //   setView(0);
          history.push(`/blog`);
        }}
        style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
      >
        <ArrowBackIcon />
        <p>Back</p>
      </div>
      <div className={styles.displayed}>
        <div>
          <img
            src={blog?.cover_thumbnail}
            alt="demo"
            className={styles.sel_image}
          />
        </div>
        <div className={styles.bg_category_inner}>
          {" "}
          {blog?.blog_category?.title}
        </div>
        <div className={styles.blg_content_main}>
          <div dangerouslySetInnerHTML={{ __html: blog?.blog_content }}></div>
        </div>
      </div>
    </>
  );
}

export default BlogDetails;
