import React from 'react'
import styles from "./gallery.module.css"
import Carousel from "react-multi-carousel";
import cf2 from "../../../Assets/NewHomepageImg/cf-2.png";
import gicon from "../../../Assets/NewHomepageImg/galleryicon1.png";

const NewGallery = (values) => {

    const Gallerydata = values?.values

    const responsiveCoursesgallry = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 3,
          slidesToSlide: 1,
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3,
          slidesToSlide: 1,
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2,
          slidesToSlide: 1,
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1,
          slidesToSlide: 1,
        },
      };
  return (
    <section className={styles.gallerySection}>
        <h4 className={styles.sectionHeading}>Gallery</h4>
        <Carousel
          responsive={responsiveCoursesgallry}
          autoPlay={true}
          autoPlaySpeed={3000}
          infinite={true}
          arrows={true}
          // className={styles.slider}
        >
          {Gallerydata?.map((v, i) => {
            return (
              <>
                <div
                  key={i}
                  className={`${styles.galleryimgContainer} ${styles.hidemobile}`}
                >
                  <img src={v?.img} alt={v.id} />
                  {/* <h1>{v?.id}</h1> */}
                </div>
                <div
                  key={i}
                  className={`${styles.galleryimgContainer} ${styles.showmobile}`}
                >
                  <img src={v?.mobileimg} alt={v.id} />
                  {/* <h1>{v?.id}</h1> */}
                </div>
              </>
            );
          })}
        </Carousel>
        <div className={`${styles.postions} ${styles.galleryIcon2}`}>
          <img src={cf2} />
        </div>
        <div className={`${styles.postions} ${styles.galleryIcon1}`}>
          <img src={gicon} />
        </div>
      </section>
  )
}

export default NewGallery