import React from "react";
import styles from "./index.module.css";

function BenefitCard() {
  return (
    <>
      {cardData?.map((v, i) => {
        return (
          <>
          <div className={styles.outercardContainer} key={i}>
            {/* <div className={styles.InnerCardContainer}></div> */}
            <h3>
              {v?.title}
            </h3>
            <div className={styles.titleunderline}></div>
            <p>{v?.para}</p>
            <div className={styles.cardnum}>0{v?.id}</div>
          </div>

          </>
        );
      })}
    </>
  );
}

export default BenefitCard;

const cardData = [
  {
    id: 1,
    title: "Comprehensive Understanding of Cognitive Abilities",
    para: "BrainHap’s brain mapping technology deep dives into the unique cognitive profile of each student. By evaluating various aspects such as problem-solving skills, creativity, logical reasoning, and emotional intelligence, we provide a thorough understanding of your child’s intellectual landscape",
  },
  {
    id: 2,
    title: "Identify Strengths and Interests",
    para: "Our detailed analysis helps pinpoint your child's inherent strengths and passions. Whether your child has a knack for analytical thinking, a talent for creativity, or an aptitude for interpersonal skills, BrainHap identifies these traits to guide them towards the most suitable academic stream.",
  },
  {
    id: 3,
    title: "Informed Decision - Making",
    para: "With the insights gained from our brain mapping test, students can make well-informed decisions about their subject choices and career paths. This ensures they select the stream that not only aligns with their natural aptitudes but also encourages their passion for learning and personal growth.",
  },
];
