import React, { useEffect, useState } from "react";
import { api_call } from "../../Utils/Network";
import styles from "./index.module.css";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Skeleton from "@material-ui/lab/Skeleton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MailIcon from "@material-ui/icons/Mail";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import Divider from "@material-ui/core/Divider";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useHistory } from 'react-router-dom';
import { DataUsageOutlined, ImportantDevices, NoEncryption } from "@material-ui/icons";
import { color } from "@mui/system";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
      marginTop: "20px",
    },
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  appBar: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      backgroundColor: "white",
      color: "black",
      top: "120px",
      boxShadow: "none",
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    position: "relative",
    zIndex: 0,
    border: 'none',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  colors: {
    color: 'red !important',
  }
}));

function Blog(props) {
  let history = useHistory ();
  const { window } = props;
  const [blog, setBlog] = useState([]);
  const [single, setSingleValue] = useState({});
  const [view, setView] = useState(0);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState({});
  const [blogCat, setBlogCat] = useState([]);
  const [titles, setTitle] = useState('All')
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  useEffect(() => {
    getBlog();
    getBlogCategory();
  }, []);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const getBlogCategory = () => {
    api_call
      .get(`/test/blog_category`)
      .then((res) => {
        setBlogCat(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;
  const getBlog = (paramObj = { ...filter, page_size: 10 }) => {
    api_call
      .get(`test/blog/`, { params: { ...paramObj } })
      .then((res) => {
        console.log(res.data.data, "Blog");
        setBlog(res.data.data);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };

  const setSingle = (val) => {
    console.log(val, "setSingle");
    history.push(`/blog/${val?.slugs}`)
    setSingleValue(val);
    setView(1);
  };

  const DataSend = () => {
    history.push('/')
  }
  console.log(titles, "title")
  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <h1>Category</h1>
      <Divider />
      <List>
        <ListItem button onClick={() => { getBlog({ ...filter, page_size: 10 }); setView(0); setTitle('All') }}>
          <ListItemText primary={"All"} style={{ color: `${titles === 'All' ? "#ee9437" : '#192f69'}` }} />
        </ListItem>
        {blogCat &&
          blogCat.map((text, index) => (
            <ListItem
              button
              key={text.id}
              // style={{color: 'black'}}
              onClick={() => {
                getBlog({ ...filter, blog_category: text?.id, page_size: 10 });
                setView(0)
                setTitle(text.title)
              }}
            >
              <ListItemText style={{ color: `${titles === text.title ? "#ee9437" : '#192f69'}` }} primary={text.title} />

            </ListItem>
          ))}
      </List>
    </div>
  );

  return (
    <div>
      <div className={styles.main_blog}>
        <div className={styles.blogs_handle}>
          <div>
            <CssBaseline />
            <AppBar position="fixed" className={classes.appBar}>
              <Toolbar>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  className={classes.menuButton}
                >
                  <MenuIcon />
                </IconButton>
              </Toolbar>
            </AppBar>
            <Hidden smUp implementation="css">
              <Drawer
                container={container}
                variant="temporary"
                anchor={theme.direction === "rtl" ? "right" : "left"}
                open={mobileOpen}
                onClose={handleDrawerToggle}
                classes={{
                  paper: classes.drawerPaper,
                }}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
              >
                {drawer}
              </Drawer>
            </Hidden>
            <Hidden xsDown implementation="css">
              <Drawer
                classes={{
                  paper: classes.drawerPaper,
                }}
                variant="permanent"
                open
              >
                {drawer}
              </Drawer>
            </Hidden>
          </div>
          <div className={styles.contents_main}>
            {view === 0 && (
              <div>
                {loading ? (
                  <div className={styles.blg_container}>
                    {[1, 2, 3, 4, 5, 6].map((v, i) => (
                      <div className={styles.box_blg_skeleton} key={i}>
                        <Skeleton
                          animation="wave"
                          className={styles.blg_image}
                          height={300}
                        />
                        <Skeleton animation="wave" width={100} height={10} />
                        <Skeleton animation="wave" height={10} />
                        <Skeleton animation="wave" height={10} />
                      </div>
                    ))}
                  </div>
                ) : (
                  <div>
                    <div onClick={() => DataSend()} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                      <ArrowBackIcon />
                      <p>Back</p>
                    </div>
                    <div className={styles.blg_container}>
                      {blog &&
                        blog.map((value, indx) => (
                          <div
                            className={styles.box_blg}
                            onClick={() => setSingle(value)}
                          >
                            <div>
                              <img
                                src={value?.cover_thumbnail}
                                alt="demo"
                                className={styles.blg_image}
                              />
                            </div>
                            <div className={styles.bg_category}>
                              {value?.blog_category?.title}
                            </div>
                            <div className={styles.blg_content_main}>
                              {(value?.title) ? <div
                                className={styles.blogContent}
                              >{value?.title}</div> : <div className={styles.blog_Content}
                                dangerouslySetInnerHTML={{ __html: value?.blog_content }}></div>}
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </div>
            )}

            {view === 1 && (
              <div className={styles.displayed}>
                <div onClick={() => {setView(0); history.push(`/blog`)}} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                  <ArrowBackIcon />
                  <p>Back</p>
                </div>
                <div>
                  <img
                    src={single?.cover_thumbnail}
                    alt="demo"
                    className={styles.sel_image}
                  />
                </div>
                <div className={styles.bg_category_inner}>
                  {" "}
                  {single?.blog_category?.title}
                </div>
                <div className={styles.blg_content_main}>
                  <div
                    dangerouslySetInnerHTML={{ __html: single?.blog_content }}
                  ></div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Blog;
