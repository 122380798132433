import React, { useState } from "react";
import styles from "./contact.module.css";
// import { api_call, api_call_token } from "../../Utils/Network";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@material-ui/icons/Close";
// import styles from "./nav.module.css";
import { InputAdornment } from "@mui/material";
import { Button, TextField } from "@material-ui/core";
import { Snackbar, Switch } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import Grow from "@material-ui/core/Grow";
import { api_call } from "../../../Utils/Network";
import { Alert } from "@material-ui/lab";

const styleTwo = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  textAlign: "center",
  // alignItems:"center"
};

function NewContactFrom({setVideoClose}) {
  const [finelOpne, setFinelOpne] = useState(false);
  const [errors, setErrors] = useState({ name: "" });
  const [msg, setMsg] = useState("");
  const [userData, setUserData] = useState({
    contact_person_name: "",
    // c_lname: "",
    c_phone: "",
    c_email: "",
    c_message: "",
    c_school: "",
  });
  var temp = {};

  const validate = () => {
    let num = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    var validEmail =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    let returnValue = true;
    temp.contact_person_name =
      userData.contact_person_name === "" ? "Please enter Your Name" : "";
    temp.c_phone = num.test(userData.c_phone)
      ? ""
      : "Please Enter valid number";
    // temp.c_email = userData.c_email.match(validEmail)
    //   ? ""
    //   : "Please Enter valid email";
    // temp.c_message = userData.c_message === "" ? "Please Enter valid Message" : "";
    temp.c_school = userData.c_school === "" ? "Please Enter School Name" : "";

    setErrors({
      ...temp,
    });
    var found = Object.keys(temp).filter(function (key) {
      return temp[key].includes("Please");
    });
    if (found.length) {
      console.log(temp);
      returnValue = false;
    }
    return returnValue;
  };

  const submitForm = () => {
    if (!validate()) return null;
    var payload = {
      contact_person_name: userData.contact_person_name,
      // last_name: userData.c_lname,
      phone: userData.c_phone,
      email: userData.c_email,
      message: userData.c_message,
      school: userData.c_school,
    };

    console.log(payload, "contactfromdata");

    // if(payload){
    //   setMsg("Thank You! Your submission has been sent.");
    // }
    // setMsg("Thank You! Your submission has been sent.");
    api_call
      .post("contact/general/", payload)
      .then((x) => {
        setMsg("Thank You! Your submission has been sent.");
        setUserData({
          contact_person_name: "",
          c_phone: "",
          c_email: "",
          c_message: "",
          c_school: "",
        });
        document.getElementById("outlined-name1").value = "";
        // document.getElementById("outlined-email1").value = "";
        document.getElementById("outlined-phone1").value = "";
        document.getElementById("outlined-school1").value = "";
        // document.getElementById("outlined-multiline-static1").value = "";
        // setSelectedGrades([]);
        // setFinelOpne(false)
        setTimeout(function() {
          setVideoClose(false);
      }, 3000); 
      })
      .catch((error) => console.log(error));
  };

  const handle = (event) => {
    const { name, value } = event.target;
    setUserData({ ...userData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const viewVideoOpne = (video) => {
    // setSelectedVideo(video);
    setFinelOpne(true);
  };
  // const viewVideoClose = () => setFinelOpne(false);

  return (
    <>
      <div className={styles.borderContainer}>
        <div className={styles.bgacolor}>
          <div className={styles.fromContainer}>
            <h4 className={styles.sectionHeading}>Request Demo</h4>
            <p className={styles.serviceInfopara}>
              Be the first one to get to know about BrainHap
            </p>

            <div className={styles.textfieldContainer}>
              <div className={styles.innerbox}>
                <TextField
                  id="outlined-name1"
                  variant="outlined"
                  label="Enter name"
                  placeholder="Enter name"
                  name="contact_person_name"
                  type="text"
                  className={styles.fullwidth}
                  onChange={handle}
                />
                <p style={{ margin: "0", color: "red" }}>
                  {errors.contact_person_name}
                </p>
              </div>

              <div className={styles.innerbox}>
                <TextField
                  id="outlined-phone1"
                  variant="outlined"
                  label="Mobile Number"
                  // placeholder="Mobile Number"
                  type="text"
                  name="c_phone"
                  className={styles.fullwidth}
                  style={{ textAlign: "left" }}
                  onChange={handle}
                  InputProps={{
                    startAdornment: (
                      // <input type="text" value="+91 |" readOnly style={{border:"none", background:'transparent'}} />
                      <InputAdornment position="start">+91 |</InputAdornment>
                    ),
                  }}
                />
              </div>
              <p style={{ margin: "0", color: "red" }}>{errors.c_phone}</p>
              <div className={styles.innerbox}>
                <TextField
                  id="outlined-school1"
                  variant="outlined"
                  label="Enter school"
                  placeholder="Enter school"
                  type="text"
                  className={styles.fullwidth}
                  name="c_school"
                  onChange={handle}
                  // required
                />
                <p style={{ margin: "0", color: "red" }}>{errors.c_school}</p>
              </div>
            </div>
            <Button
              variant="contained"
              className={styles.fromsubmitbtn}
              onClick={submitForm}
            >
              Submit
            </Button>
          </div>
        </div>
      </div>

      {msg && (
        <Snackbar
          open
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={() => setMsg("")}
          autoHideDuration={3000}
          message={msg}
          TransitionComponent={Grow}
          style={{
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          {/* <CheckIcon className={styles.formsubmited} /> */}
          <Alert className={styles.formsubmited} severity="success">{msg}</Alert>
        </Snackbar>
      )}
    </>
  );
}

export default NewContactFrom;
