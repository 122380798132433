import React, { useContext } from "react";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { Link } from "react-router-dom";

import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { makeStyles } from "@material-ui/core/styles";
import { UserCredsContext } from "../../ContextApi/UserCredsContext/UserCredsContext";
import "./ProfileDrop.css";
import { useHistory } from "react-router-dom";
import { api_call_token } from "../../Utils/Network";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  paper: {
    marginRight: theme.spacing(2),
  },
}));

function ProfileDrop({ datas, ...props }) {
  const { logout } = useContext(UserCredsContext);
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [progress, setProgress] = React.useState([]);
  const [ofldatasAptitude, setOflDatasAptitude] = React.useState({});
  const [ofldatasIntrest, setOflDatasIntrest] = React.useState({});
  const anchorRefdrop = React.useRef(null);
  let history = useHistory();

  const progressTest = () => {
    api_call_token
      .get("test/aptitude_test_question/progress_bar/")
      .then((res) => {
        setProgress(res.data.data);
        // console.log(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  const ViewOflFinalResult = () => {
    api_call_token
      .get(`test/result_view/offline_overall/?test_type=aptitude`)
      .then((res) => {
        setOflDatasAptitude(res.data);
        // console.log(ofldatasAptitude, "oflViewClick");
        // console.log(ofldatasAptitude.status, "oflViewClick");
      })
      .catch((err) => console.log(err));

    api_call_token
      .get(`test/result_view/offline_overall/?test_type=interest`)
      .then((res) => {
        setOflDatasIntrest(res.data);
        // console.log(ofldatasAptitude, "oflViewClick");
        // console.log(ofldatasAptitude.status, "oflViewClick");
      })
      .catch((err) => console.log(err));
  };

  const handelOverallResult = (e) => {
    history.push(`/result_view/overall/${e}`);
    console.log(e, "props e");
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRefdrop.current && anchorRefdrop.current.contains(event.target)) {
      return;
    }
    console.log(event, "event ");
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRefdrop.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  React.useEffect(() => {
    progressTest();
    ViewOflFinalResult();
  }, []);

  // console.log(datas.first_name, "NAME");

  let stud = datas.first_name;

  // let inst = datas.institute.name;
  // console.log(inst, "Valssss");
  return (
    <div className={classes.root}>
      <div>
        <Button
          ref={anchorRefdrop}
          aria-controls={open ? "menu-list-grow" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          className="drop_profile"
        >
          {/* {window.innerWidth < 767
            ? console.log(datas, "datas")
            : datas.first_name || datas.institute.name} */}
          {datas?.first_name || datas.institute?.name}
          <i className="arrow down"></i>
        </Button>
        <Popper
          open={open}
          anchorEl={anchorRefdrop.current}
          role={undefined}
          transition
          disablePortal
          style={{zIndex: '9999'}}
          placement="bottom-end"
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper style={{background: 'linear-gradient(90deg, #3673A9 10%, #002D55 100%)',color:"#ffffff"}}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                  >
                    <Link to="/my-space" style={{ color: "black" }}>
                      <MenuItem className="profileDroplist" style={{color:"#fff"}} onClick={handleClose}>My Sessions</MenuItem>
                    </Link>
                    {/* <Link to="/my-account" style={{ color: "black" }}> */}
                    <Link to="/dashboard" style={{color:"black"}} >
                      {/* <MenuItem onClick={handleClose}>My Profile</MenuItem> */}
                      <MenuItem className="profileDroplist" style={{color:"#fff"}} onClick={handleClose}>My Dashboard</MenuItem>
                    </Link>

                    {/* {progress &&
                      progress.map((values, ind) => (
                        <>
                          {values.percent == 100 ? (
                            <MenuItem
                              style={{ textTransform: "capitalize" }}
                              onClick={() =>
                                handelOverallResult(values.test_type)
                              }
                            >
                              {`${values?.test_type} Download Report`}
                            </MenuItem>
                          ) : null}
                        </>
                      ))} */}
                    {ofldatasAptitude.status && (
                      <MenuItem
                        style={{ textTransform: "capitalize" }}
                        onClick={() => {
                          window.open(ofldatasAptitude.data.pdf);
                        }}
                      >
                        {ofldatasAptitude?.data.test_type
                          ? ofldatasAptitude?.data?.test_type
                          : ""}{" "}
                        Download Report
                      </MenuItem>
                    )}
                    {ofldatasIntrest.status && (
                      <MenuItem
                        style={{ textTransform: "capitalize" }}
                        onClick={() => {
                          window.open(ofldatasIntrest.data.pdf);
                        }}
                      >
                        {ofldatasIntrest?.data.test_type
                          ? ofldatasIntrest?.data?.test_type
                          : ""}{" "}
                        Download Report
                      </MenuItem>
                    )}
                    <MenuItem className="profileDroplist" style={{color:"#fff"}} onClick={logout}>Logout</MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
}

export default ProfileDrop;
