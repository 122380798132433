import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useContext,
} from "react";
import "./Navbar.css";
import { makeStyles } from "@material-ui/core/styles";
import { Link, Redirect } from "react-router-dom";
import Search from "../Search/Search";
import scope from "../../Assets/Nav_Bar/Assets/1x/Asset 22.png";
import contact from "../../Assets/Nav_Bar/Assets/1x/Asset 23.png";
// import profile from "../../Assets/Nav_Bar/Assets/1x/Profile.svg";
import hamburgers from "../../Assets/Nav_Bar/Assets/1x/Asset 1.png";
import { AiOutlineUser } from "react-icons/ai";
import logo from "../../Assets/Nav_Bar/Assets/1x/Asset 4.png";
import Model from "../Model/Model";
import Popovr from "../Popover/Popovr";
import CallIcon from "@material-ui/icons/Call";
import DialogBox from "../DialogBox/DialogBox";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import { UserCredsContext } from "../../ContextApi/UserCredsContext/UserCredsContext";
import Drawer from "../Drawer/Drawer";
import { useHistory } from "react-router-dom";
import footerLogo from "../../Assets/brainhap/footerLogo.png";
import drawerBG from "../../Assets/brainhap/sidebanner.png";
import { SwipeableDrawer } from "@material-ui/core";
import { api_call, api_call_token } from "../../Utils/Network";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@material-ui/icons/Close";
import styles from "./nav.module.css";
import { InputAdornment } from "@mui/material";
import { Button, TextField } from "@material-ui/core";
import { Snackbar, Switch } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import Grow from "@material-ui/core/Grow";
import NewContactFrom from "../Newdesigncomponets/NewContactFrom/NewContactFrom";
import { Alert } from "@material-ui/lab";
import Menu from '@material-ui/core/Menu';
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MenuItem from '@material-ui/core/MenuItem';
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuList from "@material-ui/core/MenuList";

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
}));

const styleTwo = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  textAlign: "center",
  // alignItems:"center"
};

function Navbar({ executeScrolls = () => {} }) {
  const { setDisplayType, setFilter, user, setOpenModel, testDone } =
    useContext(UserCredsContext);
  const history = useHistory();
  const classes = useStyles();
  const [testType, setTestType] = useState(null);
  const myRef = useRef(null);
  // const [openModel, setOpenModel] = useState(false);
  const [isActive, setActive] = useState(false);
  const toggle = () => {
    setActive(!isActive);
  };
  const [colorChange, setColorchange] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [finelOpne, setFinelOpne] = useState(false);
  const [errors, setErrors] = useState({ name: "" });
  const [msg, setMsg] = useState("");
  const [newProduct, setNewProduct] = useState(null)
  const anchorRef = React.useRef(null);
  const [userData, setUserData] = useState({
    contact_person_name: "",
    // c_lname: "",
    c_phone: "",
    c_email: "",
    c_message: "",
    c_school: "",
  });
  var temp = {};

  const validate = () => {
    let num = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    var validEmail =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    let returnValue = true;
    temp.contact_person_name =
      userData.contact_person_name === "" ? "Please enter Your Name" : "";
    temp.c_phone = num.test(userData.c_phone)
      ? ""
      : "Please Enter valid number";
    // temp.c_email = userData.c_email.match(validEmail)
    //   ? ""
    //   : "Please Enter valid email";
    // temp.c_message = userData.c_message === "" ? "Please Enter valid Message" : "";
    temp.c_school = userData.c_school === "" ? "Please Enter School Name" : "";

    setErrors({
      ...temp,
    });
    var found = Object.keys(temp).filter(function (key) {
      return temp[key].includes("Please");
    });
    if (found.length) {
      console.log(temp);
      returnValue = false;
    }
    return returnValue;
  };

  const submitForm = () => {
    if (!validate()) return null;
    var payload = {
      contact_person_name: userData.contact_person_name,
      // last_name: userData.c_lname,
      phone: userData.c_phone,
      email: userData.c_email,
      message: userData.c_message,
      school: userData.c_school,
    };

    console.log(payload, "contactfromdata");

    // if(payload){
    //   setMsg("Thank You! Your submission has been sent.");
    // }
    // setMsg("Thank You! Your submission has been sent.");
    api_call
      .post("contact/general/", payload)
      .then((res) => {
        setFinelOpne(false);
        console.log(res?.data?.data, "bewyuda_dhedgehdbeudygwdjw");
        setMsg("Thank You for submitting your request. Our executive will connect with you within 24 hours");
        setUserData({
          contact_person_name: "",
          c_phone: "",
          c_email: "",
          c_message: "",
          c_school: "",
        });
        document.getElementById("outlined-name").value = "";
        document.getElementById("outlined-email").value = "";
        document.getElementById("outlined-phone").value = "";
        document.getElementById("outlined-school").value = "";
        document.getElementById("outlined-multiline-static").value = "";
        // setSelectedGrades([]);
        
      })
      .catch((error) => console.log(error));
  };

  const executeScroll = () =>
    myRef.current.scrollIntoView({ behavior: "smooth" });
  // const opneContact = () =>{
  //   history.push(`/#requestdemo`)
  // }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const changeNavbarColor = () => {
    if (window.scrollY >= 80) {
      setColorchange(true);
    } else {
      setColorchange(false);
    }
  };

  const redirectExpert = () => {
    if (user.user_type === 1) {
      // history.push('/taketest')
      history.push("/ask-to-counselor");
    } else {
      setDisplayType(0);
      setOpenModel(true);
      setTestType(1);
    }
  };

  const redirects = () => {
    if (user.user_type === 1) {
      // history.push('/taketest')
      history.push("/testseries");
    } else {
      setDisplayType(0);
      setOpenModel(true);
      setTestType(1);
    }
  };
  const handle = (event) => {
    const { name, value } = event.target;
    setUserData({ ...userData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const viewVideoOpne = (video) => {
    // setSelectedVideo(video);
    setFinelOpne(true);
  };
  const viewVideoClose = () => {
    console.log("hitfrom ");  
    setFinelOpne(false)
  };

  const handelnewproduct = (e) =>{
    setNewProduct((prevOpen) => !prevOpen)
  }

  const handleClosenewProduct = (event ,text) => {
    // console.log(text, "handleClosenewProduct");
    // if(text){
    //   history.push(`/${text}`)
    //   setNewProduct(null)
    // } else{
    //   history.push("/")
    //   setNewProduct(null)
    // }
    // setNewProduct(null)
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    console.log(event, "event ");
    setNewProduct(false);
   
  }
  const redirecttoMHealth = () => {

    history.push("/mental-health-testseries");
    // if (user.user_type === 1) {
    //   // history.push('/taketest')
    //   history.push("/mental-health-testseries");
    // } else {
    //   setDisplayType(0);
    //   setOpenModel(true);
    //   setTestType(1);
    // }
  }
  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setNewProduct(false);
    }
  }
  const handleOfferPage = () => {
    history.push('/offers')
  }

  const navElement = () => {
    return (
      <div
        className={`mb_nv_flex flx_width nvb_side_bar newDrawer`}
        style={{ height: "100%" }}
      >
        <div className="flexTaktest">
          {/* onClick={() => redirects()}           */}
          {/* <Link style={{ color: "#032C6B" }} onClick={() => redirects()}>
            <p style={{ fontWeight: "500", fontSize: "1.1rem" }}>
              {testDone ? "View Test" : "Take Test"}
            </p>
          </Link>
          <Link onClick={() => redirectExpert()} style={{ color: "#032C6B" }}>
            <p style={{ fontWeight: "500", fontSize: "1.1rem" }}>
              Book Career Counselling
            </p>
          </Link>
          <Link
            to="/blog"
            style={{ color: "#032C6B" }}
            onClick={() => handleClose()}
          >
            <p style={{ fontWeight: "500", fontSize: "1.1rem" }}>Blog</p>
          </Link> */}

       {/*    <Link style={{ color: "#032C6B" }} className={styles.linkText} onClick={() => redirects()}>
            <p style={{ fontWeight: "500", fontSize: "1.1rem" }}>
              {testDone ? "View Test" : "Take Test"}
            </p>
          </Link>
          {/* <Link onClick={() => redirectExpert()} style={{ color: "#032C6B" }}>
            <p style={{ fontWeight: "500", fontSize: "1.1rem" }}>Product</p>
          </Link> */}
          {/* <Link style={{ color: "#032C6B" }} className={styles.linkText}>
            <p style={{ fontWeight: "500", fontSize: "1.1rem" }}>Product</p>
          </Link> */}
          <Link 
          // onClick={() => redirectExpert()} 
          className={styles.linkText} style={{ color: "#032C6B" }}>
            
            <p ref={anchorRef}  onClick={ handelnewproduct} aria-controls="simple-menu" aria-haspopup="true" style={{ fontWeight: "500", fontSize: "1.1rem" }}>
            Products

            </p>
            <Popper
          open={newProduct}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          style={{zIndex: '9999'}}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper style={{background: 'linear-gradient(90deg, #3673A9 10%, #002D55 100%)', color:"#ffffff"}}>
                <ClickAwayListener onClickAway={handleClosenewProduct}>
                  <MenuList
                    autoFocusItem={newProduct}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                  >
                    <Link to="/alphaguide" style={{ color: "black" }}>
                    <MenuItem className={styles.dropList} onClick={handleClosenewProduct}>Alphaguide</MenuItem>
                    </Link>
                    <Link to="/brain-mapping" style={{ color: "black" }}>
                    <MenuItem className={styles.dropList} onClick={handleClosenewProduct}>Mindgrapher</MenuItem>
                    </Link>
                    <Link to="/neuromentor" style={{ color: "black" }}>
                    <MenuItem className={styles.dropList} onClick={handleClosenewProduct}>Neuromentor</MenuItem>
                    </Link>
                    <Link to="/evoskills" style={{ color: "black" }}>
                    <MenuItem className={styles.dropList} onClick={handleClosenewProduct}>Evoskill</MenuItem>
                    </Link>


                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>

            {/* <Menu
            id="simple-menu"
            anchorEl={newProduct}
            keepMounted
            open={newProduct}
            onClose={() => handleClosenewProduct()}
            >
              <MenuItem onClick={() => handleClosenewProduct("alphaguide")}>Alphaguide</MenuItem>
              <MenuItem onClick={() => handleClosenewProduct()}>Mindgrapher</MenuItem>
              <MenuItem onClick={() => handleClosenewProduct("neuromentor")}>Neuromentor</MenuItem>
              <MenuItem onClick={() => handleClosenewProduct("evoskills")}>Evoskill</MenuItem>
            </Menu>*/}
          </Link> 
          
          <Link
            to="/evoskills"
            style={{ color: "#032C6B" }}
            onClick={() => handleClose()}
            className={styles.linkText}
          >
            <p style={{ fontWeight: "500", fontSize: "1.1rem" }}>
              {/* Stem Accrediation */}
              STEM Courses
            </p>
          </Link>
          <Link
            // to="/mental-health-testseries"
            style={{ color: "#032C6B" }}
            onClick={() => redirecttoMHealth()}
            className={styles.linkText}
          >
            <p style={{ fontWeight: "500", fontSize: "1.1rem" }}>
              {/* Stem Accrediation */}
              Mental Health
            </p>
          </Link>

          <Link onClick={() => redirectExpert()} className={styles.linkText} style={{ color: "#032C6B" }}>
            <p style={{ fontWeight: "500", fontSize: "1.1rem" }}>
               Counselling
            </p>
          </Link>
          <Link
            to="/blog"
            style={{ color: "#032C6B" }}
            onClick={() => handleClose()}
            className={styles.linkText}
          >
            <p style={{ fontWeight: "500", fontSize: "1.1rem" }}>Blog</p>
          </Link>
          <div>
            {/* {window.innerWidth > 992 ? (
            <></>
          ) : (
            <div>
              <Link
                to="/explore"
                style={{ color: "#032C6B" }}
                onClick={() => handleClose()}
              >
                <p style={{ fontWeight: "500", fontSize: "1.1rem" }}>Explore</p>
              </Link>
            </div>
          )} */}
            <div>
              {/* <Link to="#requestdemo"> */}
              <button
                // className="btn_nv_tech btn_ng_gn"
                className={`${styles.linkText} btn_nv_tech btn_ng_gn`}
                onClick={() => {
                  viewVideoOpne();
                  executeScrolls();
                }}
              >
                {" "}
                Request demo{" "}
              </button>
              {/* </Link> */}
            </div>
          </div>
          {/* <div>
          <Link
            to="/blog"
            style={{ color: "#032C6B" }}
            onClick={() => handleClose()}
          >
            <p style={{ fontWeight: "500" }}>Login  </p>
          </Link>
        </div> */}
        </div>
        {/* <div>
          <button className="btn_nv_tech btn_ng_gn">
            <a
              href="tel:9653257335"
              style={{
                display: "flex",
                color: "white",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CallIcon style={{ marginRight: "10px" }} /> 9653257335
            </a>
          </button>
        </div> */}
      </div>
    );
  };

  // document.addEventListener("click", setActive(false))

  // let menuRef = useRef();

  // useEffect(() => {
  //   let handler = (event) => {
  //     if (!menuRef.current.contains(event.target)) {
  //       setActive(false);
  //     }
  //   };
  //   document.addEventListener("mousedown", handler);

  //   return () => {
  //     document.removeEventListener("mousedown", handler);
  //   };
  // });
  window.addEventListener("scroll", changeNavbarColor);

  return (
    <>
      <div className="topnumber-section">
        <marquee style={{cursor:"pointer"}} onClick={handleOfferPage}>
          {/* <a
            href="tel:9653257335"
            className="changelinksforancor"
            style={{
              display: "flex",
              // color: "white",
              // justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CallIcon style={{ marginRight: "10px" }} /> 9653257335 call to book
            a counselling session now!
          </a> */}
          Special Offer: Assessment Test + Counselling only ₹99/-  Book Now
        </marquee>
      </div>
      <nav className={colorChange ? "navbar colorChange " : "navbar "}>
        <div className="nav_base">
          <div className="main_nav">
            <div className="hm-bg">
              <div className="hamburger">
                <img
                  src={hamburgers}
                  alt="hamburgers"
                  className="hamb"
                  onClick={handleClick}
                ></img>
                <SwipeableDrawer
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  {window.innerWidth < 992 ? navElement() : <></>}
                </SwipeableDrawer>
              </div>
              <div className="nav-logo">
                <a href="#">
                  <img src={footerLogo} alt="logo" className="logo"></img>
                </a>
              </div>
            </div>
          </div>

          <div className="mb_nv_flex rev_flx flx_width">
            {window.innerWidth > 992 ? navElement() : <></>}

            <div className="mobile_login main_nav">
              <Drawer />
            </div>
            <div>
              <div className="login">
                <DialogBox />
              </div>
            </div>
          </div>
        </div>
      </nav>
      {/* {openModel && <Model closeModel={setOpenModel} />} */}
      <Modal
        open={finelOpne}
        onClose={viewVideoClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleTwo} className={styles.boxForMobile}>
          <div
            className={styles.closebtn}
            style={{ textAlign: "right", cursor: "pointer" }}
          >
            <CloseIcon onClick={viewVideoClose} />
          </div>

          <NewContactFrom setVideoClose={viewVideoClose}/>
        </Box>
      </Modal>

      {msg && (
        <Snackbar
          open
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={() => setMsg("")}
          autoHideDuration={3000}
          message={msg}
          TransitionComponent={Grow}
          style={{
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          {/* <CheckIcon className={styles.formsubmited} /> */}
          <Alert className={styles.formsubmited} severity="success">{msg}</Alert>
        </Snackbar>
      )}
    </>
  );
}

export default Navbar;
