import React, { useContext, useEffect, useState } from "react";
import styles from "./reports.module.css";
import { colors } from "@material-ui/core";
import downloade from "../../Assets/downloadeReport.png";
import ParametersBox from "../../Components/MyDashboardComponent/ParametersBox/ParametersBox";
import up from "../../Assets/thumbsUp.png";
import down from "../../Assets/thumbsdown.png";
import { api_call_token, api_token } from "../../Utils/Network";
import { useHistory } from "react-router-dom";
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import TestPricing from "../../Components/TestPricing/TestPricing";
import TestPricingHindi from "../../Components/TestPricing/TestPricingHindi";
import { UserCredsContext } from "../../ContextApi/UserCredsContext/UserCredsContext";

function formatDate(date) {
  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "long" });
  const year = date.getFullYear();

  // Determine the appropriate suffix for the day
  let suffix = "th";
  if (day === 1 || day === 21 || day === 31) suffix = "st";
  else if (day === 2 || day === 22) suffix = "nd";
  else if (day === 3 || day === 23) suffix = "rd";

  return `${day}${suffix} ${month} ${year}`;
}

function MyReortsPage() {
  const [allReport, setAllReport] = useState([]);
  const [dreamCareer, setDreamCareer] = useState([]);
  const [pricecheck, setPriceCheck] = useState();
  const [pricemodel, setPriceModel] = useState(false);
  const { user, setUser, boardList, gradeList, countrylist, statelist } =
    useContext(UserCredsContext);
  const history = useHistory();
  // const []

  useEffect(() => {
    getReportApi();
    getDreamCareer();
    getAptitudeForPay();
  }, []);
  const getAptitudeForPay = () => {
    api_call_token
      .get(`/counseling/purchase/test/payment_status/`)
      .then((res) => {
        console.log(res.data.data, "res");
        let price = res.data.data;
        setPriceCheck(price);
      })
      .catch((err) => console.log(err));
  };

  const getReportApi = () => {
    api_call_token
      .get(`/test/user_reports/get_all_reports`)
      .then((res) => {
        setAllReport(res?.data?.data);
        console.log(res?.data?.data, "987yhjjvghjjhvhj");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getDreamCareer = () => {
    api_call_token
      .get(`/test/user_reports/counselling_report`)
      .then((res) => {
        setDreamCareer(res?.data?.data);
        console.log(res?.data?.data, "987yhjjvghjjhvhj");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleopnePdf = (file) => {
    console.log(file);

    const link = document.createElement("a");
    link.href = file;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const redirecttest = () => {
    history.push("/testseries");
  };
  const redirectExpert = () => {

      // history.push('/taketest')
      history.push("/ask-to-counselor");
    
  };

  const handelPriceboxopne = () => {
    setPriceModel(true);
    // console.log("232322",pricemodel);
  };
  const handelPriceClose = () => {
    setPriceModel(false);
  };
  return (
    <div className={styles.MainReportContainer}>
      <div className={styles.buttoncontainer}>
        <Link
          className={styles.freeTest}
          to={{
            pathname: `get-all-result/all/aptitude/1`,
            state: {
              test: "sample",
            },
          }}
          // style={{
          //   fontFamily: "Segoe UI",
          //   fontStyle: "normal",
          //   fontWeight: "400",
          //   fontSize: "16px",
          //   lineHeight: "21px",
          //   textTransform: "capitalize",
          //   color: "#000",
          //   backgroundColor: "#95a78a",
          //   borderRadius: "5px",
          //   padding: "5px 32px",
          //   boxShadow: "0.7px 0.7px 1.5px 0 #767676",
          // }}
        >
          {"Free Test"}
        </Link>
        {pricecheck?.standard?.status ? (
          <button className={styles.allTest} onClick={redirecttest}>
            Paid Test
          </button>
        ) : (
          <button className={styles.allTest} onClick={handelPriceboxopne}>
            Buy Packages
          </button>
        )}
      </div>
      {/* {pricecheck?.standard?.status && ( */}
      <div className={styles.reportListingContainer}>
        {allReport?.alphaguide?.length > 0 && (
          <div
            className={styles.boxContainer}
            style={{
              background: "linear-gradient(180deg, #D4FFF2 0%, #ACD6D6 100%)",
            }}
          >
            <h2 style={{ color: "#16838C" }} className={styles.boxHeading}>
              Alpha Guide
              <span
                className={styles.hedingUnderline}
                style={{ background: "#16838C" }}
              ></span>
            </h2>
            <div className={styles.overflowContainer}>
              {allReport?.alphaguide?.map((p, i) => {
                return (
                  <div
                    style={{ border: `1px solid #16838C` }}
                    className={styles.innerBoxContainer}
                  >
                    <div
                      style={{ color: "#16838C" }}
                      className={styles.boxleftContiner}
                    >
                      <h3 className={styles.textTitle}>
                        {p?.test_type ? p?.test_type : "Take Test"}
                      </h3>
                      <p className={styles.textDate}>
                        {p?.attempt_date
                          ? `Test taken on ${formatDate(
                              new Date(p?.attempt_date)
                            )}`
                          : ""}
                      </p>
                    </div>
                    {p?.file ? (
                      <div
                        className={styles.boxrightContainer}
                        style={{ color: "#0A3E42" }}
                        onClick={() => handleopnePdf(p?.file)}
                      >
                        <img src={downloade} /> Download Report
                      </div>
                    ) : (
                      <div
                        className={styles.boxrightContainer}
                        style={{ color: "#0A3E42" }}
                        onClick={redirecttest}
                      >
                        {/* <img src={downloade} /> */}
                        Take Test
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        )}
        <div
          className={styles.boxContainer}
          style={{
            background:
              "linear-gradient(180deg, rgba(250, 242, 242, 0.8) 0%, rgba(243, 225, 225, 0.8) 100%)",
          }}
        >
          <h2 style={{ color: "#B46E6E" }} className={styles.boxHeading}>
            Counselling
            <span
              className={styles.hedingUnderline}
              style={{ background: "#B46E6E" }}
            ></span>
          </h2>
          <div className={styles.overflowContainer}>
            {allReport?.counselling?.map((p, i) => {
              return (
                <div
                  style={{ border: `1px solid #B46E6E` }}
                  className={styles.innerBoxContainer}
                >
                  <div
                    style={{ color: "#B46E6E" }}
                    className={styles.boxleftContiner}
                  >
                    <h3 className={styles.textTitle}>Session {i + 1}</h3>
                    <p className={styles.textDate}>
                      {p?.created
                        ? `Session done on ${formatDate(new Date(p?.created))}`
                        : ""}
                    </p>
                  </div>
                  {p?.file ? (
                    <div
                      className={styles.boxrightContainer}
                      style={{ color: "#763D3D" }}
                      onClick={() => handleopnePdf(p?.file)}
                    >
                      <img src={downloade} /> Download Report
                    </div>
                  ) : (
                    <div
                      className={styles.boxrightContainer}
                      style={{ color: "#763D3D" }}
                      onClick={redirectExpert}
                    >
                       Book Counselling
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
        <div
          className={styles.boxContainer}
          style={{
            background: "linear-gradient(180deg, #E8ECF1 0%, #B5CFD8 100%)",
          }}
        >
          <h2 style={{ color: "#6286AB" }} className={styles.boxHeading}>
            MindGrapher
            <span
              className={styles.hedingUnderline}
              style={{ background: "#6286AB" }}
            ></span>
          </h2>
          <div className={styles.overflowContainer}>
            {allReport?.mindgrapher?.map((p, i) => {
              return (
                <div
                  style={{ border: `1px solid #6286AB` }}
                  className={styles.innerBoxContainer}
                >
                  <div
                    style={{ color: "#6286AB" }}
                    className={styles.boxleftContiner}
                  >
                    <h3 className={styles.textTitle}>Session {i + 1}</h3>
                    <p className={styles.textDate}>
                      {p?.modified
                        ? `Session done on ${formatDate(new Date(p?.modified))}`
                        : ""}
                    </p>
                  </div>
                  {
                    p?.report_pdf ?  <div
                    className={styles.boxrightContainer}
                    style={{ color: "#2C3F53" }}
                    onClick={() => handleopnePdf(p?.report_pdf)}
                  >
                    <img src={downloade} /> Download Report
                  </div>: <div
                    className={styles.boxrightContainer}
                    style={{ color: "#2C3F53" }}
                    onClick={() => alert(`Book Your MindGrapher Session`)}
                  >
                    Download Report
                  </div>
                  }
                  
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {/* // )} */}

      {dreamCareer?.dream_career && (
        <div className={styles.DreamCareerContainer}>
          <div className={styles.dreamTextContainer}>
            <h1>{dreamCareer?.dream_career}</h1>
            <h4>DREAM CAREER</h4>
          </div>
        </div>
      )}
      {dreamCareer?.suggested_career?.length > 0 && (
        <div className={styles.careersOptions}>
          <h3 className={styles.containerHeading}>Suggested Career Options</h3>
          <div className={styles.careersListing}>
            {dreamCareer?.suggested_career?.map((v, i) => {
              return <h5 className={styles.careersOption}>{v}</h5>;
            })}
          </div>
        </div>
      )}

      {dreamCareer?.area_of_strength_aptitude?.length > 0 ||
        (dreamCareer?.area_of_improvement_aptitude?.length > 0 && (
          <div className={styles.aptitudeContainer}>
            <h3 className={styles.containerHeading}>Based on Aptitude Test</h3>
            <div className={styles.testInnerContainer}>
              <ParametersBox
                text={"Area Of Strength"}
                list={dreamCareer?.area_of_strength_aptitude}
                img={up}
                background={"linear-gradient(360deg, #5ED56A 0%, #0B7754 100%)"}
              />

              <ParametersBox
                text={"Areas for improvement"}
                list={dreamCareer?.area_of_improvement_aptitude}
                img={down}
                background={"linear-gradient(180deg, #FF6020 0%, #EC914D 100%)"}
              />
            </div>
          </div>
        ))}
      {(dreamCareer?.very_interested_in_interest?.length > 0 ||
        dreamCareer?.less_interested_in_interest?.length > 0) && (
        <div className={styles.aptitudeContainer}>
          <h3 className={styles.containerHeading}>Based on Interest Test</h3>
          <div className={styles.testInnerContainer}>
            <ParametersBox
              text={"Interested In"}
              list={dreamCareer?.very_interested_in_interest}
              img={up}
              background={"linear-gradient(360deg, #57C3FF 0%, #514EFF 100%)"}
            />

            <ParametersBox
              text={"Less Interested In"}
              list={dreamCareer?.less_interested_in_interest}
              img={down}
              background={"linear-gradient(0deg, #FF6969 0%, #B30000 100%)"}
            />
          </div>
        </div>
      )}

      {user?.profile?.lang_medium === 1 ? (
        <TestPricing
          isOpen={pricemodel}
          isClose={handelPriceClose}
          setPriceModel={setPriceModel}
          pricecheck={pricecheck}
        />
      ) : user?.profile?.lang_medium === 2 ? (
        <TestPricingHindi
          isOpen={pricemodel}
          isClose={handelPriceClose}
          setPriceModel={setPriceModel}
          pricecheck={pricecheck}
        />
      ) : (
        ""
      )}
    </div>
  );
}

export default MyReortsPage;

const parameters = [
  "Technological",
  "Social",
  "Physical",
  "linguistic",
  "Organizational",
  "investigative",
  "Exploratory",
];

const overallprogerss = [
  {
    title: "Alpha Guide",
    tests: [
      {
        title: "Aptitude Test",
        date: "4th jaunuary 2024",
      },
      {
        title: "Interest Test",
        date: "4th jaunuary 2024",
      },
    ],
    color: "#16838C",
    background: "linear-gradient(180deg, #D4FFF2 0%, #ACD6D6 100%)",
  },
  {
    title: " Counselling",
    tests: [
      {
        title: "Session",
        date: "4th jaunuary 2024",
      },
      {
        title: "Session",
        date: "4th jaunuary 2024",
      },
      {
        title: "Session",
        date: "4th jaunuary 2024",
      },
    ],
    color: "#B46E6E",
    background:
      "linear-gradient(180deg, rgba(250, 242, 242, 0.8) 0%, rgba(243, 225, 225, 0.8) 100%)",
  },
  {
    title: "Mind Grapher",
    tests: [
      {
        title: "Session",
        date: "4th jaunuary 2024",
      },
      {
        title: "Session",
        date: "4th jaunuary 2024",
      },
    ],
    color: "#6286AB",
    background: "linear-gradient(180deg, #E8ECF1 0%, #B5CFD8 100%)",
  },
];

const careers = {
  dreamCareer: "ASTRONAUT",
  otherOption: [
    "Dancer",
    "Artist",
    "Teacher",
    "Web Designer",
    "Cricketer",
    "Automotive Engineer",
    "Computer system Analyst",
    "Track And Field Trainer",
    "Video Game Developer",
  ],
};
