import React, { useState } from "react";
import styles from "./from.module.css";
import cf1 from "../../../Assets/NewHomepageImg/cf-1.png";
import cf2 from "../../../Assets/NewHomepageImg/cf-2.png";
import workIcon3 from "../../../Assets/NewHomepageImg/workicon-3.png";
import girl_Illustrator from "../../../Assets/brainhap/girl_Illustrator.svg";
import { Button, TextField } from "@material-ui/core";
import { InputAdornment } from "@mui/material";
import { api_call } from "../../../Utils/Network";
import { Snackbar, Switch } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import Grow from "@material-ui/core/Grow";
import { Alert } from "@material-ui/lab";

function RequestDemoForm( ref) {
  const [msg, setMsg] = useState("");
  const [errors, setErrors] = useState({ name: "" });
  const [userData, setUserData] = useState({
    contact_person_name: "",
    // c_lname: "",
    c_phone: "",
    c_email: "",
    c_message: "",
    c_school: "",
  });
  var temp = {};

  const validate = () => {
    let num = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    var validEmail =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    let returnValue = true;
    temp.contact_person_name =
      userData.contact_person_name === "" ? "Please enter Your Name" : "";
    temp.c_phone = num.test(userData.c_phone)
      ? ""
      : "Please Enter valid number";
    // temp.c_email = userData.c_email.match(validEmail)
    //   ? ""
    //   : "Please Enter valid email";
    // temp.c_message = userData.c_message === "" ? "Please Enter valid Message" : "";
    temp.c_school = userData.c_school === "" ? "Please Enter School Name" : "";

    setErrors({
      ...temp,
    });
    var found = Object.keys(temp).filter(function (key) {
      return temp[key].includes("Please");
    });
    if (found.length) {
      console.log(temp);
      returnValue = false;
    }
    return returnValue;
  };

  const submitForm = () => {
    if (!validate()) return null;
    var payload = {
      contact_person_name: userData.contact_person_name,
      // last_name: userData.c_lname,
      phone: userData.c_phone,
      email: userData.c_email,
      message: userData.c_message,
      school: userData.c_school,
    };

    // console.log(payload, "contactfromdata");

    // if(payload){
    //   setMsg("Thank You! Your submission has been sent.");
    // }
    // setMsg("Thank You! Your submission has been sent.");
    api_call
      .post("contact/general/", payload)
      .then((x) => {
        setMsg("Thank You for submitting your request. Our executive will connect with you within 24 hours");
        setUserData({
          contact_person_name: "",
          c_phone: "",
          c_email: "",
          c_message: "",
          c_school: "",
        });
        document.getElementById("outlined-name").value = "";
        // document.getElementById("outlined-email").value = "";
        document.getElementById("outlined-phone").value = "";
        document.getElementById("outlined-school").value = "";
        document.getElementById("outlined-multiline-static").value = "";
        // setSelectedGrades([]);
      })
      .catch((error) => console.log(error));
  };


  const handle = (event) => {
    const { name, value } = event.target;
    setUserData({ ...userData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };
  return (
    <>
      <section
        className={styles.contactformSection}
        id="requestdemo"
        ref={ref?.ref}
      >
        <div className={`${styles.innercontactContainer}`}>
          <div className={styles.borderContainer}>
            <div className={styles.bgacolor}>
              <div className={styles.fromContainer}>
                <h4 className={styles.sectionHeading}>Request Demo</h4>
                <p className={styles.serviceInfopara}>
                  Be the first one to get to know about BrainHap
                </p>

                <div className={styles.textfieldContainer}>
                  <div className={styles.innerbox}>
                    <TextField
                      id="outlined-name"
                      variant="outlined"
                      label="Enter name"
                      placeholder="Enter name"
                      name="contact_person_name"
                      type="text"
                      className={styles.fullwidth}
                      onChange={handle}
                      // required
                    />
                    <p style={{ margin: "0", color: "red" }}>
                      {errors.contact_person_name}
                    </p>
                  </div>

                  <div className={styles.innerbox}>
                    <TextField
                      id="outlined-phone"
                      variant="outlined"
                      label="Mobile Number"
                      // placeholder="Mobile Number"
                      type="text"
                      name="c_phone"
                      className={styles.fullwidth}
                      style={{ textAlign: "left" }}
                      onChange={handle}
                      // maxRows="10"
                      InputProps={{
                        startAdornment: (
                          // <input type="text" value="+91 |" readOnly style={{border:"none", background:'transparent'}} />
                          <InputAdornment position="start">
                            +91 |
                          </InputAdornment>
                        ),
                      }}
                      // required
                    />
                  </div>
                  <p style={{ margin: "0", color: "red" }}>{errors.c_phone}</p>
                  <div className={styles.innerbox}>
                    <TextField
                      id="outlined-school"
                      variant="outlined"
                      label="Enter school"
                      placeholder="Enter school"
                      type="text"
                      className={styles.fullwidth}
                      name="c_school"
                      onChange={handle}
                      // required
                    />
                    <p style={{ margin: "0", color: "red" }}>
                      {errors.c_school}
                    </p>
                  </div>
                  <div className={styles.innerbox}></div>
                  <p style={{ margin: "0", color: "red" }}>
                    {errors.c_message}
                  </p>
                </div>
                <Button
                  variant="contained"
                  className={styles.fromsubmitbtn}
                  onClick={submitForm}
                >
                  Submit
                </Button>
              </div>
            </div>
          </div>
          <div className={`${styles.contactformImgContainer}`}>
            <img src={girl_Illustrator} />
          </div>
        </div>
        <div className={`${styles.postions} ${styles.cfone}`}>
          <img src={cf1} />
        </div>
        <div className={`${styles.postions} ${styles.cftwo}`}>
          <img src={cf2} />
        </div>
        <div className={`${styles.postions} ${styles.cfthree}`}>
          <img src={workIcon3} />
        </div>
      </section>
      {msg && (
        <Snackbar
          open
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={() => setMsg("")}
          autoHideDuration={3000}
          message={msg}
          TransitionComponent={Grow}
          style={{
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          {/* <CheckIcon className={styles.formsubmited} /> */}
          <Alert className={styles.formsubmited} severity="success">{msg}</Alert>
        </Snackbar>
      )}
    </>
  );
}

export default RequestDemoForm;
