import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./index.module.css";
import logo from "../../Assets/freetrailpage/headerlogo.png";
import { Button } from "@material-ui/core";
import BenefitCard from "../../Components/LandingPageComponent/BenefitCard/BenefitCard";
import HowdoesCard from "../../Components/LandingPageComponent/HowdoesCard/HowdoesCard";
import whatyouget from "../../Assets/freetrailpage/whatyouwiilimg.png";
import RegisterNowCard from "../../Components/LandingPageComponent/RegisterNowCard/RegisterNowCard";
import StudentReviewcard from "../../Components/LandingPageComponent/StudentReviewCard/StudentReviewcard";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import arrow from "../../Assets/freetrailpage/arrow.png";
import ImageAnimationSlider from "../../Components/LandingPageComponent/ImageAnimation/ImageAnimationSlider";
import Modal from "@material-ui/core/Modal";
import NewLoginModal from "../../Components/LandingPageComponent/NewLoginModal/NewLoginModal";
import { api_call_token } from "../../Utils/Network";
import { UserCredsContext } from "../../ContextApi/UserCredsContext/UserCredsContext";
import { useHistory } from "react-router-dom";
import lcarro from "../../Assets/Logo/Artboard 1 copy 3.png";
import bg1 from "../../Assets/freetrailpage/bg1.png"
import bg2 from "../../Assets/freetrailpage/bg2.png"
import bg3 from "../../Assets/freetrailpage/bg3.png"
import bg4 from "../../Assets/freetrailpage/bg4.png"

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    console.log(script, "script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    // script.onload = display.Razorpay;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

function LandingPage() {
  const [modalOpen, setModalOpen] = useState(false);
  const [pricecheck, setPriceCheck] = useState();
  const [userObject, setUserObject] = useState({});
  const [price, setPrice] = useState({});
  const [loading, setLoading] = useState(true);
  const [loadings, setLoadings] = useState(true);
  const history = useHistory();
  const {
    updateLoginData,
    loginData,
    user,
    setUser,
    setToken,
    userType,
    token,
  } = useContext(UserCredsContext);
  const myRef = useRef(null);
  const executeScroll = () =>
    myRef.current.scrollIntoView({ behavior: "smooth" });

  console.log(token, "getAptitudeForPay");
  const getAptitudeForPay = (newUserd) => {
    debugger;
    console.log(newUserd, "displayAdvRazorPay");
    api_call_token
      .get(`/counseling/purchase/test/payment_status/`)
      .then((res) => {
        console.log(res.data.data, "res");
        setUserObject(newUserd);
        let price = res?.data?.data;
        // setPriceCheck(price);

        handleAdvanceBooking(price?.advance?.price, newUserd);
      })
      .catch((err) => console.log(err));
  };

  // useEffect(()=>{
  //   if(token?.access !== ""){

  //     getAptitudeForPay()
  //   }
  // },[token])

  // /////// rozorpay modal code

  const handleAdvanceBooking = (data, newUserd) => {
    // debugger;
    setLoading(true);
    // console.log(data, "solts");

    // if (data == null || undefined) {
    //   console.log(data, "price");
    // } else {
    let price = data * 100;
    // setPriceModel(false)

    api_call_token
      .post(`counseling/purchase/test/initiate_book_slot_and_test/`, {
        amount: price,
      })
      .then((res) => {
        console.log(res.data, "solts result");
        const payData = res?.data?.data;

        displayAdvRazorPay(payData, newUserd);
      })
      .catch((err) => console.log(err));
    // }
    setLoading(false);
  };

  async function displayAdvRazorPay(dataId, newUserd) {
    setLoading(true);
    // console.log(dataId, slotData, "data id passed solts");
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    if (!res) {
      alert("razorpay sdk failed to load. Are you online?");
      return;
    }
    console.log(userObject, "newUseenkjdh87");
    let current_url = window.location.href;
    if (current_url.includes("localhost") || current_url.includes("staging")) {
      // var razorpay_key = "rzp_test_TW6h2zNAZqVdu1";
      var razorpay_key = "rzp_live_lDScQvs1epmNca";
    } else {
      var razorpay_key = "rzp_live_lDScQvs1epmNca";
    }
    var courseprice = dataId.amount;
    const options = {
      key: razorpay_key,
      amount: courseprice,
      currency: "INR",
      name: "Brainhap",
      description: "Course payment",
      image: lcarro,
      order_id: `${dataId.order_id}`,
      handler: function (response) {
        console.log(response.razorpay_payment_id, "hello");
        console.log(response.razorpay_order_id, "hello1");
        console.log(response.razorpay_signature, "hello2");
        // setPaymentId(response.razorpay_payment_id);
        // var intValue = parseInt(courseId);
        // console.log(slotData, "getpurchase body");
        getAdvpurchase(response, dataId);
      },
      prefill: {
        name: `${newUserd?.first_name}`,
        email: `${newUserd?.email}`,
        contact: `+91 ${newUserd?.phone}`,
      },
    };
    var paymentObject = new window.Razorpay(options);
    paymentObject.open();
    setLoadings(false);
  }

  const getAdvpurchase = (rpid, ordId, slotData) => {
    setLoading(true);
    // console.log(slotData, "getpurchase body");
    console.log(rpid, ordId, "rpid, ordId, slotData");
    var data = {
      transaction_id: rpid?.razorpay_payment_id,
      order_id: ordId?.id,
      amount: price?.advance?.price,
      // razorpay_signature: rpid?.razorpay_signature,
    };
    console.log(data, "value to pass in purchase");
    api_call_token
      .post(`counseling/purchase/test/order_counseling_with_test/`, data)
      .then((response) => {
        console.log("The response for purchase course is ", response.data.data);
        // alert("Successfully Slot is Booked");
        if (
          response.status == 200 ||
          response.status == 201 ||
          response.status == 204
        ) {
          // setOpen(true);
          // ViewAllResult()
          // setOpen(false);
          // setPriceModel(false)
          alert("Payment Successfull");
          history.push("/testseries");
        }
        // setSuccess(true);
      });
    // console.log(rpid, "rpid");
  };

  const handalmodalopne = () => {
    setModalOpen(true);
  };

  const handlemodalClose = () => {
    setModalOpen(false);
  };
  return (
    <div className={styles.mainContainer}>
      <div className={styles.firstContainer}>
        <div className={styles.logocontainer}>
          <img src={logo} alt="logo" />
        </div>

        <h1 className={styles.pageHeading}>
          {" "}
          Check Your Talent With BrainHap’s BrainMapping Test
        </h1>
        <div className={styles.descriptionbox}>
          <p className={styles.mainPara}>
            Is your child ready to excel in their chosen career? BrainHap,
            <strong> in association with IIT Bombay </strong>, offers an
            innovative BrainMapping Test designed to reveal your child's unique
            talents and strengths. Our state-of-the-art, Neuroscience-based
            approach provides comprehensive analysis that empowers students from{" "}
            <strong> Class 6 to 12 </strong> to make informed decisions about
            their future.
          </p>
          <Button className={styles.registerNow} onClick={handalmodalopne} >
            Register Now
          </Button>
        </div>
        <div className={styles.Bg1}>
          <img src={bg1} />
        </div>
        <div className={styles.Bg2}>
          <img src={bg2} />
        </div>
        <div className={styles.Bg3}>
          <img src={bg3} />
        </div>
        <div className={styles.Bg4}>
          <img src={bg4} />
        </div>
      </div>

      {/* imag animation  */}
      <div className={styles.imgcontainerSliders}>
        <ImageAnimationSlider />
      </div>

      {/* How Can Your Child Benefit from BrainHap? */}
      <div className={styles.secondContainer}>
        <h2 className={styles.sectionHeading}>
          How Can Your Child Benefit from BrainHap?
        </h2>
        <div className={styles.textLine}>
          <h3>
            {" "}
            Struggling to pick the right stream—Science, Arts, or Commerce?
          </h3>
        </div>
        <div className={styles.firstLine}></div>
        <p className={styles.chooseWrite}>
          Choosing the right academic stream can be a tough decision for both
          students and parents. With BrainHap's advanced BrainMapping
          technology, this decision becomes clear and straightforward
        </p>
        <div className={styles.CardListing}>
          <BenefitCard />
        </div>
      </div>

      {/* How does it work */}

      <div className={styles.hdiwSection}>
        <h2 className={styles.sectionHeading}>How Does It Work?</h2>
        <div className={styles.headingLine}></div>

        <HowdoesCard />
      </div>

      {/* What if we told you */}

      <div className={styles.toldyou}>
        <h2 className={styles.toldyouHeading}>What If We Told You</h2>
        <p className={styles.toldyoupara}>
          You can achieve success and growth in your chosen field by
          understanding how your brain works in different subjects. By
          identifying your strengths and weaknesses in various cognitive areas,
          you can set the stage for a successful future.
        </p>
        <h5 className={styles.bottomtext}> YES, It Is Possible</h5>
      </div>

      {/* you get */}
      <div className={styles.youwillget}>
        <h3 className={styles.youwillgetheding}>
          Here’s what you will get with BrainHap
        </h3>
        <div className={styles.whatyougetContainer}>
          <img src={whatyouget} />
        </div>
      </div>

      {/* Register Today */}

      <div className={styles.registerBonus}>
        <h4 className={styles.registerHeading}>
          Register Today to unlock <br /> Bonuses Worth <span> ₹45,000</span>
        </h4>

        <RegisterNowCard />
      </div>

      {/* student Review */}
      <div className={styles.studentReviewcontainer} >
        <h2 className={styles.sectionHeading}>Hear From Our Students</h2>
        <div className={styles.headingLine}></div>
        <StudentReviewcard />
      </div>

      {/* future today */}

      <div className={styles.futureTodayContainer} ref={myRef} onClick={handalmodalopne}>
        <div className={styles.innerFutureCard}>
          <h4 className={styles.topText}>
            <img src={arrow} /> Take A step To Secure Your Future Today
          </h4>

          <div className={styles.futureBtn}>
            <span> ₹2,500/-</span> ₹99
          </div>
          <p className={styles.youtext}>[ Offer Valid Only For Today ]</p>
        </div>
      </div>
      {/* <Modal
  open={modalOpen}
  onClose={handlemodalClose}
  // aria-labelledby="simple-modal-title"
  // aria-describedby="simple-modal-description"
>
  <div>hiii</div>
</Modal> */}

      <NewLoginModal
        open={modalOpen}
        onClose={handlemodalClose}
        getAptitudeForPay={getAptitudeForPay}
      />

      <div className={styles.stikybtn}>
          <div className={styles.stickytest}>
          ₹99 only 
          Ends Today

          </div>
          <div >
            <Button className={styles.stickyInnerBtn} onClick={handalmodalopne}>
                Register Now
            </Button>
          </div>
      </div>
    </div>
  );
}

export default LandingPage;
