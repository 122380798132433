import React from "react";
import styles from "./index.module.css";
import img1 from "../../../Assets/freetrailpage/Counsellor.png";
import img2 from "../../../Assets/freetrailpage/Insights.png";
import img3 from "../../../Assets/freetrailpage/Techniques.png";
import img4 from "../../../Assets/freetrailpage/Solutions.png";

function RegisterNowCard() {
  return (
    <>
      <div className={styles.cardListing}>
        {carddata?.map((v, i) => {
          return (
            <div className={styles.cardContainer} key={v?.id}>
              <div className={styles.imgContainer}>
                <img src={v?.img} />
              </div>

              <h5 className={styles.title}>{v?.title}</h5>
              <p className={styles.para}>{v?.para}</p>
              <span className={styles.cardnum}>Bonus {v?.id }</span>
            </div>
          );
        })}
      </div>
    </>
  );
}

export default RegisterNowCard;

const carddata = [
  {
    id: 1,
    img: img1,
    title: "Lifetime Access to BrainHap Guidance Counsellor",
    para: "Get ongoing support to ensure you stay on track",
  },
  {
    id: 2,
    img: img2,
    title: "Scientific-Evidence Based Proven Data Insights",
    para: "Receive a list of best-fit career options that match your profile.",
  },
  {
    id: 3,
    img: img3,
    title: "10+ Neuroscientific Techniques",
    para: "Access techniques to improve targeted areas that need more attention.",
  },
  {
    id: 4,
    img: img4,
    title: "Mental Health Stress Solutions",
    para: "Address academic and non-academic stress with effective strategies.",
  },
];
