import React, { useContext, useEffect, useState } from "react";
import styles from "./index.module.css";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { login_api } from "../../../Utils/Network";
import { UserCredsContext } from "../../../ContextApi/UserCredsContext/UserCredsContext";
import { Snackbar, Switch } from "@material-ui/core";
import Grow from "@material-ui/core/Grow";
import CheckIcon from "@material-ui/icons/Check";
import { Alert } from "@material-ui/lab";

const stylesmo = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(stylesmo)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);


export const EmailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;


export const PasswordRegex = new RegExp(
  "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
);
export const numberRegex = (/^[6-9]\d{9}$/);
function NewLoginModal({ open, onClose, getAptitudeForPay = () => { } }) {

  const { updateLoginData, loginData, user, setUser, setToken, userType } = useContext(UserCredsContext);
  const [errors, setErrors] = useState({});
  const [enable, setEnable] = useState(true);
  const [contactNumber, setContactNumber] = useState({
    code: "+91",
    number: null,
  });
  // const [submitsucess, setSubmitSuccess] = useState(0);
  const [show, setShow] = useState(false);
  const [showOtp, setShowOtp] = useState(false)
  const [validOtp, setValidotp] = useState(false);
  const [msg, setMsg] = useState("");
  const [uOtp, setUOtp] = useState([]);
  const [userData, setUserData] = useState({
    name: "",
    grade: {},
    language: "",
    number: "",
    pass: "",
    renter: "",
    email: "",
  });
  const [filterGrade, setFilterGrade] = useState(0);
  const [gradeUpd, setGrade] = useState(null);
  const [stateId, setStateId] = useState([]);
  const [languageValue, setLanguagesValue] = useState(null);
  const [filterlang, setFilterLang] = useState(0);
  const [languages, setLanguages] = useState([
    { id: 1, title: "English" },
    { id: 2, title: "Hindi" },
  ]);

  useEffect(() => {
    getGrade();
  }, []);

  const getGrade = () => {
    login_api
      .get(`/base/grade/`)
      .then((response) => {
        // console.log(response.data.data, "board/grade");
        setStateId(response.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  //Data If already added
  useEffect(() => {
    // if (user?.id) {
    //   setUserData({
    //     first_name: user?.first_name || "",
    //     last_name: user?.last_name || "",
    //     name: `${user?.first_name ? user.first_name : ""} ${
    //       user?.last_name ? user.last_name : ""
    //     }`,
    //     grade: user?.profile?.grade?.id || "",
    //     G_name: "",
    //     gphone: "",
    //     inst: user?.profile?.institute || "",
    //   });
    // }

    if (user?.profile?.grade) {
      setGrade(user?.profile?.grade?.id);
    }
    // if (user?.profile?.institute) {
    //   setSchool(user?.profile?.institute);
    // }
    // setFilterGender(genders.findIndex((el) => el.id === +user.gender));

    setFilterLang(languages.findIndex((el) => el.id === +user?.profile?.lang_medium))
    // setFilterLang(0)
    if (stateId.length !== 0) {
      // console.log(stateId.findIndex((el) => el.id === user?.profile?.grade?.id),"data");
      setFilterGrade(
        stateId.findIndex((el) => el.id === user?.profile?.grade?.id)
      );
    }



    if (user?.profile?.lang_medium) {
      setLanguagesValue(user?.profile?.lang_medium);
    } else {
      setLanguagesValue(1);
    }


    // getGurdianData();
  }, [user, stateId]);
  const handleChanged = (event, values) => {
    const outpt = values !== null ? setGrade(values.id) : "Please select Grade";
    setFilterGrade(stateId.findIndex((el) => el.id === values?.id));
    setErrors({ ...errors, outpt });
  };

  console.log(gradeUpd , "sdjakhfkud");

  const handleLanguages = (event, values) => {
    const vals =
      values !== null
        ? setLanguagesValue(values?.id)
        : "Please select Languages";
    setFilterLang(languages.findIndex((el) => el.id === +values?.id));
    setErrors({ ...errors, vals });
  };

  const checkId = (e, type) => {
    const { name, value } = e.target;
    console.log(e.target.name, e.target.value);
    if (errors.hasOwnProperty(name) && errors[name].length !== 0) {
      setErrors({ ...errors, [name]: "" });
    }
    if (value.length === 10) {
      setEnable(false);
    } else if (value.length !== 10) {
      setEnable(true);
    }

    if (name == "number" && value.length > 10) {
      return;
    }
    setContactNumber({
      ...contactNumber,
      [name]: value,
    });
  };
  const validate = () => {
    let temp = {};
    let returnValue = true;
    temp.code = contactNumber.code ? "" : "Code is required";
    temp.number = /^[0-9][0-9]{9}$/.test(contactNumber.number)
      ? ""
      : "Please enter valid phone number.";
    setErrors({
      ...temp,
    });
    var found = Object.keys(temp).filter(function (key) {
      return temp[key].includes("required") || temp[key].includes("valid");
    });
    if (found.length) {
      returnValue = false;
    }
    return returnValue;
  };

  const getOtp = (phone) => {
    // if (!validate()) {
    //   return null;
    // }
    setShowOtp(false)
    login_api
      .post(`/auth/lcarro/unique_phone_check/?phone=${phone}`)
      .then((response) => {
        // debugger;
        console.log("Response", response.data);
        const { message, status, id } = response.data.data;
        if (!status) {
          setErrors({
            err: message,
          });
          setShow(status)
          setShowOtp(true)
        } else if (status) {
          updateLoginData({
            id: response.data.data.id,
            code: contactNumber.code,
            phone: contactNumber.number,
            otp: "",
            type: "auth",
          });
          setErrors({
            err: "",
          });
          setShow(status)
          setShowOtp(false)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handalOTP = (e) => {
    console.log(e.target.value, "handalOTP");
    let otpValue = e.target.value;
    setUOtp(otpValue);
  };

  const handleChangeData = (event) => {
    console.log(event, "datasssssssss");
    const { name, value } = event.target;

    setUserData({ ...userData, [name]: value });
    // if(name === "first_name"){
    //   var check = userData.first_name
    // }

    let newErrors = { ...errors }; // Copy current errors
    if (name === "pass") {
      const passError = PasswordRegex.test(value)
        ? ""
        : "Password must be at least 8 characters long and contain an uppercase letter, a lowercase letter, a number, and a special character.";
      newErrors.pass = passError;
    }

    if (name === "renter") {
      const checkpassError =
        userData.pass === value ? "" : "Password does not match";
      newErrors.checkpass = checkpassError;
    }

    if (name === "email") {
      const emailError =
        EmailRegex.test(value) ? "" : "Enter a Valid Email Address";
      newErrors.email = emailError;
    }

    if (name === "number") {
      var numberError =""
        // numberRegex.test(value) ? "" : "Enter a Valid  number";
        if(numberRegex.test(value)){
          // setContactNumber({
          //   ...contactNumber,
          //   number: value
          // })
          // setShow(true)
          setErrors({
            err: "",
          });
          getOtp(value)
        }
        else{
          // setShow(false)
          numberError = "Enter a Valid  number"
        }
      newErrors.number = numberError;
   
    }
    // if (name === "otp") {
    //   var numberError =""
    //     // numberRegex.test(value) ? "" : "Enter a Valid  number";
    //     if(numberRegex.test(value)){
    //       setValidotp(true)
    //       verifyOtp()
    //     }
    //     else{
    //       setValidotp(false)
    //       numberError = "Enter a Valid  number"
    //     }
    //   newErrors.number = numberError;
   
    // }
    setErrors(newErrors);

    // if (name == "renter") {
    //   var checkpass = userData.pass === value ? "" : "Password does not match";
    //   setErrors({ ...errors, checkpass });
    // } else {
    //   setErrors({});
    // }
  };
  console.log(userData, "kdfji098");
  const verifyOtp = () => {
    console.log(uOtp, "kdfji098");

    if (uOtp.length == 0) {
      setErrors({ otp: "please enter otp" });
      return null;
    } else if (uOtp.length < 4) {
      setErrors({ otp: "please enter valid otp" });
      return null;
    }

    // var otp_number = uOtp.join("");
    console.log("OTP", uOtp);
    const datas = {
      mobile: +loginData.phone,
      otp: +uOtp,
    };
    console.log("DD", datas);
    login_api
      .post(`/auth/lcarro/${loginData.id}/verify_otp/`, datas)
      .then((response) => {
        const { status, message } = response.data.data;
        console.log(status);
        console.log(!status);
        //var redirect = status.includes("f");
        setMsg("OTP verified successfully. Please proceed to fill out the form on the next step.");
        if (!status) {
          setErrors({ message });
        }
      })
      .catch((error) => {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data, "OTP error");
          setErrors({ otp: error.response.data.data.status });
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
      });

  };


  const handalSubmit = () => {
    // let phoneNumber = loginData.phone;
    // if (phoneNumber.length > 10) {
    //   phoneNumber = phoneNumber.substring(2); // remove first two characters
    // }
    let nams = userData.name.trim();
    let va = nams.split(" ");

    // Validate required fields
    // if (!userData.phone || !userData.name || !userData.email || !gradeUpd) {
    //   alert("Please fill in all the required fields.");
    //   return;
    // }

    let datas = {
      profile: {
        grade_id: gradeUpd,
        title: "Mr",
        lang_medium: 1,
      },
      user_type: 1,
      password: "pass@BH123",
      name: `${va[0]} ${va[1]}`,
      first_name: va[0],
      last_name: va[1],
      phone: userData?.phone,
      email: userData.email,
      // gender: genderValue,
      // languages: languageValue,
    };

    login_api
      .post(`auth/v2/lcarro/${loginData.id}/student_register/`, datas)
      .then((response) => {
        // console.log(response, "RESPONSE")
        let { token, user } = response.data.data;
        setToken(token);
        localStorage.setItem("network_access_token", token.access);
        setUser(user);
        // setSubmitSuccess(1)
        onClose()
        getAptitudeForPay(user);
      })
      .catch((err) => {
        alert("Error Filling Data or User might already exist");
      });

    console.log(datas, "all the studentdata");
  }
  // if (userData.number){

  // }

  return (
    <div>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        className={styles.heropopup}
      >

        <DialogTitle id="customized-dialog-title" onClose={onClose}>
          <h2 className={styles.fromHeading}>Please Fill in Your Details </h2>
        </DialogTitle>
        <div className={styles.mainForm}>

          {/* <div className={styles.inputFieldContainer}>
            {/* <div className={styles.phoneNumber}>
              <div
                className="signin_input sing_val"
                style={{ width: "auto", display: "flex"}}
              >
                <div className="inp_ten" style={{ width: "auto" }}>
                  <input
                    type="text"
                    name="code"
                    list="countryCode"
                    id="cityId"
                    onChange={(e) => checkId(e, "select")}
                    defaultValue={contactNumber.code}
                  />
                  <datalist id="countryCode">
                    {optionList.map((item, key) => {
                      return <option key={key} value={item.number} />;
                    })}
                  </datalist>
                </div>
                <div className={styles.inp_ten}>
                  <input
                    name="number"
                    type="text"
                    id="numberId"
                    placeholder="99999 99999"
                    onChange={(e) => checkId(e, "input")}
                  />
                </div>
              </div>
              <Button className={styles.btn} variant="contained" onClick={getOtp}>
                {" "}
                Get Otp
              </Button>
            </div>
            { errors.err &&
              <p className="error-message-text errs" name="err">
              {errors.err}{" "}
            </p>
            }
            
          </div> */}
          <div className={styles.inputFieldContainer}>
            <TextField
              name="number"
              value={userData.number}
              onChange={handleChangeData}
              label="Mobile Number"
              placeholder="mobile number"
              className={styles.textWidth}
              variant="outlined"
              id="outlined-full-width"
              type="input field"
              autoComplete="off"
              // onBlur={handleBlur}
              required

              InputProps={{
                endAdornment: (
                  (show) && <CheckIcon style={{ color: 'green' }} />
                ),
              }}
            >

            </TextField>
            {
              (errors.number || errors.err) &&
              <p className="error-message-text error-marginbottom">
                {(errors?.number) ? errors.number : ''} {(!errors.number) && errors.err}
              </p>
            }

          </div>
       {/* {showOtp &&(   <div className={styles.inputFieldContainer}>
            <div className={styles.phoneNumber}>
              <TextField
                required
                name="otp"
                id="outlined-required"
                label="Otp"
                // defaultValue="Hello World"
                className={styles.textWidth}
                variant="outlined"
                onChange={handleChangeData}
                InputProps={{
                  endAdornment: (
                    validOtp && <CheckIcon style={{ color: 'green' }} />
                  ),
                }}
                // onChange={(e) => handalOTP(e, "otp")}
              />
              
            </div>
            {errors.otp &&
              <p className="error-message-text error-marginbottom">
                {errors.otp}
              </p>
            }

          </div>)} */}
          <div className={styles.inputFieldContainer}>
            <TextField
              value={userData.name}
              label="Full Name"
              onChange={handleChangeData}
              placeholder="Full Name"
              className={styles.textWidth}
              variant="outlined"
              id="outlined-full-width"
              name="name"
              required
            // style={{ width: "27em" }}
            ></TextField>
            {
              errors.name &&
              <p className="error-message-text error-marginbottom">
                {errors.name}
              </p>
            }

          </div>
          <div className={styles.inputFieldContainer}>
            <TextField
              name="email"
              value={userData.email}
              onChange={handleChangeData}
              label="Email"
              placeholder="Email"
              className={styles.textWidth}
              variant="outlined"
              id="outlined-full-width"
              type="email"
              autoComplete="off"
              // required
            ></TextField>
            {
              errors.email &&
              <p className="error-message-text error-marginbottom">
                {errors.email}
              </p>
            }

          </div>
          {/* <div className={styles.inputFieldContainer}>
            <Autocomplete
              // value={languages[filterlang]}
              id="combo-box-demo"
              options={languages}
              style={{ margin: "10px auto" }}
              onChange={handleLanguages}
              autoComplete="off"
              className={styles.textWidth}
              getOptionLabel={(option) => option.title}
              renderInput={(params) => (
                <TextField
                  variant="outlined"
                  required
                  autoComplete="off"
                  {...params}
                  label="Language"
                  name="languages"
                />
              )}
            /> 
            {
              errors.language &&
              <p className="error-message-text error-marginbottom">
                {errors.language}
              </p>
            }

          </div> */}
          <div className={styles.inputFieldContainer}>
            <Autocomplete
              // value={stateId[filterGrade]}
              id="combo-box-demos"
              options={stateId.slice(5)}
              style={{ margin: "10px auto" }}
              onChange={handleChanged}
              className={styles.textWidth}
              getOptionLabel={(option) => option.title}
              renderInput={(params) => (
                <TextField
                  required
                  variant="outlined"
                  {...params}
                  label="Grade"
                  name="grade"
                />
              )}
            />

            {
              errors.grade &&
              <p className="error-message-text error-marginbottom">
                {errors.grade}
              </p>
            }

          </div>
          {/* <div className={styles.inputFieldContainer}>
            <TextField
              name="pass"
              value={userData.pass}
              onChange={handleChangeData}
              label="Password"
              placeholder="Password"
              className={styles.textWidth}
              variant="outlined"
              id="outlined-full-width"
              type="password"
              autoComplete="off"
              required
            ></TextField>
            {
              errors.pass &&
              <p className="error-message-text error-marginbottom">
                {errors.pass}
              </p>
            }

          </div> */}
          {/* <div className={styles.inputFieldContainer}>
            <TextField
              name="renter"
              onChange={handleChangeData}
              value={userData.renter}
              placeholder="Re-Enter Password"
              label="Re-Enter Password"
              className={styles.textWidth}
              variant="outlined"
              id="outlined-full-width"
              type="password"
              required
            ></TextField>
            {
              errors.checkpass &&
              <p className="error-message-text error-marginbottom">
                {errors.checkpass}
              </p>
            }

          </div> */}
        </div>
        <DialogActions>
          <Button className={styles.Submit} variant="contained" autoFocus onClick={handalSubmit} color="primary">
            Pay Now
          </Button>
        </DialogActions>
        {msg && (
          <Snackbar
            open
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            onClose={() => setMsg("")}
            autoHideDuration={3000}
            message={msg}
          // TransitionComponent={Grow}
          // style={{
          //   top: "50%",
          //   left: "50%",
          //   transform: "translate(-50%, -50%)",
          // }}
          >
            {/* <CheckIcon className={styles.formsubmited} /> */}
            <Alert severity="success">{msg}</Alert>
          </Snackbar>
        )}
      </Dialog>

    </div>
  );
}

export default NewLoginModal;
const optionList = [
  {
    number: "+91",
    id: 1,
  },
  {
    number: "+92",
    id: 2,
  },
];
