import React, { useEffect, useState } from 'react'
import styles from "./parameters.module.css"
import bbdImg from "../../../Assets/BBdased.png"

function extractList(data) {
  const result = {
      list: []
  };
  // deb
  data.forEach(item => {
      if (item.breakdown) {
          result.list.push(item.breakdown);
      } else if (item.title) {
          result.list.push(item.title);
      }
  });

  return result;
}

function ParametersBox({
  text="",
  list=[],
  img="",
  background="",
}) {
  const[updateList, setUpdateList] = useState([]);

  const result = extractList(list);
 useEffect(() => {
  if(result?.list?.length > 0){
    setUpdateList(result)
  } 
 },[result])
  

  return (
    <div className={styles.boxContainer} style={{background:background}}>
        <div className={styles.textimageTop}>
          <img src={img} />
          {text}
        </div>
        <img src={bbdImg} />

        <ul className={styles.listContainer}>
          {
            result?.list?.map((v, i) => {
              console.log(result, "listContainer");
              
              return(
                <li className={styles.listText}>{v}</li>
              )
            })
          }
        </ul>
    </div>
  )
}

export default ParametersBox
