import React from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
// import CancelIcon from "@mui/icons-material/Cancel";
import { Cancel, Clear } from "@material-ui/icons";
import { Box, FormHelperText, OutlinedInput } from "@mui/material";
// const orderProduct=[
//     {
//       id: 1,
//       title: "Electronics",
//     },
//     {
//       id: 2,
//       title: "Virtual Reality",
//     },
//     {
//       id: 3,
//       title: "Robotics",
//     },
//     {
//       id: 4,
//       title: "Drone Tech",
//     },
//   ]
const useStyles = makeStyles((theme) => ({
  formControl: {
    // margin: theme.spacing(1),
    //   minWidth: 120,
    //   maxWidth: 300,
    width: "100%",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const names = [
  "Oliver Hansen",
  "Van Henry",
  "April Tucker",
  "Ralph Hubbard",
  "Omar Alexander",
  "Carlos Abbott",
  "Miriam Wagner",
  "Bradley Wilkerson",
  "Virginia Andrews",
  "Kelly Snyder",
];

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const CustomMultiSelectChips = ({
  label,
  handleDelete,
  onChange,
  value,
  // orderProduct,
  listArray,
  error,
}) => {
  // const listArray = orderProduct;
  console.log("sddadasdasddfwefwe", listArray);
  const classes = useStyles();
  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);

  const handleChange = (event) => {
    setPersonName(event.target.value);
  };

  const handleChangeMultiple = (event) => {
    const { options } = event.target;
    const value = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    setPersonName(value);
  };

  return (
    <>
      <div style={{width:"100%"}}>
      <FormControl className={classes.formControl}>
        <InputLabel id="demo-mutiple-chip-label-outlined">Kits</InputLabel>
        <Select
            // native
          labelId="demo-mutiple-chip-label-outlined"
          id="demo-mutiple-chip-outlined"
          multiple
          value={value}
          // onChange={handleChange}
          onChange={(e) => onChange(e.target.value)}
          // input={<Input id="select-multiple-chip" />}
          // renderValue={(selected) => (
          //   <div className={classes.chips}>
          //     {selected.map((value) => {
          //       console.log(value,"mjhdfabmnbyebhuhe");
          //       return(
          //       <Chip key={value?.id} label={value?.title} className={classes.chip} />
          //     )})}
          //   </div>
          // )}
          input={
            <Input
            // <OutlinedInput
              size="small"
              id="select-multiple-chip"
              label={label}
            />
          }
          renderValue={(selected) =>
            { 
              console.log(selected,"nduhduahdajdha");
              return(
           
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected.map((value) => {
                return (
                  <Chip
                    label={value?.title}
                    key={value?.id}
                    deleteIcon={
                      <Clear
                        onMouseDown={(event) => event.stopPropagation()}
                      />
                    }
                    // onClick={handleDeleteChip}
                    onDelete={(e) => handleDelete(value?.id)}
                  />
                );
              })}
            </Box>
          )}}
          MenuProps={MenuProps}
        >
          {listArray?.map((v) => (
            <MenuItem key={v?.id} value={v} style={getStyles(v, personName, theme)}>
              {v?.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      </div>
      {/* <div style={{width:"100%"}}>
        <FormControl sx={{ width: "100%" }} size="small" error={error}>
          <InputLabel size="small" id="demo-simple-select-label">
          {label}
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select-label"
            multiple
            label={label}
            value={value}
            onChange={(e) => onChange(e.target.value)}
            // name="subject_id"
            size="small"
            input={
              <OutlinedInput
                size="small"
                id="select-multiple-chip"
                label={label}
              />
            }
            renderValue={(selected) => (
              <Box
               sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
               >
                {selected.map((value) => {
                  return (
                    <Chip
                      label={value?.title}
                      key={value?.id}
                      deleteIcon={
                        <Clear
                          onMouseDown={(event) => event.stopPropagation()}
                        />
                      }
                      onClick={handleDelete}
                      onDelete={(e) => handleDelete(value?.id)}
                    />
                  );
                })}
              </Box>
            )}
            // MenuProps={MenuProps}
          >
            {listArray?.map((sub, key) => (
              <MenuItem key={key} value={sub}>
                {sub?.title}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{error}</FormHelperText>
        </FormControl>
      </div> */}
    </>
  );
};

export default CustomMultiSelectChips;
